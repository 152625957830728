import { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { connect } from "react-redux";
import SnackbarCloseButton from "containers/SnackbarCloseButton";
import { actionGenerators as notificationsActionGenerators } from "features/EntryPoint/containers/Notifications/state";

function withAccountSettingsSubmit(WrappedComponent) {
  class WithAccountSettingsSubmit extends Component {
    static propTypes = {
      account: PropTypes.object.isRequired,
      addNotification: PropTypes.func.isRequired,
      updateAccountRequest: PropTypes.func.isRequired,
    };

    errorCallback = (actions) => {
      return (errors) => {
        const { addNotification } = this.props;
        const validationErrors = get(errors, ["validationErrors", "settings"], {
          message: ["There was an error saving your account settings"],
        });
        const errorsKey = Object.keys(validationErrors)[0];
        const errorMessage = validationErrors[errorsKey][0];
        actions.setSubmitting(false);
        actions.setErrors(validationErrors);
        if (addNotification) {
          addNotification({
            message: errorMessage || "Something went wrong!",
            options: {
              action: (key) => {
                return <SnackbarCloseButton snackbarKey={key} />;
              },
              variant: "error",
            },
          });
        }
      };
    };

    successCallback = (
      actions,
      message = "Account settings saved successfully!",
    ) => {
      return () => {
        const { addNotification } = this.props;
        actions.setSubmitting(false);

        if (addNotification) {
          addNotification({
            message,
            options: {
              action: (key) => {
                return <SnackbarCloseButton snackbarKey={key} />;
              },
            },
          });
        }
      };
    };

    handleSubmit = (values, actions, successMessage) => {
      const { account, updateAccountRequest } = this.props;
      updateAccountRequest(account.id, values, {
        successCallback: this.successCallback(actions, successMessage),
        errorCallback: this.errorCallback(actions),
      });
    };

    render() {
      return (
        <WrappedComponent {...this.props} handleSubmit={this.handleSubmit} />
      );
    }
  }

  const withConnect = connect(() => {
    return {};
  }, notificationsActionGenerators);

  return withConnect(WithAccountSettingsSubmit);
}

export default withAccountSettingsSubmit;

import { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { NavLink, Switch, Route, Redirect } from "react-router-dom";
import { CloudUploadIcon } from "icons";

import ContactImport from "features/ContactImports/containers/ContactImport";
import ContactImportCollection from "features/ContactImports/containers/ContactImportCollection";
import ExtensionContactImport from "features/ContactImports/containers/ExtensionContactImport";
import NewContactImport from "features/ContactImports/containers/NewContactImport";

import PageContent from "components/Page/PageContent";
import PageSection from "components/Page/PageSection";
import PageSidebar from "components/Page/PageSidebar";
import PageSidebarHeader from "components/Page/PageSidebarHeader";
import PageSidebarHeaderButton from "components/Page/PageSidebarHeaderButton";
import PageWrapper from "components/Page/PageWrapper";
import withSidebar from "higherOrderComponents/withSidebar";

const StartContactImportNavLink = styled(NavLink)`
  text-decoration: none;
  width: 100%;

  &.active {
    button {
      background: ${(props) => {
        return props.theme.colors.action.hover;
      }};
      color: ${(props) => {
        return props.theme.colors.primary.main;
      }};
    }
  }
`;

export class Root extends PureComponent {
  static propTypes = {
    currentAccount: PropTypes.object.isRequired,
    isSinglePanel: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    showSidebar: PropTypes.bool.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
  };

  render() {
    const { currentAccount, isSinglePanel, match, showSidebar, toggleSidebar } =
      this.props;


    return (
      <PageWrapper>
        <PageSidebar>
          <ContactImportCollection
            contactImportCollectionId={
              currentAccount.contactImports ||
              `/${currentAccount.slug}/contact_imports`
            }
            match={match}
            toggleSidebar={toggleSidebar}
          />
        </PageSidebar>
        <PageContent isSinglePanel={isSinglePanel} showSidebar={showSidebar}>
          <PageSection>
            <Switch>
              <Route
                exact
                path={`${match.path}`}
                render={() => {
                  return (
                    <Redirect to={`${match.url}/new`.replace("//", "/")} />
                  );
                }}
              />
              <Route
                exact
                path={`${match.path}/new`}
                render={(props) => {
                  return (
                    <NewContactImport
                      {...props}
                      toggleSidebar={toggleSidebar}
                    />
                  );
                }}
              />
              <Route
                exact
                path={`${match.path}/extension`}
                render={(props) => {
                  return <ExtensionContactImport {...props} />;
                }}
              />
              <Route
                exact
                path={`${match.path}/:importSlug`}
                render={(props) => {
                  return (
                    <ContactImport
                      {...props}
                      contactImportId={`/contact_imports/${props.match.params.importSlug}`}
                      toggleSidebar={toggleSidebar}
                    />
                  );
                }}
              />
            </Switch>
          </PageSection>
        </PageContent>
      </PageWrapper>
    );
  }
}

export default withSidebar(Root);

import { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import AccountPlansSettings from "./components/AccountPlansSettings";
import LicenseDelegationTable from "./components/LicenseDelegationTable";
import withRecord from "higherOrderComponents/withRecord";
import SnackbarCloseButton from "containers/SnackbarCloseButton";

import { actionGenerators as notificationsActionGenerators } from "features/EntryPoint/containers/Notifications/state";

import PageHeader from "components/Page/PageHeader";
import SettingsPageContent from "components/SettingsPageComponents/SettingsPageContent";
import SettingsPageWrapper from "components/SettingsPageComponents/SettingsPageWrapper";

interface AccountConfigurationProps {
  account: Record<string, any>;
  addNotification: (notificationOptions: {
    message: string;
    options: { action: (key: any) => React.ReactNode; variant: string };
  }) => void;
  toggleSidebar: () => void;
  fetchAccountPlansRequest: (
    accountPlanId: string,
    body: Record<string, any> | null,
    returnCallbacks: {
      successCallback: (response: Record<string, any>) => void;
      errorCallback: (error: Record<string, any>) => void;
    },
  ) => void;
}

const AccountConfiguration: React.FC<AccountConfigurationProps> = ({
  account,
  addNotification,
  fetchAccountPlansRequest,
  toggleSidebar,
}) => {
  const accountPlansId = `/${account?.slug}/account_plans`;

  const successCallback = () => {};

  const errorCallback = (error: Record<string, any>) => {
    addNotification({
      message: "There was an error retrieving account plans",
      options: {
        action: (key) => {
          return <SnackbarCloseButton snackbarKey={key} />;
        },
        variant: "error",
      },
    });
  };

  useEffect(() => {
    fetchAccountPlansRequest(accountPlansId, null, {
      successCallback,
      errorCallback,
    });
  }, [accountPlansId]);

  return (
    <SettingsPageWrapper>
      <PageHeader title="Account Configuration" toggleSidebar={toggleSidebar} />
      <SettingsPageContent maxWidth="1024px">
        <AccountPlansSettings />
        <LicenseDelegationTable />
      </SettingsPageContent>
    </SettingsPageWrapper>
  );
};

const mapStateToProps = () => {
  return {};
};

const withConnect = connect(
  () => {
    return mapStateToProps;
  },
  {
    ...notificationsActionGenerators,
  },
);

export default compose(
  withRecord({
    actions: ["fetch"],
    container:
      "features/AccountSettings/components/AccountConfiguration/AccountPlansSettings",
    multiple: false,
    shape: {},
    showLoader: () => {
      return false;
    },
    type: "accountPlans",
  }),
  withConnect,
)(AccountConfiguration);

import { Identifiable } from "../types";
import { Phone } from "./Phone";
import { BullhornCandidate, GreenhouseCandidate, identify } from ".";

const root = "/contacts/";

export namespace Contact {
  export interface Model {
    slug: string;
    id: `/contacts/${string}`;
    phones: Phone.Model[];
    name: string;
    data: {
      business?: string;
      tags?: string[];
    };
  }

  export interface Raw {
    lastName: string;
    notes: {
      id: `/contacts/${string}/notes`;
      members: string[];
      totalItems: number;
    };
    phones: {
      id: `/contacts/${string}/phones`;
      members: Phone.Raw[];
      totalItems: number;
    };
    data: {
      tags: string[];
      business: string;
      bullhorn?: {
        Candidate?: BullhornCandidate.Raw;
      };
      greenhouse?: {
        Candidate?: GreenhouseCandidate.Raw;
      };
    };
    name: string;
    conversations: string;
    id: `/contacts/${string}`;
    createdAt: string;
    firstName: string;
  }

  export type Like = Identifiable<Raw>;

  export type Identity = Model["id"];
}

export const sanitizeContactId = (id: Contact.Identity) => {
  if (id.startsWith(root)) {
    return id.split("/")[2];
  }

  return id;
};

export const identifyContact = (contact: Contact.Like) => {
  const id = identify(contact) as Contact.Identity;

  return sanitizeContactId(id);
};

/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import { useEffect, useState } from "react";
import { Box, Divider } from "@mui/material";
import { useSnackbar } from "notistack";
import { ConnectedIntegrations } from "../ConnectedIntegrations/ConnectedIntegrations";
import { AvailableIntegrations } from "../AvailableIntegrations/AvailableIntegrations";
import { getAllIntegrations } from "../../api/getAllIntegrations";
import { IntegrationDetails } from "../IntegrationDetails";
import { IntegrationsLandingPageProps } from "./types";
import SettingsPageWrapper from "components/SettingsPageComponents/SettingsPageWrapper";
import PageHeader from "components/Page/PageHeader";
import { IntegrationProvider } from "models/IntegrationProvider";

const formatIntegrationProviders = (data) => {
  const result: any[] = [];
  data.forEach((item) => {
    // For merge integrations that have multiple categories (e.g ["ats", "crm"]), we want to create a new item for each category
    if (
      item.categories &&
      Array.isArray(item.categories) &&
      item.categories.length > 1
    ) {
      return item.categories.forEach((category: string) => {
        const newItem = {
          ...item,
          providerName: `${item.providerName}: ${category.toUpperCase()}`,
          category,
        };
        delete newItem.categories;
        return result.push(newItem);
      });
    }
    // For merge integrations that have one category
    if (
      item.categories &&
      Array.isArray(item.categories) &&
      item.categories.length === 1
    ) {
      item.category = item.categories[0];
      delete item.categories;
      return result.push(item);
    }
    // For non-merge integrations
    return result.push(item);
  });
  return result;
};

export function IntegrationsLandingPage({
  account,
  toggleNavbar,
}: IntegrationsLandingPageProps) {
  const [allIntegrations, setAllIntegrations] = useState<IntegrationProvider[]>(
    [],
  );
  const [connectedIntegrations, setConnectedIntegrations] = useState<
    IntegrationProvider[]
  >([]);
  const [selectedIntegration, setSelectedIntegration] =
    useState<IntegrationProvider | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const handleGetAllIntegrations = async () => {
    try {
      const response = await getAllIntegrations();
      if (!response.ok) {
        throw new Error("Cannot fetch available integrations");
      }
      const data = await response.json();
      const formattedData = formatIntegrationProviders(data);
      setAllIntegrations(formattedData);
    } catch {
      enqueueSnackbar("", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (!selectedIntegration && account) {
      handleGetAllIntegrations();
    }
  }, [account, selectedIntegration]);

  return selectedIntegration?.id ? (
    <IntegrationDetails
      allIntegrations={allIntegrations}
      selectedIntegration={selectedIntegration}
      setSelectedIntegration={setSelectedIntegration}
      toggleSidebar={toggleNavbar}
    />
  ) : (
    <SettingsPageWrapper>
      <PageHeader
        aria-label="Individual Sequence Detail Page"
        title="Integrations"
        toggleSidebar={toggleNavbar}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "scroll",
          paddingY: "32px",
          paddingX: "96px",
        }}
      >
        <ConnectedIntegrations
          allIntegrations={allIntegrations}
          connectedIntegrations={connectedIntegrations}
          currentAccount={account}
          selectedIntegration={selectedIntegration}
          setConnectedIntegrations={setConnectedIntegrations}
          setSelectedIntegration={setSelectedIntegration}
        />
        <Divider
          sx={{
            marginBottom: "32px",
          }}
        />
        <AvailableIntegrations
          allIntegrations={allIntegrations}
          connectedIntegrations={connectedIntegrations}
          currentAccount={account}
          selectedIntegration={selectedIntegration}
          setSelectedIntegration={setSelectedIntegration}
        />
        <Divider
          sx={{
            marginBottom: "32px",
          }}
        />
      </Box>
    </SettingsPageWrapper>
  );
}

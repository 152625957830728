import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import get from "lodash/get";

import StartConversationWrapper from "../containers/StartConversationWrapper";
import { dialogIds } from "../constants";
import ActiveConversation from "features/ActiveConversation";
import BlankState from "components/BlankState";
import ContactSidebar from "features/ContactSidebar";
import Logo from "components/Logo";
import PageLoader from "components/PageLoader";
import fixedEncodeURIComponent from "utils/fixedEncodeURIComponent";

const Wrapper = styled.div`
  color: ${(props) => {
    return props.theme.colors.text.primary;
  }};
  display: flex;
  height: 100%;
  left: ${(props) => {
    return props.sidebarActive ? "0" : "-100vw";
  }};
  position: absolute;
  transition: ${(props) => {
    return props.theme.mixins.transition({ timing: "0.5s" });
  }};
  width: 100%;

  @media (min-width: 768px) {
    left: 0;
    position: relative;
  }
`;

const Sidebar = styled.div`
  width: 100%;

  @media (min-width: 600px) {
    border-right: ${(props) => {
      return props.theme.mixins.border({ color: props.theme.colors.divider });
    }};

    min-width: 240px;
    width: 240px;
  }
`;

const Main = styled.div`
  flex: 1 1 auto;
  position: relative;
`;

const TransparentWrapper = styled.div`
  div {
    background: transparent;
  }
`;

function TransparentPageLoader() {
  return (
    <TransparentWrapper>
      <PageLoader />
    </TransparentWrapper>
  );
}

class ContactShow extends Component {
  static propTypes = {
    activeConversation: PropTypes.string,
    closeModal: PropTypes.func,
    contact: PropTypes.object.isRequired,
    currentAccount: PropTypes.object.isRequired,
    expanded: PropTypes.string,
    isSinglePanel: PropTypes.bool,
    setActiveView: PropTypes.func,
    setContactModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      sidebarActive: true,
      showLoader: true,
    };
  }

  componentDidMount() {
    // Add a timeout to the contact show to allow the animation to complete before kicking off the contact conversation requests
    this.showLoaderTimeout = setTimeout(() => {
      this.setState({ showLoader: false });
    }, 1000);
  }

  componentWillUnmount() {
    clearTimeout(this.showLoaderTimeout);
  }

  handleBackClick = () => {
    this.setState({ sidebarActive: true });
  };

  handleConversationClick = (activeConversation) => {
    this.setState({ sidebarActive: false }, () => {
      this.props.setContactModal({ activeConversation });
    });
  };

  renderActiveSection = () => {
    const { activeConversation, contact, currentAccount, setContactModal } =
      this.props;

    const firstContactPhone = get(contact, ["phones", "members", "0"]);
    if (!firstContactPhone) {
      return (
        <BlankState
          image={<Logo color="disabled" />}
          title="This contact does not have a number"
          subTitle="Add a number to get started"
        />
      );
    }
    if (!activeConversation && currentAccount.phoneNumbers.length === 0) {
      return (
        <BlankState
          image={<Logo color="disabled" />}
          title="Your account is not enabled for messaging"
          subTitle="Select 'Other Conversations' to view conversations with other accounts"
        />
      );
    }
    if (!activeConversation) {
      return <TransparentPageLoader />;
    }
    if (activeConversation.startsWith("+")) {
      const contactPhone = contact.phones.members.find((phone) => {
        return phone.phoneNumber === activeConversation;
      });
      const conversationUrl = `/${
        currentAccount.slug
      }/conversations/${fixedEncodeURIComponent(
        activeConversation,
      )}?contactPhone=${contactPhone.id}`;
      return (
        <StartConversationWrapper
          contactModal
          backClickHandler={this.handleBackClick}
          conversationId={activeConversation}
          conversationUrl={conversationUrl}
          currentContact={contact.id}
          setContactModal={setContactModal}
        />
      );
    }
    if (activeConversation === "Not Found") {
      const conversationUrl = `/${
        currentAccount.slug
      }/conversations/${fixedEncodeURIComponent(
        firstContactPhone.phoneNumber,
      )}?contactPhone=${firstContactPhone.id}`;
      return (
        <StartConversationWrapper
          contactModal
          backClickHandler={this.handleBackClick}
          conversationId={activeConversation}
          conversationUrl={conversationUrl}
          currentContact={contact.id}
          setContactModal={setContactModal}
        />
      );
    }
    return (
      <ActiveConversation
        contactModal
        backClickHandler={this.handleBackClick}
        conversationId={activeConversation}
        currentContact={contact.id}
        showConversationSidebar={false}
        setContactModal={setContactModal}
      />
    );
  };

  render() {
    const {
      activeConversation,
      closeModal,
      contact,
      currentAccount,
      expanded,
      setActiveView,
      isSinglePanel,
    } = this.props;
    const { sidebarActive, showLoader } = this.state;

    return (
      <Wrapper sidebarActive={sidebarActive}>
        {showLoader ? (
          <TransparentPageLoader />
        ) : (
          <>
            <Sidebar>
              <ContactSidebar
                activeConversationId={activeConversation}
                closeModal={closeModal}
                contact={contact}
                currentAccount={currentAccount}
                expanded={expanded}
                handleConversationClick={this.handleConversationClick}
                handleEditClick={() => {
                  setActiveView(dialogIds.edit);
                }}
                setActiveView={setActiveView}
              />
            </Sidebar>
            {(sidebarActive && isSinglePanel) === false && (
              <Main>{this.renderActiveSection()}</Main>
            )}
          </>
        )}
      </Wrapper>
    );
  }
}

export default ContactShow;

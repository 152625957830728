import { useState } from "react";
import PropTypes from "prop-types";
import withSizes from "react-sizes";
import get from "lodash/get";
import { connect } from "react-redux";
import { compose } from "redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { format } from "date-fns";
import CustomUserSchemaModal from "./CustomUserSchemaModal";
import { actionGenerators as notificationsActionGenerators } from "features/EntryPoint/containers/Notifications/state";
import CollectionTable from "components/CollectionTable";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import PageHeader from "components/Page/PageHeader";
import SettingsPageContent from "components/SettingsPageComponents/SettingsPageContent";
import SettingsPageWrapper from "components/SettingsPageComponents/SettingsPageWrapper";
import SnackbarCloseButton from "containers/SnackbarCloseButton";
import withRecord from "higherOrderComponents/withRecord";

function CustomUserSchema({
  addNotification,
  deleteCustomFieldRequest,
  fetchUserSchemaRequest,
  history,
  toggleSidebar,
  userSchema,
  userSchemaId,
}) {
  const [variableModalOpen, setVariableModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [variableToModify, setVariableToModify] = useState(null);

  const toggleVariableModalOpen = () => {
    setVariableModalOpen(!variableModalOpen);
  };
  const toggleConfirmationModalOpen = () => {
    setConfirmationModalOpen(!confirmationModalOpen);
  };
  const userSchemaCollection = get(userSchema, ["fields"], {});

  const variableTypes = [
    { name: "Date (MM-DD)", value: "calendar_day" },
    { name: "Date (MM-DD-YYYY)", value: "date" },
    { name: "Email", value: "email" },
    { name: "Number (00.00)", value: "number" },
    { name: "Phone number", value: "telephone" },
    { name: "Text", value: "text" },
    // temporarily remove time option
    // { name: "Time (hh:mm am/pm)", value: "time" },
    { name: "URL", value: "url" },
  ];

  const variableTypeLookup = variableTypes.reduce((acc, val) => {
    acc[val.value] = val.name;
    return acc;
  }, {});

  const closeVariableModal = () => {
    setModalAction("");
    setVariableToModify(null);
    toggleVariableModalOpen();
  };

  const handleCreateVariable = () => {
    setModalAction("Add");
    toggleVariableModalOpen();
  };

  const handleEditVariable = (variable) => {
    const { description, slug, title, type } = variable;
    setModalAction("Edit");
    setVariableToModify({
      description,
      slug,
      title,
      type: { name: variableTypeLookup[type], value: type },
    });
    toggleVariableModalOpen();
  };

  const handleOpenConfirmationModal = (title, slug) => {
    setVariableToModify({ title, slug });
    toggleConfirmationModalOpen();
  };

  const handleDeleteVariable = () => {
    const { slug } = variableToModify;
    deleteCustomFieldRequest(`${userSchemaId}/${slug}`, null, {
      successCallback: () => {
        fetchUserSchemaRequest(userSchemaId);
        addNotification({
          message: "Personalization successfully deleted.",
          options: {
            action: (key) => {
              return <SnackbarCloseButton snackbarKey={key} />;
            },
          },
        });
        toggleConfirmationModalOpen();
        setVariableToModify(null);
      },
      errorCallback: () => {
        return addNotification({
          message: "We were unable to delete this personalization.",
          options: {
            action: (key) => {
              return <SnackbarCloseButton snackbarKey={key} />;
            },
            variant: "error",
          },
        });
      },
    });
  };

  const handleCancelDelete = () => {
    setVariableToModify(null);
    toggleConfirmationModalOpen();
  };

  function EditButton(values) {
    return (
      <Box>
        <IconButton
          aria-label={`Edit Personalization ${values.title}`}
          type="button"
          onClick={() => {
            return handleEditVariable(values);
          }}
          disabled={false}
          size="large"
        >
          <EditIcon fontSize="small" color="primary" />
        </IconButton>
      </Box>
    );
  }

  function DeleteButton(variableTitle, variableId) {
    return (
      <Box>
        <IconButton
          aria-label={`Delete Personalization ${variableTitle}`}
          type="button"
          onClick={() => {
            return handleOpenConfirmationModal(variableTitle, variableId);
          }}
          disabled={false}
          size="large"
        >
          <DeleteIcon fontSize="small" color="primary" />
        </IconButton>
      </Box>
    );
  }

  const columns = [
    {
      title: "Name",
      align: "left",
      getTableCellContent: ({ title }) => {
        return title;
      },
    },
    {
      title: "Type",
      align: "left",
      getTableCellContent: ({ type }) => {
        return variableTypeLookup[type];
      },
    },
    {
      title: "Date Created",
      align: "left",
      getTableCellContent: ({ createdAt }) => {
        return format(new Date(createdAt), "MMMM do, yyyy");
      },
    },
    {
      title: "Edit",
      align: "left",
      getTableCellContent: (schemaVariable) => {
        return EditButton(schemaVariable);
      },
    },
    {
      title: (
        <Box display="flex" alignItems="center">
          Delete
          <Tooltip
            style={{ marginLeft: "0.5em" }}
            title="Personalizations currently in use cannot be deleted."
          >
            <InfoOutlinedIcon />
          </Tooltip>
        </Box>
      ),
      align: "left",
      getTableCellContent: ({ title, slug, inUse }) => {
        return !inUse && DeleteButton(title, slug);
      },
    },
  ];

  const collapsedColumns = [
    {
      title: "Name",
      align: "left",
      getTableCellContent: ({ title }) => {
        return title;
      },
    },
    {
      title: "Edit",
      align: "left",
      getTableCellContent: (schemaVariable) => {
        return EditButton(schemaVariable);
      },
    },
    {
      title: (
        <Box display="flex" alignItems="center">
          Delete
          <Tooltip
            style={{ marginLeft: "0.5em" }}
            title="Personalizations currently in use cannot be deleted."
          >
            <InfoOutlinedIcon />
          </Tooltip>
        </Box>
      ),
      align: "left",
      getTableCellContent: ({ title, slug, inUse }) => {
        return !inUse && DeleteButton(title, slug);
      },
    },
  ];

  const blankStateMessage = (
    <Box
      display="flex"
      flex="0 0 auto"
      mt={4}
      color="secondary"
      justifyContent="center"
    >
      You have no user personalization options created
    </Box>
  );

  const removalMessage = variableToModify
    ? `the "${variableToModify.title}"`
    : `this`;

  return (
    <SettingsPageWrapper>
      <PageHeader title="User Personalization" toggleSidebar={toggleSidebar} />
      <CustomUserSchemaModal
        modalAction={modalAction}
        closeModal={closeVariableModal}
        fetchUserSchemaRequest={fetchUserSchemaRequest}
        open={variableModalOpen}
        userSchemaId={userSchemaId}
        variableToEdit={variableToModify}
        variableTypes={variableTypes}
      />
      <ConfirmationModal
        cancellationHandler={handleCancelDelete}
        confirmationHandler={handleDeleteVariable}
        isOpen={confirmationModalOpen}
        message={`You are about to remove ${removalMessage} user personalization from your account's custom schema.`}
        title="Delete this user personalization?"
      />
      <SettingsPageContent>
        <Box mt={4}>
          <Box display="flex" flex="0 0 auto">
            <Box mr={2}>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "700" }}
                component="h2"
              >
                User Personalization
              </Typography>
              <Typography gutterBottom>
                Allow users to add personal details to be able to quickly
                include them in messages.
              </Typography>
            </Box>
            <Box display="flex" flex="0 0 auto" alignItems="center">
              <Button
                aria-label="Create New Button"
                color="primary"
                variant="contained"
                onClick={handleCreateVariable}
              >
                Create New
              </Button>
            </Box>
          </Box>
          <CollectionTable
            blankState={blankStateMessage}
            changePageHandler={() => {}}
            collection={userSchemaCollection}
            columns={columns}
            collapsedColumns={collapsedColumns}
            history={history}
            label="Settings-User Schema Table"
            page={0}
            zebra
          />
        </Box>
      </SettingsPageContent>
    </SettingsPageWrapper>
  );
}

CustomUserSchema.propTypes = {
  addNotification: PropTypes.func.isRequired,
  deleteCustomFieldRequest: PropTypes.func.isRequired,
  fetchUserSchemaRequest: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  userSchema: PropTypes.object.isRequired,
  userSchemaId: PropTypes.string.isRequired,
};

const withConnect = connect(null, {
  ...notificationsActionGenerators,
});

export default compose(
  withSizes(({ width }) => {
    return {
      isSmall: width < 600,
    };
  }),
  withConnect,
  withRecord({
    actions: ["fetch"],
    container:
      "features/AccountSettings/components/CustomUserSchema/userSchema",
    shape: { fields: { members: [] } },
    type: "userSchema",
    showLoader: () => {
      return false;
    },
  }),
  withRecord({
    actions: ["delete"],
    container:
      "features/AccountSettings/components/CustomUserSchema/customField/delete",
    type: "customField",
  }),
)(CustomUserSchema);

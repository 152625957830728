/* eslint-disable no-return-assign */
/* eslint-disable prefer-const */
import * as Yup from "yup";

const allFields = [
  {
    fieldProperty: "webhookUrl",
    validator: Yup.string().required("API key is required"),
  },
  {
    fieldProperty: "username",
    validator: Yup.string().required("Username is required"),
  },
  {
    fieldProperty: "password",
    validator: Yup.string().required("Password is required"),
  },
  {
    fieldProperty: "clientId",
    validator: Yup.string().required("Client ID is required"),
  },
  {
    fieldProperty: "clientSecret",
    validator: Yup.string().required("Client secret is required"),
  },
  {
    fieldProperty: "entityId",
    validator: Yup.string().required("Entity ID is required"),
  },
  {
    fieldProperty: "baseUrl",
    validator: Yup.string().required("Base URL is required"),
  },
  {
    fieldProperty: "tenantId",
    validator: Yup.string().required("Tenant ID is required"),
  },
  {
    fieldProperty: "organizationUrl",
    validator: Yup.string().required("Organization URL is required"),
  },
  {
    fieldProperty: "email",
    validator: Yup.string().email().required("Email is required"),
  },
  {
    fieldProperty: "apiKey",
    validator: Yup.string().required("API Key is required"),
  },
  {
    fieldProperty: "subdomain",
    validator: Yup.string().required("Greenhouse subdomain is required"),
  },
];

export const generateCustomIntegrationValidationSchema = (
  integrationConfig: any,
) => {
  let validationSchema = {};
  const authorizationFields = Object.keys(integrationConfig);
  authorizationFields.forEach((field) => {
    if (field === "datacenter") {
        return validationSchema = {
            ...validationSchema,
            apiHost: Yup.string().required("DataCenter is required"),
            authHost: Yup.string(),
        }
    }
    const fieldValidator = allFields.find((fieldConfig) => {
      return fieldConfig.fieldProperty === field;
    });
    return validationSchema = {
        ...validationSchema,
        [field]: fieldValidator?.validator
    }
  });
  return Yup.object().shape(validationSchema);
};

import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import Root from "./components/Root";
import { actionGenerators as notificationsActionGenerators } from "features/EntryPoint/containers/Notifications/state";
import { selectCurrentUser } from "features/EntryPoint/containers/App/selectors";
import withRecord from "higherOrderComponents/withRecord";

class SavedReplyCollectionItem extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentUser: selectCurrentUser(state, props),
  };
};

const withConnect = connect(
  () => {
    return mapStateToProps;
  },
  {
    ...notificationsActionGenerators,
  },
);

export default compose(
  withRecord({
    actions: ["delete"],
    container: "features/SavedReplies/containers/SavedReplyCollectionItem",
    type: "savedReply",
  }),
  withConnect,
)(SavedReplyCollectionItem);

import assert from "assert";
import { userManager } from "@tesseract/core";
import { headers } from "constants/apiConstants";
import { Account as AccountType } from "models/Account";

export const createRecipients = async (
  currentAccount: AccountType,
  sequenceId: string,
  contacts: string | string[],
): Promise<Response> => {
  const user = await userManager.getUser();

  assert(user?.access_token);

  const url = `/api/v4/${currentAccount.slug}/sequences/${sequenceId}/sequence_recipients`;

  const requestBody =
    typeof contacts === "string"
      ? { contactFilter: { contactFilterSlug: contacts } }
      : { contactPhoneIds: contacts };

  const request = new Request(url, {
    method: "POST",
    headers: {
      ...headers,
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  return fetch(request);
};

export const AUTOMATION_PATHS = Object.freeze({
  automation: "automation",
  automationKeywords: "automation_keywords",
  sequences: "sequences",
  keywordsMain: "keywords",
  keywordGroups: "keywords_groups",
});

export const SEQUENCES_NAV = Object.freeze({
  mine: "Mine",
  others: "Others",
});

export const SEQUENCES_TABS = Object.freeze({
  all: 0,
  active: 1,
  inactive: 2,
  archive: 3,
});

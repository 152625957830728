import { connect } from "react-redux";
import { compose } from "redux";
import withSizes from "react-sizes";

import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EmailIcon from "@mui/icons-material/Email";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { AnalyticsDetailReportHeaderProps } from "../models/AnalyticsModels";
import { useDetailReportHeader } from "../hooks/useDetailReportHeader";
import { useFilters } from "../hooks/useFilters";

import AnalyticsInfoModal from "./AnalyticsInfoModal";
import EditAnalyticsReportModal from "./EditAnalyticsReportModal";
import ScheduleAnalyticsReportModal from "./ScheduleAnalyticsReportModal";
import { actionGenerators as notificationsActionGenerators } from "features/EntryPoint/containers/Notifications/state";
import withRecord from "higherOrderComponents/withRecord";
import H3 from "components/H3";
import breakpoints from "utils/styles/breakpoints";

const style = {
  display: "flex",
  flex: "0 0 auto",
};

function AnalyticsDetailReportHeader({
  addNotification,
  analyticReport,
  billingReport = false,
  createAnalyticExportReportsRequest,
  currentAccount,
  currentUser,
  fullScreen,
  reportName,
  reportType,
  scheduledReports,
  setScheduledReports,
}: AnalyticsDetailReportHeaderProps) {
  const { account, accountList } = useFilters({
    currentUser,
  });
  const { headers = [], title = "" } = analyticReport;

  const {
    anchorEl,
    associatedReports,
    closeEditModal,
    deleteReport,
    editReport,
    existingValues,
    handleEditModalToggle,
    handleInfoModalToggle,
    handleMenuClose,
    handleScheduleClick,
    handleScheduleModal,
    navigateToReportList,
    openEditModal,
    openInfoModal,
    openScheduleModal,
    scheduleReport,
    sendReport,
    updateReport,
  } = useDetailReportHeader({
    account,
    accountList,
    addNotification,
    billingReport,
    createAnalyticExportReportsRequest,
    currentAccount,
    currentReport: analyticReport,
    currentUser,
    reportName,
    reportType,
    scheduledReports,
    setScheduledReports,
  });

  const slug = title.replaceAll(/ /gi, "");
  const numberOfReports = associatedReports.length ?? 0;
  return (
    <>
      <Box
        data-product-tour={`AnalyticsReports-${slug}-Icon`}
        display="flex"
        mt={4}
        mb={0.5}
      >
        <H3 padding>{title}</H3>
        <AnalyticsInfoModal
          fullScreen={fullScreen}
          handleInfoModalToggle={handleInfoModalToggle}
          headers={headers}
          openInfoModal={openInfoModal}
          title={title}
        />
      </Box>
      <Box
        data-product-tour={`AnalyticsReports-${slug}-EmailAndScheduling`}
        mt={4}
        mb={0.5}
      >
        <IconButton
          onClick={sendReport}
          aria-label={`Send ${title} Report via Email`}
          size="large"
        >
          <EmailIcon color="primary" fontSize="small" style={style} />
        </IconButton>
        <IconButton
          onClick={handleScheduleClick}
          aria-label={`Schedule ${title} Report via Email`}
          size="large"
        >
          <Badge
            badgeContent={numberOfReports}
            color="error"
            aria-label={`${numberOfReports} Scheduled ${title} Report`}
            sx={{
              "& .MuiBadge-badge": {
                color: "customColors.badgeText",
              },
            }}
          >
            <CalendarTodayIcon color="primary" fontSize="small" style={style} />
          </Badge>
        </IconButton>
        {Boolean(anchorEl) && (
          <Menu
            aria-label={`${title} Report Scheduling Menu`}
            id={`${title} Report Scheduling Menu`}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleScheduleModal}>New Report</MenuItem>
            <MenuItem onClick={handleEditModalToggle}>{`Edit Scheduled Report${
              numberOfReports > 1 ? "s" : ""
            }`}</MenuItem>
          </Menu>
        )}
      </Box>
      <EditAnalyticsReportModal
        accountList={accountList}
        currentAccount={currentAccount}
        deleteReport={deleteReport}
        editReport={editReport}
        existingReports={associatedReports}
        fullScreen={fullScreen}
        handleEditModalToggle={handleEditModalToggle}
        open={openEditModal}
        title={title}
      />
      <ScheduleAnalyticsReportModal
        accountList={accountList}
        fullScreen={fullScreen}
        handleClose={closeEditModal}
        initialValues={existingValues}
        navigateToReportList={navigateToReportList}
        open={openScheduleModal}
        scheduleReport={scheduleReport}
        title={title}
        updateReport={updateReport}
      />
    </>
  );
}

const mapStateToProps = (): {} => {
  return {};
};

const withConnect = connect(
  () => {
    return mapStateToProps;
  },
  {
    ...notificationsActionGenerators,
  },
);

export default compose(
  withRecord({
    actions: ["update"],
    container: "features/AccountAnalytics/AnalyticsDetailReport/schedule",
    type: "scheduleExports",
  }),
  withRecord({
    actions: ["create"],
    container: "features/AccountAnalytics/AnalyticsDetailReport/export",
    type: "analyticExportReports",
  }),
  withSizes(({ width }) => {
    return { fullScreen: width < breakpoints.medium };
  }),
  withConnect,
)(AnalyticsDetailReportHeader);

import { useState, useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import qs from "query-string";

import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";

import { useSnackbar } from "notistack";
import {
  AnalyticReport,
  DayToSendKeys,
  ReportMember,
  ReportType,
  ReportValues,
  SendFrequencyKeys,
  UseDetailReportHeaderProps,
  UseDetailReportHeaderReturn,
} from "../models/AnalyticsModels";
import { scheduleAnalyticReport } from "../api/scheduleAnalyticReport";
import SnackbarCloseButton from "containers/SnackbarCloseButton";
import { formatYearMonthDayUtc } from "utils/date";
import { Snackbar } from "components/Snackbar";

const initialValues = {
  account: "",
  dayToSend: "",
  sendFrequency: "",
  timePeriod: "",
};

const useDetailReportHeader = ({
  account,
  accountList,
  addNotification,
  billingReport,
  createAnalyticExportReportsRequest,
  currentAccount,
  currentReport,
  currentUser,
  reportName,
  reportType,
  scheduledReports,
  setScheduledReports,
}: UseDetailReportHeaderProps): UseDetailReportHeaderReturn => {
  const { enqueueSnackbar } = useSnackbar();
  const { search } = useLocation();

  const [associatedReports, setAssociatedReports] = useState<ReportMember[]>(
    [],
  );
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [existingValues, setExistingValues] = useState<ReportValues>(
    initialValues as ReportValues,
  );
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openInfoModal, setOpenInfoModal] = useState<boolean>(false);
  const [openScheduleModal, setOpenScheduleModal] = useState<boolean>(false);

  useEffect(() => {
    const getAssociatedReports = scheduledReports.filter(
      ({ reports, account: slug }) => {
        return (
          slug === currentAccount?.id &&
          reports.find((report: string) => {
            return report === reportName;
          })
        );
      },
    );
    setAssociatedReports(getAssociatedReports);
  }, [scheduledReports]);

  const { title } = currentReport ?? {};

  const { startDate, endDate } = useMemo(() => {
    return qs.parse(search);
  }, [search]);

  const notificationMessages = {
    deleteScheduleError: `Something went wrong trying to delete ${title} Report.`,
    deleteScheduleSuccess: `${title} Report successfully deleted!`,
    scheduleError: `Something went wrong trying to schedule ${title} Report.`,
    scheduleSuccess: `${title} Report successfully scheduled!`,
    sendReportError: `Something went wrong trying to export ${title} Report.`,
    sendReportSuccess: `Data export successful! ${title} Report will be emailed to you shortly.`,
    updateScheduleError: `Something went wrong trying to edit ${title} Report.`,
    updateScheduleSuccess: `${title} Report successfully edited!`,
  };

  const handleMenuClick = (target: Element) => {
    return setAnchorEl(target);
  };

  const handleMenuClose = () => {
    return setAnchorEl(null);
  };

  const handleInfoModalToggle = () => {
    return setOpenInfoModal(!openInfoModal);
  };

  const handleEditModalToggle = () => {
    if (anchorEl) handleMenuClose();
    setOpenEditModal(!openEditModal);
  };

  const handleScheduleModal = () => {
    if (anchorEl) handleMenuClose();
    setOpenScheduleModal(!openScheduleModal);
  };

  const closeEditModal = () => {
    handleScheduleModal();
    setExistingValues(initialValues as ReportValues);
  };

  const handleScheduleClick = ({
    currentTarget,
  }: {
    currentTarget: Element;
  }) => {
    if (isEmpty(associatedReports)) {
      handleScheduleModal();
    } else {
      handleMenuClick(currentTarget);
    }
  };

  const navigateToReportList = () => {
    handleScheduleModal();
    setOpenEditModal(!openEditModal);
  };

  const errorCallback = (report: ReportType) => {
    addNotification({
      message: notificationMessages[report],
      options: {
        action: (key: string) => {
          return <SnackbarCloseButton snackbarKey={key} />;
        },
        variant: "error",
      },
    });
  };

  const successCallback = (report: ReportType) => {
    addNotification({
      message: notificationMessages[report],
      options: {
        action: (key: string) => {
          return <SnackbarCloseButton snackbarKey={key} />;
        },
      },
    });
  };

  const sendReport = () => {
    const { slug, id } = currentAccount;
    const descendants = billingReport ? true : account.descendants;

    // formatYearMonthDayUTC() requires a Date type
    const reportStart = new Date(startDate as string);
    const reportEnd = new Date(endDate as string);

    createAnalyticExportReportsRequest(
      `/${slug}/analytics/${reportType}/${reportName}/export`,
      {
        account: id,
        currentUser,
        descendants,
        endDate: formatYearMonthDayUtc(reportEnd),
        reports: [reportName],
        startDate: formatYearMonthDayUtc(reportStart),
      },
      {
        successCallback: () => {
          return successCallback("sendReportSuccess");
        },
        errorCallback: () => {
          return errorCallback("sendReportError");
        },
      },
    );
  };

  const scheduleReport = async ({
    account: accountSlug,
    dayToSend,
    sendFrequency,
    timePeriod,
  }: {
    account: string;
    dayToSend: string;
    sendFrequency: string;
    timePeriod: SendFrequencyKeys;
  }) => {
    try {
      const { descendants = false } =
        accountList.find(({ slug }: { slug: string }) => {
          return slug === accountSlug;
        }) ?? {};
      const { name, email } = currentUser;
      const requestBody = {
        name,
        email,
        settings: {
          analyticsReports: scheduledReports.concat({
            account: currentAccount.id,
            descendants,
            reports: [reportName],
            schedule: `${sendFrequency}${dayToSend}`,
            timePeriod,
          }),
        },
      };
      const response = await scheduleAnalyticReport(
        currentUser.id,
        requestBody,
      );
      const data = await response.json();
      setScheduledReports(data.settings.analyticsReports);
      enqueueSnackbar(notificationMessages.scheduleSuccess, {
        content: (key, message) => {
          return <Snackbar id={key} message={message} variant="info" />;
        },
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar(notificationMessages.scheduleError, {
        content: (key, message) => {
          return <Snackbar id={key} message={message} variant="error" />;
        },
      });
    } finally {
      handleScheduleModal();
    }
  };

  const editReport = ({
    timePeriod,
    schedule,
    descendants = true,
  }: {
    timePeriod: SendFrequencyKeys;
    schedule: string;
    descendants: boolean;
  }) => {
    const [, sendFrequency, dayToSend] = schedule.match(/(.*)(BY.*)/) ?? [];
    const { slug = "" } =
      accountList.find(
        ({ descendants: descendantsParams }: { descendants: boolean }) => {
          return descendants === descendantsParams;
        },
      ) ?? {};
    setExistingValues({
      account: slug,
      dayToSend: dayToSend as DayToSendKeys,
      sendFrequency: sendFrequency as SendFrequencyKeys,
      timePeriod,
    });
    handleEditModalToggle();
    handleScheduleModal();
  };

  const filterReports = () => {
    return scheduledReports.filter(
      ({
        descendants = true,
        schedule: reportSchedule,
        timePeriod: reportTimePeriod,
        reports: [report],
      }: AnalyticReport) => {
        const {
          account: accountSlug,
          dayToSend,
          sendFrequency,
          timePeriod,
        } = existingValues;
        const existingSchedule = `${sendFrequency}${dayToSend}`;
        const correspondingAccount = accountList.find(
          ({ slug }: { slug: string }) => {
            return accountSlug === slug;
          },
        );
        const correspondingDescendants =
          descendants !== correspondingAccount?.descendants;
        const schedule = existingSchedule !== reportSchedule;
        const correspondingTimePeriod = timePeriod !== reportTimePeriod;
        const correspondingReport = report !== reportName;
        return (
          correspondingDescendants ||
          correspondingReport ||
          correspondingTimePeriod ||
          schedule
        );
      },
    );
  };

  const deleteReport = async (deletedReport: AnalyticReport) => {
    try {
      const deletedReportIndex = scheduledReports.findIndex(
        (report: AnalyticReport) => {
          return isEqual(report, deletedReport);
        },
      );
      const unmodifiedReports = scheduledReports
        .slice(0, deletedReportIndex)
        .concat(
          scheduledReports.slice(
            deletedReportIndex + 1,
            scheduledReports.length,
          ),
        );
      const { name, email, id } = currentUser ?? {};
      const requestBody = {
        name,
        email,
        settings: {
          analyticsReports: unmodifiedReports,
        },
      };
      const response = await scheduleAnalyticReport(id, requestBody);
      const data = await response.json();
      setScheduledReports(data.settings.analyticsReports);
      enqueueSnackbar(notificationMessages.deleteScheduleSuccess, {
        content: (key, message) => {
          return <Snackbar id={key} message={message} variant="info" />;
        },
      });
    } catch {
      enqueueSnackbar(notificationMessages.deleteScheduleError, {
        content: (key, message) => {
          return <Snackbar id={key} message={message} variant="error" />;
        },
      });
    } finally {
      handleEditModalToggle();
    }
  };

  const updateReport = async (updatedValues: ReportValues) => {
    if (!isEqual(updatedValues, existingValues)) {
      try {
        const unmodifiedReports = filterReports();
        const {
          account: accountSlug,
          sendFrequency,
          dayToSend,
          timePeriod,
        } = updatedValues;
        const { descendants = false } =
          accountList.find(({ slug }: { slug: string }) => {
            return slug === accountSlug;
          }) ?? {};
        const { name, email } = currentUser ?? {};
        const requestBody = {
          name,
          email,
          settings: {
            analyticsReports: unmodifiedReports.concat({
              account: currentAccount.id,
              descendants,
              reports: [reportName],
              schedule: `${sendFrequency}${dayToSend}`,
              timePeriod,
            }),
          },
        };
        const response = await scheduleAnalyticReport(
          currentUser.id,
          requestBody,
        );
        const data = await response.json();
        setScheduledReports(data.settings.analyticsReports);
        enqueueSnackbar(notificationMessages.updateScheduleSuccess, {
          content: (key, message) => {
            return <Snackbar id={key} message={message} variant="info" />;
          },
        });
      } catch {
        enqueueSnackbar(notificationMessages.updateScheduleError, {
          content: (key, message) => {
            return <Snackbar id={key} message={message} variant="error" />;
          },
        });
      } finally {
        setExistingValues(initialValues as ReportValues);
        handleScheduleModal();
      }
    }
  };

  return {
    anchorEl,
    associatedReports,
    closeEditModal,
    deleteReport,
    editReport,
    existingValues,
    handleEditModalToggle,
    handleInfoModalToggle,
    handleMenuClose,
    handleScheduleClick,
    handleScheduleModal,
    navigateToReportList,
    openEditModal,
    openInfoModal,
    openScheduleModal,
    scheduleReport,
    sendReport,
    updateReport,
  };
};

export { useDetailReportHeader };

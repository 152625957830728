import { useDispatch } from "react-redux";

import { useAccountSettingsActions } from "./useAccountSettingsActions";
import SnackbarCloseButton from "containers/SnackbarCloseButton";
import { useNotification, useCurrentAccount } from "hooks";
import { swapLinksInBody } from "components/MessageInputOptions/ShortenedLinkOption/utils/swapLinksInBody";

export const useAccountSettingsSubmit = (
  messageSettingType = "",
  shortenedLink = {},
) => {
  const dispatch = useDispatch();
  const account = useCurrentAccount();
  const { addNotification } = useNotification();
  const { updateAccountRequest } = useAccountSettingsActions();

  const errorCallback = (actions) => {
    return (errors) => {
      const validationErrors = errors.validationErrors?.settings || {
        message: ["There was an error saving your account settings"],
      };

      const errorsKey = Object.keys(validationErrors)[0];
      const errorMessage = validationErrors[errorsKey][0];
      actions.setSubmitting(false);
      actions.setErrors(validationErrors);
      if (addNotification) {
        dispatch(
          addNotification({
            message: errorMessage || "Something went wrong!",
            options: {
              variant: "error",
            },
          }),
        );
      }
    };
  };

  const successCallback = (
    actions,
    message = "Account settings saved successfully!",
  ) => {
    return () => {
      actions.setSubmitting(false);

      dispatch(
        addNotification({
          message,
          options: {
            // This is a carry over from withAccountSettingsSubmit, but should not be passing a component to the store!
            // Not really sure why this is being passed, or why we are pushing this to the store via an action
            // other than as a means to provide a close button on the toast, which can be done another way.
            // Notifications will need to be revisited later...
            action: (key) => {
              return <SnackbarCloseButton snackbarKey={key} />;
            },
          },
        }),
      );
    };
  };

  const handleShortLinkInMessageSetting = (values) => {
    const { fullLink, shortLink } = shortenedLink;
    const formValues = values;
    const isAutoReply = Boolean(messageSettingType === "autoReply");
    const formBody = isAutoReply
      ? formValues.settings.autoReplyMessage.value.message
      : formValues.settings[`${messageSettingType}Message`].value;
    const bodyWithReplacedLinks = swapLinksInBody(
      shortLink,
      `{{ "${fullLink}" | shortlink }}`,
      formBody,
    );
    if (isAutoReply) {
      formValues.settings.autoReplyMessage.value.message =
        bodyWithReplacedLinks;
    } else {
      formValues.settings[`${messageSettingType}Message`].value =
        bodyWithReplacedLinks;
    }
    return formValues;
  };

  const handleSubmit = (values, actions, successMessage) => {
    const { fullLink } = shortenedLink || "";
    const formValues = fullLink
      ? handleShortLinkInMessageSetting(values)
      : values;
    updateAccountRequest(account.id, formValues, {
      successCallback: successCallback(actions, successMessage),
      errorCallback: errorCallback(actions),
    });
  };

  return { handleSubmit, updateAccountRequest };
};

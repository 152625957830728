import {
  Box,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import { ConfirmationModalProps } from "./types";
import { DialogFooter } from "components/DialogFooter";
import { SimpleDialog } from "components/SimpleDialog";

function ConfirmationModal({
  additionalMessage,
  boldText,
  buttonText = "Confirm",
  cancellationHandler,
  confirmationHandler,
  isOpen,
  message,
  title,
}: ConfirmationModalProps) {
  const makeTextBold = () => {
    return <strong>{boldText}</strong>;
  };

  return (
    <SimpleDialog open={isOpen} title={title} handleClose={cancellationHandler}>
      <DialogContent sx={{ padding: "1rem" }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <DialogContentText
            aria-label="Confirmation Message"
            variant="body1"
            color="common.black"
            marginBottom="1rem"
          >
            {message}
            {boldText && makeTextBold()}
            {additionalMessage}
          </DialogContentText>
          <Typography variant="body1" fontWeight={700}>
            Are you sure you want to continue?
          </Typography>
        </Box>
      </DialogContent>
      <DialogFooter
        confirmText={buttonText}
        onConfirm={confirmationHandler}
        onCancel={cancellationHandler}
      />
    </SimpleDialog>
  );
}

export default ConfirmationModal;

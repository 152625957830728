/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { useState, MouseEvent } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import { Contact } from "features/ContactSidebar/types";
import { getFormattedIntegrationName } from "features/ContactSync/utils/getFormattedIntegrationName";
import { IntegrationIdLink } from "features/ContactSync/IntegrationIdLink/IntegrationIdLink";
import { getIntegrationDataKeys } from "features/ContactSync/utils/getIntegrationDataKeys";
import { getContactSyncId } from "features/ContactSync/utils/getContactSyncId";
import { createSnackbarContent } from "components/Snackbar";

interface ContactSyncHyperlinkProps {
  contact: Contact;
}

function ContactSyncHyperlink({ contact }: ContactSyncHyperlinkProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const handleMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const integrationNames = getIntegrationDataKeys(contact);

  return integrationNames.length > 0 ? (
    integrationNames.length > 1 ? (
      <>
        <Button
          onClick={handleMenuOpen}
          sx={{
            color: (theme) => {
              return theme.palette.text.hyperlink;
            },
            fontSize: "12px",
            fontWeight: 400,
            marginRight: "auto",
            padding: 0,
            paddingLeft: "4px",
            textDecoration: "underline",
            textTransform: "none",
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          {integrationNames.length} integrations
        </Button>
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
        >
          {integrationNames.map((integration) => {
            const integrationData = contact.data[integration];
            const formattedIntegrationName =
              getFormattedIntegrationName(integration) ?? "";
            const contactSyncId = getContactSyncId(integrationData) ?? "";
            return (
              <MenuItem
                key={integration}
                onClick={() => {
                  if (integrationData?.Candidate?.link) {
                    return window
                      .open(integrationData?.Candidate?.link ?? "", "_blank")
                      ?.focus();
                  }
                  navigator.clipboard.writeText(contactSyncId);
                  enqueueSnackbar("Integration ID copied to clipboard", {
                    content: createSnackbarContent("info"),
                  });
                }}
                sx={(theme) => {
                  return {
                    color: integrationData?.Candidate?.link
                      ? theme.palette.text.hyperlink
                      : "",
                    fontSize: "16px",
                    fontWeight: integrationData?.Candidate?.link ? 700 : 400,
                    textDecoration: integrationData?.Candidate?.link
                      ? "underline"
                      : "",
                  };
                }}
              >
                {formattedIntegrationName} ID {contactSyncId}
              </MenuItem>
            );
          })}
        </Menu>
      </>
    ) : (
      <IntegrationIdLink
        fromConversation
        integrationData={contact?.data[integrationNames[0]]}
        integrationName={integrationNames[0]}
      />
    )
  ) : null;
}

export { ContactSyncHyperlink };

import { fromJS } from "immutable";
import { combineReducers } from "redux-immutable";
import deepEqualSelector from "utils/deepEqualSelector";

// ACTION TYPES
const ADD_NOTIFICATION = "app/Notifications/ADD_NOTIFICATION";
const REMOVE_NOTIFICATION = "app/Notifications/REMOVE_NOTIFICATION";
const actionTypes = { ADD_NOTIFICATION, REMOVE_NOTIFICATION };

export const addNotification = (notification) => {
  return {
    type: ADD_NOTIFICATION,
    notification: {
      key: new Date().getTime() + Math.random(),
      ...notification,
    },
  };
};

export const removeNotification = (key) => {
  return {
    type: REMOVE_NOTIFICATION,
    key,
  };
};

const actionGenerators = { addNotification, removeNotification };

// REDUCER
const notifications = (state = fromJS([]), action) => {
  switch (action.type) {
    case actionTypes.ADD_NOTIFICATION:
      return state.unshift(action.notification);
    case actionTypes.REMOVE_NOTIFICATION:
      return state.filterNot((notification) => {
        return notification.key === action.key;
      });
    default:
      return state;
  }
};
const reducer = combineReducers({ notifications });

// SELECTORS
const selectNotifications = deepEqualSelector((state) => {
  return state.getIn(["notificationsContainer", "notifications"]).toJS();
});

const selectors = { selectNotifications };

export { actionTypes, actionGenerators, reducer, selectors };

import { useState, useEffect, useCallback } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import debounce from "lodash/debounce";
import get from "lodash/get";
import { Formik, Field, Form } from "formik";
import pluralize from "pluralize";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import withSizes from "react-sizes";

import TextField from "@mui/material/TextField";
import ViewGroupButton from "./components/ViewGroupButton";
import { actionGenerators as notificationsActionGenerators } from "features/EntryPoint/containers/Notifications/state";
import { NewGroupValidationSchema } from "formHelpers/validationSchemas";
import breakpoints from "utils/styles/breakpoints";
import fixedEncodeURIComponent from "utils/fixedEncodeURIComponent";
import Logo from "components/Logo";
import Modal from "components/Modal";
import ModalContent from "components/ModalContent";
import ModalHeader from "components/ModalHeader";
import { selectCurrentAccount } from "features/EntryPoint/containers/App/selectors";
import SnackbarCloseButton from "containers/SnackbarCloseButton";
import withRecord from "higherOrderComponents/withRecord";

const filter = createFilterOptions();

function AddContactsToGroupModal({
  addNotification,
  closeModal,
  contacts,
  contactTotal,
  createContactCollectionRequest,
  createGroupRequest,
  currentAccount,
  fetchGroupCollectionRequest,
  fullScreen,
  visible,
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const [groupOptions, setGroupOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAdd, setShowAdd] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetchGroupCollectionRequest(
      `/${currentAccount.slug}/group_filters/accounts/groups${
        searchQuery ? `?q=${searchQuery}` : ""
      }`,
      null,
      {
        successCallback: (response) => {
          setShowAdd(true);
          setIsLoading(false);
          setGroupOptions(
            get(response, ["members"], []).map((group) => {
              return {
                name: group.name,
                slug: group.slug,
              };
            }),
          );
        },
        errorCallback: (errors) => {
          const validationErrors = get(errors, ["validationErrors"], {
            name: "Something went wrong!",
          });
          setShowAdd(true);
          setIsLoading(false);
          addNotification({
            message: validationErrors.name,
            options: {
              action: (key) => {
                return <SnackbarCloseButton snackbarKey={key} />;
              },
              variant: "error",
            },
          });
        },
      },
    );
  }, [searchQuery]);

  const handleChange = useCallback(
    debounce((value) => {
      setSearchQuery(fixedEncodeURIComponent(value));
    }, 1000),
    [],
  );

  const handleClose = () => {
    closeModal();
    setSearchQuery("");
  };

  const getCallbacks = (actions, failureMessage, successMessage, values) => {
    return {
      successCallback: (response) => {
        const group = values.slug === "" ? response.slug : values.slug;
        const currentAccountSlug = currentAccount.slug;
        actions.setSubmitting(false);
        addNotification({
          message: successMessage,
          options: {
            action: (key) => {
              return (
                <>
                  <ViewGroupButton
                    groupPathname={`/${currentAccountSlug}/groups/mine/${group}`}
                    snackbarKey={key}
                  />
                  <SnackbarCloseButton snackbarKey={key} />
                </>
              );
            },
          },
        });
        handleClose();
      },
      errorCallback: (errors) => {
        const validationErrors = get(errors, ["validationerrors"], {
          name: "Something went wrong!",
        });
        addNotification({
          message: failureMessage,
          options: {
            action: (key) => {
              return <SnackbarCloseButton snackbarKey={key} />;
            },
            variant: "error",
          },
        });
        actions.setSubmitting(false);
        actions.setErrors(validationErrors);
      },
    };
  };

  const handleUpdateGroup = (values, actions) => {
    const { slug: group } = values;
    const successMessage = `You successfully added ${pluralize(
      "contact",
      contactTotal,
      true,
    )} to this group.`;
    const failureMessage = "We were unable to add contacts to the group.";
    createContactCollectionRequest(
      `/groups/${group}/contacts`,
      { contacts },
      getCallbacks(actions, failureMessage, successMessage, values),
    );
  };

  const handleCreateGroup = (values, actions) => {
    const valuesToSend = { ...values, contacts };
    const successMessage = `You successfully created this group with ${pluralize(
      "contact",
      contactTotal,
      true,
    )}.`;
    const failureMessage = "We were unable to create this group.";
    createGroupRequest(
      `/${currentAccount.slug}/groups`,
      valuesToSend,
      getCallbacks(actions, failureMessage, successMessage, values),
    );
  };

  const displayFilterOptions = (formOptions, formParams) => {
    const isUserCreated = formParams.inputValue !== "";
    const isNewValue = !formOptions.some((option) => {
      return (
        option.slug !== "" &&
        option.name.toLowerCase() === formParams.inputValue.toLowerCase()
      );
    });
    return isUserCreated && isNewValue && showAdd;
  };
  const initialValues = { name: "", slug: "" };
  return (
    <Modal
      fullScreen={fullScreen}
      transitionIn={visible}
      ariaLabel="Add Contacts to Group Modal"
      closeModal={handleClose}
    >
      <ModalHeader ariaLabel="Add Contacts to a Group" closeModal={handleClose}>
        Add Contacts to a Group
      </ModalHeader>
      <ModalContent maxWidth="100%">
        <Box minWidth="360px">
          <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              if (
                groupOptions.find((option) => {
                  return option.slug === values.slug;
                })
              ) {
                handleUpdateGroup(values, actions);
              } else {
                handleCreateGroup(values, actions);
              }
            }}
          >
            {({ errors, touched, isSubmitting }) => {
              return (
                <Form>
                  <Field type="select" name="AddContactsToGroupForm">
                    {({ form }) => {
                      return (
                        <Autocomplete
                          autoHighlight
                          disableClearable
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            if (displayFilterOptions(options, params)) {
                              filtered.push({
                                name: params.inputValue.trim(),
                                title: `Add a group named "${params.inputValue.trim()}"`,
                                slug: "",
                              });
                            }
                            return filtered;
                          }}
                          freeSolo
                          fullWidth
                          getOptionLabel={(option) => {
                            return typeof option === "string"
                              ? option
                              : option.name;
                          }}
                          id="group-name"
                          label="Group Name"
                          loading={isLoading}
                          onChange={(event, newValue) => {
                            if (typeof newValue === "string") {
                              form.setFieldValue("name", newValue.trim());
                            } else {
                              form.setFieldValue("name", newValue.name.trim());
                              form.setFieldValue("slug", newValue.slug);
                            }
                          }}
                          options={groupOptions}
                          renderOption={(props, option) => {
                            return option.slug === "" ? (
                              <li {...props}>{option.title}</li>
                            ) : (
                              <li {...props}>{option.name}</li>
                            );
                          }}
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                aria-label="group name input"
                                error={touched.name && Boolean(errors.name)}
                                helperText={
                                  touched.name ? errors.name || " " : " "
                                }
                                required
                                label="Group Name"
                                placeholder="Begin typing a group name"
                                variant="outlined"
                                onChange={(e) => {
                                  setShowAdd(false);
                                  handleChange(e.target.value.trim());
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {isLoading ? (
                                        <Logo
                                          animate
                                          color="transparent"
                                          dotColor="primary"
                                          width={20}
                                        />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                              />
                            );
                          }}
                          variant="outlined"
                        />
                      );
                    }}
                  </Field>
                  <Box textAlign="right" mt={1}>
                    <Button
                      aria-label="Add Contacts to Group"
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Add Contacts
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </ModalContent>
    </Modal>
  );
}

AddContactsToGroupModal.propTypes = {
  addNotification: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  contacts: PropTypes.any,
  contactTotal: PropTypes.number.isRequired,
  createContactCollectionRequest: PropTypes.func.isRequired,
  createGroupRequest: PropTypes.func.isRequired,
  currentAccount: PropTypes.object.isRequired,
  fetchGroupCollectionRequest: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    currentAccount: selectCurrentAccount(state, props),
  };
};

const withConnect = connect(
  () => {
    return mapStateToProps;
  },
  {
    ...notificationsActionGenerators,
  },
);

export default compose(
  withConnect,
  withRecord({
    actions: ["fetch"],
    container:
      "features/Contacts/containers/AddContactsToGroupModal/groupCollection",
    shape: { members: [] },
    type: "groupCollection",
    noFetch: true,
    showLoader: () => {
      return false;
    },
  }),
  withRecord({
    actions: ["create"],
    container: "features/Contacts/containers/AddContactsToGroupModal/group",
    type: "group",
  }),
  withRecord({
    actions: ["create"],
    container:
      "features/Contacts/containers/AddContactsToGroupModal/contactCollection",
    shape: { members: [{ phones: { members: [] } }] },
    type: "contactCollection",
  }),
  withRouter,
  withSizes(({ width }) => {
    return { fullScreen: width < breakpoints.medium };
  }),
)(AddContactsToGroupModal);

import { SyntheticEvent, useState } from "react";
import get from "lodash/get";
import { Box, Card, Link, Popover, Typography, styled } from "@mui/material";
import { DeliveryStatsProps } from "./types";

const Bar = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flex: "0 0 auto",
    height: "9px",
    width: "100%",
    borderRadius: "20px",
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    position: "relative",
    zIndex: 1,
    overflow: "hidden",
    transition: "all ease-in-out 0.15s",
  };
});

const PopoverButton = styled(Link)(({ theme }) => {
  return {
    color: theme.palette.text.hyperlink,
    fontWeight: 700,
    textDecoration: "underline",
  };
});

const LegendRow = styled("div")({
  display: "flex",
  alignItems: "center",
});

const LegendKey = styled("div")(({ theme }) => {
  return {
    height: "0.5rem",
    width: "0.5rem",
    marginRight: "0.5rem",
    borderRadius: "50%",
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.customColors.enabledBorder}`,
  };
});

function DeliveryStats({ currentAccount, deliveryStats }: DeliveryStatsProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const supportLink = get(
    currentAccount,
    ["settings", "supportLink", "value"],
    "https://help.textus.com/s",
  );

  const closePopover = () => {
    setAnchorEl(null);
  };

  const openPopover = (event: SyntheticEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const {
    campaignDailyLimit = 0,
    campaignMessagesScheduled = 0,
    campaignMessagesUsed = 0,
  } = deliveryStats ?? {};
  const sentLength = (campaignMessagesUsed / campaignDailyLimit) * 100;
  const scheduledLength =
    ((campaignMessagesScheduled + campaignMessagesUsed) / campaignDailyLimit) *
    100;
  return (
    <Box
      onMouseEnter={openPopover}
      data-product-tour="CampaignUsage"
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding="0.5rem 0.75rem 1rem 0.75rem"
      position="relative"
      flex="0 0 auto"
      overflow="hidden"
      height="auto"
      sx={(theme) => {
        return {
          borderBottom: `1px solid ${theme.palette.divider}`,
          flexFlow: "column nowrap",
        };
      }}
    >
      {campaignDailyLimit && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Box fontWeight={500}>
            <Typography variant="label" color="text.secondary">
              {campaignMessagesUsed + campaignMessagesScheduled} of{" "}
              {campaignDailyLimit} messages used today
            </Typography>
          </Box>
          <Bar>
            <Box
              sx={(theme) => {
                return {
                  position: "absolute",
                  width: `${sentLength}%`,
                  top: 0,
                  left: 0,
                  height: "100%",
                  zIndex: 5,
                  borderRadius: "20px",
                  transition: "all cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.3s",
                  background: theme.palette.primary.main,
                };
              }}
            />
            <Box
              height="100%"
              left={0}
              position="absolute"
              top={0}
              width={`${scheduledLength}%`}
              sx={({ palette }) => {
                return {
                  backgroundColor:
                    scheduledLength > 79
                      ? palette.error.main
                      : palette.secondary.main,
                  backgroundSize: "200% 200%",
                  borderRadius: "20px",
                  transition: "all cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.3s",
                  zIndex: 3,
                };
              }}
            />
          </Bar>
        </Box>
      )}
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(anchorEl)}
        onClose={closePopover}
      >
        <Card onMouseLeave={closePopover}>
          <Box padding="1rem" width="286px" color="text.primary">
            <Box display="flex" flexDirection="column" gap="0.5rem">
              <LegendRow>
                <LegendKey
                  sx={{
                    backgroundColor:
                      sentLength > 79 ? "error.main" : "primary.main",
                  }}
                />
                <Typography variant="body1">
                  Used: {campaignMessagesUsed}
                </Typography>
              </LegendRow>
              <LegendRow>
                <LegendKey
                  sx={{
                    backgroundColor:
                      scheduledLength > 79 ? "error.main" : "secondary.main",
                  }}
                />
                <Typography variant="body1">
                  Scheduled: {campaignMessagesScheduled}
                </Typography>
              </LegendRow>
              <LegendRow>
                <LegendKey />
                <Typography variant="body1">
                  Daily Limit: {campaignDailyLimit}
                </Typography>
              </LegendRow>
            </Box>
            {supportLink ? (
              <Box
                component="footer"
                display="flex"
                flexDirection="row"
                gap="0.25rem"
                marginTop="1rem"
              >
                <Typography variant="body2Hyperlink">
                  <PopoverButton
                    href={supportLink}
                    target="_new"
                    sx={{ verticalAlign: "baseline" }}
                  >
                    Contact support
                  </PopoverButton>
                </Typography>
                <Typography variant="body2">to increase your limit.</Typography>
              </Box>
            ) : null}
          </Box>
        </Card>
      </Popover>
    </Box>
  );
}

export { DeliveryStats };

import { PropsWithChildren } from "react";
import PageHeader from "components/Page/PageHeader";
import SettingsPageWrapper from "components/SettingsPageComponents/SettingsPageWrapper";
import SettingsPageContent from "components/SettingsPageComponents/SettingsPageContent";

export function TeamManagement({
  children,
  toggleSidebar,
}: PropsWithChildren<{ toggleSidebar: (...args: any[]) => boolean }>) {
  return (
    <SettingsPageWrapper>
      <PageHeader title="Team Management" toggleSidebar={toggleSidebar} />
      <SettingsPageContent minHeight="100%" maxWidth="1024px">
        {children}
      </SettingsPageContent>
    </SettingsPageWrapper>
  );
}

/* eslint-disable unicorn/prefer-export-from */
/**
 * This file contains all icons used throughout the app.
 */

// ==== MUI ICONS ==== //
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AddIcon from "@mui/icons-material/Add";
import ArchiveFilledIcon from "@mui/icons-material/Archive";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import CachedIcon from "@mui/icons-material/Cached";
import CampaignFilledIcon from "@mui/icons-material/Campaign";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import DraftsIcon from "@mui/icons-material/Drafts";
import EditIcon from "@mui/icons-material/Edit";
import ErrorIcon from "@mui/icons-material/Error";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import EventBusy from "@mui/icons-material/EventBusy";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HomeIcon from "@mui/icons-material/Home";
import GroupsIcon from "@mui/icons-material/Groups";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import InfoIcon from "@mui/icons-material/Info";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LinkIcon from "@mui/icons-material/Link";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import MobileOffIcon from "@mui/icons-material/MobileOff";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import SmsIcon from "@mui/icons-material/Sms";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import UnarchiveOutlinedIcon from "@mui/icons-material/UnarchiveOutlined";
import UndoIcon from "@mui/icons-material/Undo";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";

// ==== CUSTOM ICONS ==== //
import BoltIcon from "@mui/icons-material/Bolt";
import BullhornIcon from "./BullhornIcon";
import CampaignIcon from "./CampaignIcon";
import InfoOutlineIcon from "./InfoOutlineIcon";
import IntegrationIcon from "./IntegrationIcon";
import LogoIcon from "./LogoIcon";
import SequencesIcon from "./SequencesIcon";
import SignatureIcon from "./SignatureIcon";

export {
  AccountBoxIcon,
  AddIcon,
  ArchiveFilledIcon,
  ArrowBackIcon,
  ArrowBackIosNewIcon,
  ArrowDropDownIcon,
  AttachFileIcon,
  BoltIcon,
  BookOutlinedIcon,
  CachedIcon,
  CampaignFilledIcon,
  CheckBoxIcon,
  CheckCircleIcon,
  CheckIcon,
  CloseIcon,
  CloudDownloadIcon,
  CloudUploadIcon,
  DeleteIcon,
  DoneIcon,
  DraftsIcon,
  EditIcon,
  ErrorIcon,
  ErrorOutlineIcon,
  EventBusy,
  ExpandLessIcon,
  ExpandMoreIcon,
  HelpOutlineIcon,
  HomeIcon,
  GroupsIcon,
  GroupAddIcon,
  InfoIcon,
  InfoOutlinedIcon,
  LinkIcon,
  MarkunreadIcon,
  MobileOffIcon,
  MoreVertIcon,
  PersonIcon,
  PeopleIcon,
  RecentActorsIcon,
  RemoveCircleIcon,
  SearchIcon,
  SendIcon,
  ScheduleIcon,
  SmsIcon,
  StarBorderIcon,
  StarIcon,
  SupervisorAccountIcon,
  SwapHorizIcon,
  UploadFileIcon,
  UnarchiveOutlinedIcon,
  UndoIcon,
  WarningAmberIcon,
  LockIcon,
  LockOpenIcon,
  BullhornIcon,
  CampaignIcon,
  InfoOutlineIcon,
  IntegrationIcon,
  LogoIcon,
  SequencesIcon,
  SignatureIcon,
};

import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import queryString from "query-string";
import PropTypes from "prop-types";
import { actionGenerators, saga } from "./containers/LoginScreen/state";
import Main from "./containers/LoginScreen/components/Main";
import { LoginPage } from "./LoginPage";
import injectSaga from "utils/injectSaga";
import { actionGenerators as notificationsActionGenerators } from "features/EntryPoint/containers/Notifications/state";

class LoginScreen extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    performLoginRequest: PropTypes.func.isRequired,
    requestResetPassword: PropTypes.func.isRequired,
  };

  inNativeMobileAuth = () => {
    const { location } = this.props;
    const queryParams = queryString.parse(location.search);

    return !!queryParams.mobileNativeAuth;
  };

  render() {
    /**
     * Fake feature flag for login redesign
     */
    if (localStorage.getItem("login-redesign") !== "true") {
      return <Main {...this.props} />;
    }

    if (this.inNativeMobileAuth()) {
      return <Main {...this.props} />;
    }

    return (
      <LoginPage
        onLogin={this.props.performLoginRequest}
        onResetPassword={this.props.requestResetPassword}
      />
    );
  }
}

const withConnect = connect(
  () => {
    return {};
  },
  {
    ...actionGenerators,
    ...notificationsActionGenerators,
  },
);

const withSaga = injectSaga({ key: "LoginContainer", saga });

export default compose(withConnect, withSaga)(LoginScreen);

import { Dialog, DialogContent, DialogContentText } from "@mui/material";
import { DialogFooter } from "components/DialogFooter";
import { DialogHeader } from "components/DialogHeader";

type CancelCampaignDialogueProps = {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export function CancelCampaignDialogue({
  show,
  onClose,
  onConfirm,
}: CancelCampaignDialogueProps) {
  return (
    <Dialog open={show} maxWidth="xs">
      <DialogHeader onClose={onClose} title="Cancel campaign?" />

      <DialogContent>
        <DialogContentText
          aria-label="Confirmation Message"
          variant="body1"
          color="common.black"
        >
          This action cannot be undone and the campaign will no longer send messages.
        </DialogContentText>
      </DialogContent>

      <DialogFooter
        cancelText="Nevermind"
        onCancel={onClose}
        onConfirm={onConfirm}
      />
    </Dialog>
  );
}

/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ConnectMergeIntegrationButton } from "../ConnectMergeIntegrationButton";
import { IntegrationCardProps } from "./types";

export function IntegrationCard({
  handleToggleIntegration,
  integration,
  setCustomAuthorizationDialogOpen,
  setOauthDialogOpen,
  selectedIntegration,
  setSelectedIntegration,
}: IntegrationCardProps) {
  const renderCardFooter = () => {
    /* 
      Card footer for connected integrations
    */
    if (integration.id) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "16px",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={integration.active}
                color="secondary"
                onClick={() => {
                  if (handleToggleIntegration) {
                    handleToggleIntegration(integration);
                  }
                }}
              />
            }
            label="Active"
          />

          <Button
            color="primary"
            onClick={() => {
              setSelectedIntegration(integration);
            }}
            variant="outlined"
          >
            {" "}
            MANAGE{" "}
          </Button>
        </Box>
      );
    }
    /* 
      Card footer for webhook card
    */
    if (integration.provider === "webhook") {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            padding: "16px",
          }}
        >
          <Button
            color="primary"
            onClick={() => {
              setSelectedIntegration(integration);
            }}
            variant="outlined"
          >
            <AddIcon color="primary" /> WEBHOOK{" "}
          </Button>
        </Box>
      );
    }
    /* 
      Card footer for available integrations
    */
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          padding: "16px",
        }}
      >
        {integration.strategy === "merge" && (
          <ConnectMergeIntegrationButton
            integration={integration}
            selectedIntegration={selectedIntegration ?? null}
            setSelectedIntegration={setSelectedIntegration}
          />
        )}
        {integration.strategy === "custom" && (
          <Button
            onClick={() => {
              if (setCustomAuthorizationDialogOpen) {
                setCustomAuthorizationDialogOpen(true);
              }
              setSelectedIntegration(integration);
            }}
            sx={{ color: "primary", paddingX: "16px", paddingY: "6px" }}
            variant="outlined"
          >
            {" "}
            CONNECT{" "}
          </Button>
        )}
        {integration.strategy === "oauth" && (
          <Button
            onClick={() => {
              if (setOauthDialogOpen) {
                setOauthDialogOpen(true);
              }
              setSelectedIntegration(integration);
            }}
            sx={{ color: "primary", paddingX: "16px", paddingY: "6px" }}
            variant="outlined"
          >
            {" "}
            CONNECT{" "}
          </Button>
        )}
      </Box>
    );
  };

  return (
    <Grid item xs={12} sm={12} md={6} lg={4} minWidth="288px">
      <Box
        sx={{
          border: "1px solid",
          borderColor: "rgba(0, 0, 0, 0.12)",
          borderRadius: "4px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            padding: "8px",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              paddingTop: "8px",
              paddingLeft: "8px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            variant="h5"
          >
            {integration.providerName}
          </Typography>
          {/* Luke TODO: render health icons here for connected integrations */}
        </Box>
        {renderCardFooter()}
      </Box>
    </Grid>
  );
}

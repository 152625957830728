import useIsExtensionPopout from "./useIsExtensionPopout";

export const useIsChromeExtension = (): boolean => {
  // If Tesseract is not in a Iframe, just return false and dont chack anything else
  if (!useIsExtensionPopout()) return false;

  // This is because Firefox don't accept ancestorOrigins
  if (!window.location.ancestorOrigins[0]) return false;
  return window.location.ancestorOrigins[0].startsWith("chrome-extension");
};

import { useEffect, useMemo } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import withSizes from "react-sizes";

import { Box, Paper, IconButton, Chip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { capitalize } from "lodash";
import { LicenseDelegationTableProps } from "../models/AccountPlanModels";
import { optimisticUpdateFunc } from "../state";
import { useAccountPlans } from "../hooks/useAccountPlans";
import { useLicenseGrid } from "../hooks/useLicenseGrid";
import { LicenseGridToolbar } from "./LicenseGridToolbar";
import LicenseAssignmentModal from "./LicenseAssignmentModal";
import { actionGenerators as notificationsActionGenerators } from "features/EntryPoint/containers/Notifications/state";
import { selectAccountPlans } from "features/EntryPoint/containers/App/selectors";

import breakpoints from "utils/styles/breakpoints";
import withRecord from "higherOrderComponents/withRecord";

function LicenseDelegationTable({
  accountPlans = [],
  addNotification,
  updateAccountPlanRequest,
}: LicenseDelegationTableProps) {
  const {
    accountCollection,
    filteredRows,
    isChecked,
    isFilterApplied,
    loading,
    members,
    rows,
    searchTerm,
    totalItems,
    view,
    fetchNextPage,
    formatRows,
    getSearchedRows,
    handleCheck,
    setFilteredRows,
    setNextPage,
    setRows,
    setSearchTerm,
  } = useLicenseGrid(accountPlans);

  const {
    assignLicenses,
    campaignsPro,
    keywords,
    sequences,
    openAssignmentModal,
    selectedAccounts,
    getAccount,
    setSelectedAccounts,
    toggleAssignmentModal,
  } = useAccountPlans({
    accountPlans,
    addNotification,
    updateAccountPlanRequest,
    accountListMembers: members ?? [],
  });

  const renderAllocatedLicenseChips = useMemo(() => {
    return (row: GridRenderCellParams) => {
      const chips = row.row.licenses.map((license: string) => {
        return (
          <Chip
            key={license}
            label={
              license === "campaigns" ? "Campaigns Pro" : capitalize(license)
            }
          />
        );
      });
      return chips.length > 0 ? chips : "-";
    };
  }, []);

  const columns: GridColDef[] = [
    {
      field: "accountName",
      headerName: "Account name",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "allocatedLicenses",
      headerName: "Allocated licenses",
      flex: 2,
      minWidth: 200,
      renderCell: renderAllocatedLicenseChips,
      sortable: false,
    },
    {
      field: "manage",
      headerName: "Manage",
      align: "center",
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      filterable: false,
      headerAlign: "center",
      sortable: false,
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <IconButton
            onClick={() => {
              setSelectedAccounts([params.row.id]);
              toggleAssignmentModal();
            }}
            data-testid="manage-license"
          >
            <EditIcon />
          </IconButton>
        );
      },
    },
  ];

  // initial render, plus resets when a search is cleared
  useEffect(() => {
    if (members.length === 0 || !totalItems || !view || !accountCollection) {
      return;
    }

    const initialRows = formatRows(accountCollection);

    if (!searchTerm) {
      setRows(initialRows ?? []);
      setNextPage(view?.next ?? null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accountCollection,
    accountPlans,
    members,
    searchTerm,
    setNextPage,
    setRows,
    totalItems,
    view,
  ]);

  // applies Filter Licenses checkbox filters
  useEffect(() => {
    const checkedFilters = Object.entries(isChecked)
      .filter(([_, value]) => {
        return value === true;
      })
      .map(([key]) => {
        return key;
      });

    if (checkedFilters.length > 0) {
      const updatedRows = rows.filter((row) => {
        return row.licenses.length > 0
          ? row.licenses.some((license: string) => {
              return checkedFilters.includes(license);
            })
          : checkedFilters.includes("none");
      });
      setFilteredRows(updatedRows);
    }
  }, [isChecked, rows, setFilteredRows]);

  // TODO (JOHN BROWN) IMPLEMENT ENABLE SEQUENCES
  // const enableSequencesCheckbox = useMemo(() => {
  //   const { assignments, quantity } = sequences;
  //   return assignments?.length < quantity;
  // }, [sequences]);
  const enableSequencesCheckbox = false;

  return (
    <>
      <Box mt={4}>
        <Paper variant="outlined">
          <Box aria-label="license-delegation-table" width="100%" height={500}>
            <DataGridPro
              columns={columns}
              rows={isFilterApplied ? filteredRows : rows}
              checkboxSelection
              columnBuffer={2}
              components={{ Toolbar: LicenseGridToolbar }}
              componentsProps={{
                baseCheckbox: {
                  color: "secondary",
                },
                toolbar: {
                  getSearchedRows,
                  handleCheck,
                  isChecked,
                  multipleAccountsSelected: selectedAccounts.length <= 1,
                  searchTerm,
                  setSearchTerm,
                  toggleModal: toggleAssignmentModal,
                },
              }}
              disableRowSelectionOnClick
              hideFooter
              loading={loading}
              localeText={{
                noRowsLabel: "No accounts",
              }}
              onRowsScrollEnd={fetchNextPage}
              onRowSelectionModelChange={(id) => {
                setSelectedAccounts(id);
              }}
              rowSelectionModel={selectedAccounts}
            />
          </Box>
        </Paper>
      </Box>

      <LicenseAssignmentModal
        campaignsPro={campaignsPro}
        keywords={keywords}
        sequences={sequences}
        assignLicenses={assignLicenses}
        openModal={openAssignmentModal}
        selectedAccounts={selectedAccounts}
        getAccount={getAccount}
        toggleModal={toggleAssignmentModal}
      />
    </>
  );
}

const mapStateToProps = (state: never, props: any) => {
  return {
    accountPlans: selectAccountPlans(state),
  };
};

const withConnect = connect(
  () => {
    return mapStateToProps;
  },
  {
    ...notificationsActionGenerators,
  },
);

export default compose(
  withRecord({
    actions: [
      {
        type: "update",
        optimisticUpdateFunc,
      },
    ],
    container:
      "features/AccountSettings/components/AccountConfiguration/LicenseDelegationTable",
    multiple: true,
    type: "accountPlan",
  }),
  withSizes(({ width }: { width: number }) => {
    return {
      fullScreen: !!(width < breakpoints.medium),
    };
  }),
  withConnect,
)(LicenseDelegationTable);

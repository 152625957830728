import PropTypes from "prop-types";
import get from "lodash/get";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import ModalHeader from "components/ModalHeader";
import SnackbarCloseButton from "containers/SnackbarCloseButton";

function DiscardConfirmation({
  activeUser = {},
  addNotification,
  deleteUserRequest,
  fetchUserCollectionRequest,
  handleClose,
  userCollectionId,
}) {
  const successCallback = () => {
    fetchUserCollectionRequest(userCollectionId);
    addNotification({
      message: "The user was successfully removed",
      options: {
        action: (key) => {
          return <SnackbarCloseButton snackbarKey={key} />;
        },
      },
    });
    handleClose();
  };

  const errorCallback = () => {
    addNotification({
      message: "There was an error removing the user",
      options: {
        action: (key) => {
          return <SnackbarCloseButton snackbarKey={key} />;
        },
        variant: "error",
      },
    });
  };

  const handleDelete = () => {
    deleteUserRequest(activeUser.id, null, {
      successCallback,
      errorCallback,
    });
  };

  return (
    <Box aria-label="Delete User Confirmation">
      <ModalHeader closeModal={handleClose}>Remove User</ModalHeader>
      <Box padding="20px">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          height={{ xs: "auto", sm: "160px", md: "130px" }}
        >
          <Typography color="textPrimary" variant="body1">
            Removing a user does not deactivate the messaging account or effect
            billing.
          </Typography>
          <Typography color="textPrimary" variant="body1">
            To discard the account please do so from the accounts page.
          </Typography>
          <Typography color="textPrimary" variant="body1">
            <b>{`Are you sure you want to remove the user, ${get(activeUser, [
              "name",
            ])}?`}</b>
          </Typography>
        </Box>
        <Box paddingTop="20px" textAlign="center">
          <Button
            color="primary"
            onClick={handleClose}
            style={{ marginRight: "10px" }}
          >
            Cancel
          </Button>
          <Button
            aria-label="Confirm Delete"
            variant="contained"
            color="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

DiscardConfirmation.propTypes = {
  activeUser: PropTypes.object,
  addNotification: PropTypes.func.isRequired,
  deleteUserRequest: PropTypes.func.isRequired,
  fetchUserCollectionRequest: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  userCollectionId: PropTypes.string.isRequired,
};

export default DiscardConfirmation;

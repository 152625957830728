import { useState } from "react";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";

import SavedReplies from "features/SavedReplies";
import PageHeader from "components/Page/PageHeader";
import SettingsPageWrapper from "components/SettingsPageComponents/SettingsPageWrapper";

function SavedRepliesSettings({ account, toggleSidebar }) {
  const [activeSavedReply, setActiveSavedReply] = useState(null);

  return (
    <SettingsPageWrapper>
      <PageHeader title="Templates" toggleSidebar={toggleSidebar}>
        {activeSavedReply && (
          <Button
            variant="outlined"
            style={{ marginRight: "1rem" }}
            onClick={() => {
              return setActiveSavedReply(null);
            }}
          >
            Add New
          </Button>
        )}
      </PageHeader>
      <SavedReplies
        settings
        settingsAccount={account}
        showManager
        activeSavedReply={activeSavedReply}
        setActiveSavedReply={setActiveSavedReply}
      />
    </SettingsPageWrapper>
  );
}

SavedRepliesSettings.propTypes = {
  account: PropTypes.object.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};

export default SavedRepliesSettings;

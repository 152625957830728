import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { actionGenerators, saga } from "./state";
import { actionGenerators as notificationsActionGenerators } from "features/EntryPoint/containers/Notifications/state";
import { selectCurrentAccount } from "features/EntryPoint/containers/App/selectors";
import injectSaga from "utils/injectSaga";
import Root from "features/AccountSettings/containers/OptOutImport/components/Root";

class OptOutImport extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentAccount: selectCurrentAccount(state, props),
  };
};

const withConnect = connect(mapStateToProps, {
  ...actionGenerators,
  ...notificationsActionGenerators,
});

const withSaga = injectSaga({ key: "OptOutImportContainer", saga });

export default compose(withSaga, withConnect)(OptOutImport);

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { connect } from "react-redux";
import { compose } from "redux";
import ProfileRoot from "./components/Root";
import {
  selectCurrentUser,
  selectCurrentAccount,
  selectAppSettings,
} from "features/EntryPoint/containers/App/selectors";
import { actionGenerators as notificationsActionGenerators } from "features/EntryPoint/containers/Notifications/state";
import withRecord from "higherOrderComponents/withRecord";

function Profile({ fetchAccountCollectionRequest, ...rest }) {
  const [accountList, setAccountList] = useState([]);

  const accountCollectionId = queryString.stringifyUrl({
    url: "/accounts",
    query: {
      type: "organization",
    },
  });

  useEffect(() => {
    fetchAccountCollectionRequest(accountCollectionId, null, {
      successCallback: (response) => {
        setAccountList(response.members);
      },
    });
  }, [accountCollectionId]);

  return <ProfileRoot {...rest} accountList={accountList} />;
}

Profile.propTypes = {
  fetchAccountCollectionRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    appSettings: selectAppSettings(state, props),
    currentAccount: selectCurrentAccount(state, props),
    currentUser: selectCurrentUser(state, props),
  };
};

const withConnect = connect(mapStateToProps, notificationsActionGenerators);

export default compose(
  withRecord({
    actions: ["update"],
    container: "features/Profile",
    type: "user",
  }),
  withRecord({
    actions: ["fetch"],
    container: "features/Profile/accountCollection",
    shape: { members: [] },
    noFetch: true,
    showLoader: () => {
      return false;
    },
    type: "accountCollection",
  }),
  withConnect,
)(Profile);

/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import * as React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import pluralize from "pluralize";

import DeleteIcon from "@mui/icons-material/Delete";

import {
  getColumns,
  getCollapsedColumns,
} from "features/Groups/constants/contactColumns";
import BatchActionToolbar from "components/BatchActionToolbar";
import BlankState from "components/BlankState";
import Logo from "components/Logo";
import SnackbarCloseButton from "containers/SnackbarCloseButton";
import Table from "components/Table";
import withRecord from "higherOrderComponents/withRecord";

import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";

function GroupPageContent({
  contactCollection,
  deleteContactCollectionRequest,
  addNotification,
  setIsSendDisabled,
  selected,
  selectedRecords,
  setSelected,
  setSelectedRecords,
}) {
  const { totalItems } = contactCollection;

  const [deleteConfirmationVisibility, setDeleteConfirmationVisibility] =
    useState(false);

  const handleSelected = (values) => {
    setSelected(values);
  };

  useEffect(() => {
    selected.length === 0 ||
    selected.length === contactCollection.members?.length
      ? setIsSendDisabled(false)
      : setIsSendDisabled(true);
  }, [selected, contactCollection]);

  const toggleDeleteConfirmationVisibility = () => {
    setDeleteConfirmationVisibility(!deleteConfirmationVisibility);
  };

  const batchActions = [
    {
      title: "Remove Contacts",
      icon: (
        <DeleteIcon
          aria-label="Remove Group Contacts"
          data-testid="remove-group-contacts"
        />
      ),
      clickHandler: toggleDeleteConfirmationVisibility,
      showExpanded: true,
    },
  ];

  const deleteContacts = () => {
    deleteContactCollectionRequest(
      contactCollection.id,
      { contacts: selected },
      {
        successCallback: () => {
          addNotification({
            message: `You successfully removed the ${pluralize(
              "contact",
              selected.length,
              true,
            )} from the group.`,
            options: {
              action: (key) => {
                return <SnackbarCloseButton snackbarKey={key} />;
              },
            },
          });
          setSelected([]);
        },
        errorCallback: () => {
          addNotification({
            message: `We were unable to remove the ${pluralize(
              "contact",
              selected.length,
              true,
            )} from the group.`,
            options: {
              action: (key) => {
                return <SnackbarCloseButton snackbarKey={key} />;
              },
              variant: "error",
            },
          });
        },
      },
    );
  };

  const handleDelete = () => {
    deleteContacts();
    toggleDeleteConfirmationVisibility();
  };

  return (
    <Box
      aria-label="Group Page Content"
      display="flex"
      flexDirection="column"
      flexGrow={1}
      flexShrink={1}
      overflow="auto"
      position="relative"
    >
      <ConfirmationModal
        cancellationHandler={toggleDeleteConfirmationVisibility}
        confirmationHandler={handleDelete}
        isOpen={deleteConfirmationVisibility}
        message={`You are about to remove ${pluralize(
          "contact",
          selected.length,
          true,
        )} from this group.`}
        title={`Remove ${pluralize("contact", selected.length)} from group?`}
      />
      {selected.length > 0 && (
        <BatchActionToolbar
          actions={batchActions}
          position={{ top: "0px", left: "64px" }}
          selectedCount={selected.length}
          setSelectedRecords={setSelectedRecords}
          selectAllCount={totalItems}
        />
      )}
      <Table
        blankState={
          <BlankState
            image={<Logo color="disabled" />}
            title="This group is empty"
            subTitle="Click on the button below to add some contacts"
          />
        }
        collection={contactCollection}
        collapsedColumns={getCollapsedColumns()}
        columns={getColumns()}
        notExpandable
        selected={selected}
        selectedRecords={selectedRecords}
        setSelected={handleSelected}
        setSelectedRecords={setSelectedRecords}
        withBatchActions
      />
    </Box>
  );
}

GroupPageContent.propTypes = {
  contactCollection: PropTypes.object.isRequired,
  deleteContactCollectionRequest: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  setIsSendDisabled: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  selectedRecords: PropTypes.array,
  setSelected: PropTypes.func.isRequired,
  setSelectedRecords: PropTypes.func,
};

export default withRecord({
  actions: ["fetch", "delete"],
  container: "features/Groups/pages/group/ContactCollection",
  shape: { members: [{ phones: { members: [] } }] },
  showLoader: () => {
    return false;
  },
  type: "contactCollection",
})(GroupPageContent);

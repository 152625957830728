import { Box, Card, Typography, useTheme } from "@mui/material";
import { SnackbarContent, SnackbarKey, SnackbarMessage } from "notistack";
import { forwardRef } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export interface SnackbarProps {
  id: SnackbarKey;
  message: SnackbarMessage;
  variant: "info" | "success" | "error";
  actionButton?: JSX.Element;
  width?: string;
}

export const Snackbar = forwardRef<HTMLDivElement, SnackbarProps>(
  (props, ref) => {
    const { message, variant, actionButton, width } = props;
    const theme = useTheme();
    const bgColor = {
      info: theme.palette.blueGrey[600],
      success: theme.palette.success.main,
      error: theme.palette.error.main,
    };

    return (
      <SnackbarContent ref={ref}>
        <Card
          style={{
            alignItems: "center",
            backgroundColor: bgColor[variant],
            borderRadius: "4px",
            display: "flex",
            flex: "1 1 auto",
            width: width || "328px",
            padding: "14px 16px",
          }}
        >
          {variant === "success" && (
            <CheckCircleOutlineIcon
              sx={(theme) => {
                return {
                  color: theme.palette.primary.contrastText,
                  marginRight: "12px",
                };
              }}
            />
          )}

          {variant === "error" && (
            <ErrorOutlineIcon
              sx={(theme) => {
                return {
                  color: theme.palette.primary.contrastText,
                  marginRight: "12px",
                };
              }}
            />
          )}

          {variant === "info" && (
            <InfoOutlinedIcon
              sx={(theme) => {
                return {
                  color: theme.palette.primary.contrastText,
                  marginRight: "12px",
                };
              }}
            />
          )}

          <Typography fontSize={14} color="#fff" flex="1 1 auto">
            {message}
          </Typography>

          <Box marginLeft="8px">{actionButton || null}</Box>
        </Card>
      </SnackbarContent>
    );
  },
);

Snackbar.displayName = "Snackbar";

export const createSnackbarContent = (
  variant: "error" | "success" | "info",
  width?: string,
) => {
  return function content(id: SnackbarKey, message: SnackbarMessage) {
    return (
      <Snackbar
        id={id}
        message={message}
        variant={variant}
        width={width || "auto"}
      />
    );
  };
};

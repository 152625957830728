import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Box from "@mui/material/Box";
import CachedIcon from "@mui/icons-material/Cached";
import CircularProgress from "@mui/material/CircularProgress";
import EventIcon from "@mui/icons-material/Event";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";

import { compose } from "redux";
import { connect } from "react-redux";
import { formatDistanceToNow, isWithinInterval, subHours } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import DeliveryStatus from "./DeliveryStatus";
import MessageAttachment from "./MessageAttachment";
import MessageBubble from "./MessageBubble";
import withRecord from "higherOrderComponents/withRecord";
import { actionGenerators as notificationsActionGenerators } from "features/EntryPoint/containers/Notifications/state";

import SnackbarCloseButton from "containers/SnackbarCloseButton";
import SequencesIcon from "icons/SequencesIcon";

const Root = styled.div`
  display: flex;
  flex-flow: column nowrap;
  max-width: 75%;
`;

const LoadingWrapper = styled.div`
  display: flex;
  padding: 16px;
  position: relative;
`;

export const MessageDetails = styled.div`
  align-items: center;
  color: ${(props) => {
    return props.theme.colors.text.primary;
  }};
  display: flex;
  flex-flow: row nowrap;
  font-size: ${(props) => {
    return props.theme.fonts.primaryFontSize;
  }};
  justify-content: ${(props) => {
    return props.direction === "in" ? "flex-start" : "flex-end";
  }};
  padding: 5px 25px;
`;

const StyledBoltIcon = styled(BoltOutlinedIcon)`
  font-size: medium;
  color: rgba(0, 0, 0, 0.54);
`;

const Timestamp = styled.span``;
const FAILED_DELIVERY_STATES = new Set(["failed_transient", "unknown"]);

class MessageWrapper extends Component {
  static propTypes = {
    addNotification: PropTypes.func,
    appSettings: PropTypes.object.isRequired,
    deleteMessageRequest: PropTypes.func,
    message: PropTypes.object.isRequired,
    isLastMessage: PropTypes.bool,
    retryMessageRequest: PropTypes.func,
    shouldCollapse: PropTypes.bool,
    showModal: PropTypes.func,
    timeZone: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
    };
  }

  handleDeleteAttachment = async () => {
    // don't need to turn off showLoading because component no longer exists after this function
    this.setState({ showLoading: true });
    const { addNotification, deleteMessageRequest, message } = this.props;
    const successCallback = () => {
      addNotification({
        message: "You successfully deleted an attachment.",
        options: {
          action: (key) => {
            return <SnackbarCloseButton snackbarKey={key} />;
          },
        },
      });
    };
    const errorCallback = () => {
      addNotification({
        message: "Something went wrong. Please try again.",
        options: {
          action: (key) => {
            return <SnackbarCloseButton snackbarKey={key} />;
          },
        },
        variant: "error",
      });
    };
    await deleteMessageRequest(message.id, null, {
      successCallback,
      errorCallback,
    });
  };

  formatTimestamp(timestamp) {
    const now = new Date();
    const oneHourAgo = subHours(now, 1);
    if (isWithinInterval(timestamp, { start: oneHourAgo, end: now })) {
      clearTimeout(this.forceUpdateTimeout);
      this.forceUpdateTimeout = setTimeout(() => {
        this.forceUpdate();
      }, 1000 * 60);
      return formatDistanceToNow(timestamp, { addSuffix: true });
    }
    return formatInTimeZone(timestamp, this.props.timeZone, "h:mm a z");
  }

  render() {
    const {
      appSettings,
      message,
      shouldCollapse,
      isLastMessage,
      retryMessageRequest,
      showModal,
    } = this.props;

    const { showLoading } = this.state;

    const spinnerJustification =
      message.direction === "out" ? "flex-end" : "flex-start";

    return (
      <Root>
        {showLoading && (
          <LoadingWrapper style={{ justifyContent: spinnerJustification }}>
            <CircularProgress />
          </LoadingWrapper>
        )}
        {message.attachments.members &&
          !showLoading &&
          message.attachments.members.map((attachment) => {
            const isImage = attachment.contentType.startsWith("image");
            return (
              <MessageAttachment
                appSettings={appSettings}
                attachment={attachment}
                className="MessageAttachment"
                isImage={isImage}
                handleDeleteAttachment={this.handleDeleteAttachment}
                key={attachment.key}
                noMessageBody={!message.body}
                showModal={showModal}
              />
            );
          })}
        {message.body && (
          <MessageBubble
            message={message}
            handleDelete={this.handleDeleteMessage}
            body={message.body}
            shouldCollapse={shouldCollapse}
          />
        )}
        <Box position="absolute" right="25px">
          {retryMessageRequest &&
            FAILED_DELIVERY_STATES.has(message.deliveryState) &&
            (message.errorCreating || message.retryAllowed) && (
              <Tooltip title="Resend" aria-label="Resend Message">
                <IconButton
                  color="inherit"
                  onClick={() => {
                    return retryMessageRequest(message.retry);
                  }}
                  size="large"
                >
                  <CachedIcon />
                </IconButton>
              </Tooltip>
            )}
        </Box>
        {!shouldCollapse && (
          <MessageDetails direction={message.direction}>
            {message.direction === "out" && (
              <DeliveryStatus isLastMessage={isLastMessage} message={message} />
            )}
            {message.flags && message.flags.includes("scheduled") && (
              <Box mr={1} color="text.secondary">
                <Tooltip
                  aria-label="This message was scheduled"
                  title="This message was scheduled"
                >
                  <EventIcon />
                </Tooltip>
              </Box>
            )}
            {message.source === "hubspot" && message.direction === "out" && (
              <>
                <StyledBoltIcon /> HubSpot |&nbsp;
              </>
            )}
            {message.source === "sequences" && (
              <>
                <SequencesIcon
                  style={{
                    fill: "rgba(0, 0, 0, 0.54)",
                    fontSize: 18,
                    marginRight: "2px",
                  }}
                />{" "}
                Sequence |&nbsp;
              </>
            )}
            {!["requested", "created", "queued"].includes(
              message.deliveryState,
            ) && message.timelinePosition ? (
              <Timestamp>
                {this.formatTimestamp(new Date(message.timelinePosition))}
              </Timestamp>
            ) : null}
          </MessageDetails>
        )}
      </Root>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const withConnect = connect(
  () => {
    return mapStateToProps;
  },
  {
    ...notificationsActionGenerators,
  },
);

export default compose(
  withRecord({
    actions: ["delete"],
    container: "/components/ConversationThread/MessageWrapper",
    type: "message",
  }),
  withConnect,
)(MessageWrapper);

import { compose } from "redux";
import { connect } from "react-redux";

import get from "lodash/get";
import isEqual from "lodash/isEqual";
import { Formik, Field, Form } from "formik";
import { withRouter } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import withSizes from "react-sizes";

import { NewGroupValidationSchema } from "formHelpers/validationSchemas";
import breakpoints from "utils/styles/breakpoints";
import Modal from "components/Modal";
import ModalContent from "components/ModalContent";
import ModalHeader from "components/ModalHeader";
import { selectCurrentAccount } from "features/EntryPoint/containers/App/selectors";
import withRecord from "higherOrderComponents/withRecord";

function CreateGroupModal({
  closeModal,
  contacts,
  createGroupRequest,
  currentAccount,
  fullScreen,
  history,
  visible,
}) {
  const handleCreateGroup = (values, actions) => {
    const sendValues = { ...values, contacts };
    createGroupRequest(`/${currentAccount.slug}/groups`, sendValues, {
      successCallback: (group) => {
        actions.setSubmitting(false);
        closeModal();
        history.push({
          pathname: `/${currentAccount.slug}/groups/mine/${group.slug}`,
        });
      },
      errorCallback: (errors) => {
        const validationErrors = get(errors, ["validationErrors"], {
          name: "Something went wrong!",
        });
        actions.setSubmitting(false);
        actions.setErrors(validationErrors);
      },
    });
  };
  const initialValues = { name: "" };
  return (
    <Modal
      fullScreen={fullScreen}
      transitionIn={visible}
      ariaLabel="Create Group Modal"
      data-testid="create-group-modal"
      closeModal={closeModal}
    >
      <ModalHeader
        aria-label="Create Group"
        data-testid="create-group-modal-header"
        closeModal={closeModal}
      >
        Create a Group
      </ModalHeader>
      <ModalContent maxWidth="100%">
        <Box minWidth="360px">
          <Formik
            initialValues={initialValues}
            validationSchema={NewGroupValidationSchema}
            validateOnMount={NewGroupValidationSchema.isValid(initialValues)}
            onSubmit={handleCreateGroup}
          >
            {({ errors, touched, isSubmitting, isValid, values: { name } }) => {
              return (
                <Form>
                  <Field type="text" name="name">
                    {({ field }) => {
                      return (
                        <TextField
                          {...field}
                          autoFocus
                          fullWidth
                          error={touched.name && Boolean(errors.name)}
                          helperText={touched.name ? errors.name || " " : " "}
                          inputProps={{ "data-lpignore": true }}
                          label="Group Name"
                          aria-label="Type Group Name"
                          id="group-name"
                          data-testid="group-name"
                          placeholder="Enter a name for your group."
                          type="text"
                          variant="outlined"
                        />
                      );
                    }}
                  </Field>
                  <Box textAlign="right" mt={1}>
                    <Button
                      aria-label="Create Group"
                      data-testid="create-group-button"
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={
                        !isValid ||
                        isSubmitting ||
                        isEqual(name, initialValues.name)
                      }
                    >
                      Create Group
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </ModalContent>
    </Modal>
  );
}

CreateGroupModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  contacts: PropTypes.any,
  createGroupRequest: PropTypes.func.isRequired,
  currentAccount: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    currentAccount: selectCurrentAccount(state, props),
  };
};

const withConnect = connect(mapStateToProps);

export default compose(
  withRouter,
  withConnect,
  withRecord({
    actions: ["create"],
    container: "features/Groups/CreateGroupModal/group",
    type: "group",
  }),
  withSizes(({ width }) => {
    return { fullScreen: width < breakpoints.medium };
  }),
)(CreateGroupModal);

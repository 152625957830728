/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { IntegrationCard } from "../IntegrationCard";
import { getConnectedIntegrations } from "../../api/getConnectedIntegrations";
import { updateIntegration } from "../../api/updateIntegration";
import { ConnectedIntegrationsProps } from "./types";
import { IntegrationProvider } from "models/IntegrationProvider";
import { createSnackbarContent } from "components/Snackbar";

export function ConnectedIntegrations({
  allIntegrations,
  connectedIntegrations,
  currentAccount,
  selectedIntegration,
  setConnectedIntegrations,
  setSelectedIntegration,
}: ConnectedIntegrationsProps) {
  const { enqueueSnackbar } = useSnackbar();

  const appendFormattedNameAndStrategy = (data: IntegrationProvider[]) => {
    const integrationsToAppend = data;
    integrationsToAppend.forEach((integration) => {
      const index = allIntegrations.findIndex((allIntegration) => {
        return allIntegration.provider === integration.provider;
      });
      if (index !== -1) {
        integration.providerName = allIntegrations[index].providerName;
        integration.strategy = allIntegrations[index].strategy;
      }
    });
    setConnectedIntegrations(integrationsToAppend);
  };

  const handleGetConnectedIntegrations = async () => {
    try {
      const response = await getConnectedIntegrations(currentAccount.slug);
      if (!response.ok) {
        throw new Error("Failed to get connected integrations.");
      }
      const data = await response.json();
      if (data.members && data.members.length > 0) {
        appendFormattedNameAndStrategy(data.members);
      }
    } catch {
      enqueueSnackbar("Failed to get connected integrations.", {
        content: createSnackbarContent("error"),
      });
    }
  };

  const handleToggleIntegration = async (integration: IntegrationProvider) => {
    try {
      const response = await updateIntegration(integration?.id ?? "", {
        active: !integration.active,
      });
      if (!response.ok) {
        throw new Error("Integration update failed.");
      }
      enqueueSnackbar(
        integration.active
          ? "Integration activity paused."
          : "Integration activity resumed.",
        {
          content: createSnackbarContent("info"),
        },
      );
      handleGetConnectedIntegrations();
    } catch {
      enqueueSnackbar("Integration update failed.", {
        content: createSnackbarContent("error"),
      });
    }
  };

  useEffect(() => {
    if (!selectedIntegration && allIntegrations.length > 0) {
      handleGetConnectedIntegrations();
    }
  }, [allIntegrations, selectedIntegration]);

  return (
    <Box>
      <Typography variant="h4">Connected</Typography>
      {connectedIntegrations.length > 0 ? (
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: "24px",
            marginBottom: "32px",
          }}
        >
          {connectedIntegrations.map((integration) => {
            return (
              <IntegrationCard
                handleToggleIntegration={handleToggleIntegration}
                integration={integration}
                key={integration.provider}
                setSelectedIntegration={setSelectedIntegration}
              />
            );
          })}
        </Grid>
      ) : (
        <Typography
          variant="body2"
          sx={{
            color: "text.secondary",
            marginTop: "32px",
            marginBottom: "32px",
            textAlign: "center",
          }}
        >
          No integrations currently connected.
        </Typography>
      )}
    </Box>
  );
}

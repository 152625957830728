import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Tab, Tabs } from "@mui/material";
import { useSnackbar } from "notistack";
import { useQuery } from "@tanstack/react-query";
import { fetchSavedReplyCollection } from "../api";
import SavedReplyCollection from "features/SavedReplies/containers/SavedReplyCollection";
import { SearchBox } from "components/SearchBox";
import { useCurrentUser, useDebouncedSearch } from "hooks";
import { createSnackbarContent } from "components/Snackbar";

const SAVED_REPLY_FILTERS = Object.freeze({
  mine: "mine",
  all: "all",
});
function SavedReplyList({
  activeSavedReply,
  hasManageOptions,
  savedReplies,
  savedReplyCollectionId,
  savedReplySelectionHandler,
  setActiveSavedReply,
  setSavedReplies,
  settings,
  templatesMaintenance,
}) {
  const { mine, all } = SAVED_REPLY_FILTERS;

  const [selectedTab, setSelectedTab] = useState(0);

  const { savedReplies: slug } = useCurrentUser();

  const { enqueueSnackbar } = useSnackbar();

  const { searchTerm, debouncedSearchTerm, handleClear, handleSearch } =
    useDebouncedSearch();

  const filter = selectedTab === 0 ? mine : all;

  const getSavedReplies = async () => {
    const response = await fetchSavedReplyCollection(
      slug,
      filter,
      debouncedSearchTerm,
    );
    return response.json();
  };

  const { data: allSavedReplies, isLoading } = useQuery({
    queryKey: ["savedReplies", filter, debouncedSearchTerm],
    queryFn: getSavedReplies,
    throwOnError: (error) => {
      enqueueSnackbar("Something went wrong, please try again.", {
        content: createSnackbarContent("error"),
      });
      console.error(error);
      return false;
    },
  });

  useEffect(() => {
    setSavedReplies(allSavedReplies);
    if (!settings && templatesMaintenance) {
      setActiveSavedReply(allSavedReplies?.members[0]);
    }
  }, [
    allSavedReplies,
    setActiveSavedReply,
    settings,
    templatesMaintenance,
    setSavedReplies,
  ]);

  const handleTabChange = (event, newTab) => {
    setSelectedTab(newTab);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      overflow="scroll"
    >
      <Box
        alignItems="flex-start"
        justifyContent="flex-start"
        display="flex"
        flexDirection="column"
        sx={{
          borderBottom: (theme) => {
            return `1px solid ${theme.palette.divider}`;
          },
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          sx={(theme) => {
            return {
              width: "100%",
              borderBottom: `1px solid ${theme.palette.divider}`,
            };
          }}
        >
          <Tab
            label={mine}
            data-testid={`saved-reply-${mine}-tab`}
            sx={{ width: "50%" }}
          />
          <Tab
            label={all}
            data-testid={`saved-reply-${all}-tab`}
            sx={{ width: "50%" }}
          />
        </Tabs>
        <SearchBox
          value={searchTerm}
          onClear={handleClear}
          onChange={handleSearch}
          placeholder="Search templates"
          sx={{ padding: "0.5rem 1rem" }}
        />
      </Box>
      <SavedReplyCollection
        activeSavedReply={activeSavedReply}
        hasManageOptions={hasManageOptions}
        savedReplies={savedReplies}
        savedReplyCollectionId={savedReplyCollectionId}
        savedReplySelectionHandler={savedReplySelectionHandler}
        searchTerm={debouncedSearchTerm}
        setActiveSavedReply={setActiveSavedReply}
        setSavedReplies={setSavedReplies}
      />
    </Box>
  );
}

export default SavedReplyList;

SavedReplyList.propTypes = {
  activeSavedReply: PropTypes.object, // passed
  hasManageOptions: PropTypes.bool, // passed
  savedReplies: PropTypes.object.isRequired, // passed
  savedReplySelectionHandler: PropTypes.func.isRequired, // passed
  savedReplyCollectionId: PropTypes.string.isRequired, // passed
  setActiveSavedReply: PropTypes.func.isRequired, // passed
  setSavedReplies: PropTypes.func.isRequired, // passed
  settings: PropTypes.bool, // passed
  templatesMaintenance: PropTypes.bool, // passed
};

import { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";

import { Box } from "@mui/material";
import { TextUsTable } from "components/TextUsTable";

export default class Root extends Component {
  static propTypes = {
    contactCollection: PropTypes.object.isRequired,
  };

  columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      valueGetter({ row }) {
        return row.name;
      },
    },
    {
      field: "primaryNumber",
      headerName: "Primary Number",
      flex: 1,
      valueGetter({ row }) {
        return get(row, ["phones", "members", 0, "formattedPhoneNumber"]);
      },
    },
    {
      field: "businessName",
      headerName: "Business Name",
      flex: 1,
      valueGetter({ row }) {
        return row?.data?.business || "";
      },
    },
  ];

  render() {
    const { contactCollection } = this.props;

    return (
      <Box border="1px solid" borderColor="divider">
        <TextUsTable
          checkboxSelection={false}
          className="new-campaign-contact-collection-table"
          columns={this.columns}
          collection={contactCollection}
          condenseRows
          hideColumnsOnMobile={["primaryNumber", "businessName"]}
        />
      </Box>
    );
  }
}

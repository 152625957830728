/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Switch, Redirect, Route, NavLink } from "react-router-dom";
import { styled } from "@mui/material";

import { compose } from "redux";
import KeywordAutomation from "./screens/KeywordAutomation";
import KeywordGroup from "./screens/KeywordGroup";
import KeywordGroups from "./screens/KeywordGroups";
import { AutomationProps } from "./models/AutomationModels";
import { useAutomationNavLinks } from "./hooks/useAutomationNavLinks";
import useKeywordGroups from "./hooks/useKeywordGroups";
import { useKeywordLinking } from "features/Sequences/hooks/useKeywordLinking";
import { SequencesNavValues } from "features/Sequences/hooks/useSequences/types";
import PageSidebar from "components/Page/PageSidebar";
import PageSidebarNavLink from "components/Page/PageSidebarNavLink";
import PageWrapper from "components/Page/PageWrapper";

import {
  AUTOMATION_PATHS,
  SEQUENCES_NAV,
} from "features/Sequences/screens/SequencesOverview/constants";
import { IndividualSequenceOverview } from "features/Sequences/screens/IndividualSequenceOverview";
import { SecondaryLinks } from "features/Sequences/components/SecondaryLinks";
import { Sequences } from "features/Sequences/screens/SequencesOverview";
import withSidebar from "higherOrderComponents/withSidebar";
import type { KeywordResponse } from "models/Keyword";

const SequencesSidebarNavLink = styled(NavLink)(({ theme }) => {
  return {
    alignItems: "center",
    color: theme.palette.text.primary,
    fontSize: "13px",
    display: "flex",
    fontWeight: 600,
    justifyContent: "space-between",
    textDecoration: "none",
    padding: "0.75rem 1.25rem",

    ":hover": {
      background: theme.palette.action.hover,
    },
  };
});

function Automation({
  currentAccount,
  isSinglePanel,
  location,
  showSidebar,
  toggleSidebar,
}: AutomationProps): JSX.Element {
  const { automation, keywordsMain, keywordGroups, sequences } =
    AUTOMATION_PATHS;
  const { mine } = SEQUENCES_NAV;

  const [selectedNavLink, setSelectedNavLink] =
    useState<SequencesNavValues>(mine);

  const [keywords, setKeywords] = useState<KeywordResponse | null>(null);
  const { slug } = currentAccount ?? {};
  const { initialPage, keywordsEnabled, sequencesEnabled } =
    useAutomationNavLinks();

  const {
    confirmUnlinkRef,
    links,
    linkKeyword,
    setLinks,
    toggleUnlinkDialog,
    unlinkKeyword,
  } = useKeywordLinking();

  const { rowsPerPage, loading, page, getRow, handlePageChange } =
    useKeywordGroups(keywords, setKeywords);

  useEffect(() => {
    const initialLinks = keywords?.items
      ?.filter((keyword) => {
        // filters for only Keywords with a linked Sequence
        return keyword.sequences.length > 0;
      })
      .map((link) => {
        return {
          sequence: {
            id: link.sequences[0].id,
            name: link.sequences[0].name,
          },
          keyword: {
            id: link.id,
            keyword: link.keyword,
          },
        };
      });
    setLinks(initialLinks ?? []);
  }, [keywords]);

  const url = `/${slug}/${automation}`;

  const sequencesSelected = location.pathname.includes(sequences);

  return (
    <PageWrapper>
      {/* ==== SIDEBAR NAV LINKS ==== */}
      <PageSidebar>
        {keywordsEnabled ? (
          <>
            <PageSidebarNavLink to={`${url}/${keywordsMain}`}>
              Keywords
            </PageSidebarNavLink>
            <PageSidebarNavLink to={`${url}/${keywordGroups}`}>
              Keywords Subscribers
            </PageSidebarNavLink>
          </>
        ) : null}

        {sequencesEnabled ? (
          <SequencesSidebarNavLink to={`${url}/${sequences}`}>
            Sequences
          </SequencesSidebarNavLink>
        ) : null}

        {sequencesSelected && sequencesEnabled ? (
          <SecondaryLinks
            selectedNavLink={selectedNavLink}
            setSelectedNavLink={setSelectedNavLink}
          />
        ) : null}
      </PageSidebar>

      {/* ==== ROUTES ==== */}
      {/* Wait for initial page to be set. */}
      {initialPage ? (
        <Switch location={location.path}>
          {/* /automation */}
          <Route
            exact
            path={url}
            render={() => {
              return <Redirect to={`${url}/${initialPage}`} />;
            }}
          />

          {/* /automation/keywords_groups */}
          <Route
            exact
            path={`${url}/${keywordGroups}`}
            render={() => {
              // User tried to navigate directly to this page without proper license.
              if (!keywordsEnabled) {
                return <Redirect to={`${url}/${initialPage}`} />;
              }

              return (
                <KeywordGroups
                  isSinglePanel={isSinglePanel}
                  keywords={keywords}
                  loading={loading}
                  links={links}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  getRow={getRow}
                  handlePageChange={handlePageChange}
                  setLinks={setLinks}
                  showSidebar={showSidebar}
                  toggleSidebar={toggleSidebar}
                />
              );
            }}
          />

          {/* /automation/keywords */}
          <Route
            exact
            path={`${url}/${keywordsMain}`}
            render={() => {
              // User tried to navigate directly to this page without proper license.
              if (!keywordsEnabled) {
                return <Redirect to={`${url}/${initialPage}`} />;
              }

              return (
                <KeywordAutomation
                  currentAccount={currentAccount}
                  isSinglePanel={isSinglePanel}
                  keywords={keywords}
                  showSidebar={showSidebar}
                  setKeywords={setKeywords}
                  toggleSidebar={toggleSidebar}
                />
              );
            }}
          />

          {/* /automation/keywords_groups/:id */}
          <Route
            exact
            path={`${url}/${keywordGroups}/:keywordGroupId`}
            render={({ match }) => {
              const { keywordGroupId } = match?.params ?? {};
              const contactCollectionId = `/${currentAccount.slug}/contact_filters/keyword:${keywordGroupId}/contacts`;
              return (
                <KeywordGroup
                  confirmUnlinkRef={confirmUnlinkRef}
                  contactCollectionId={contactCollectionId}
                  isSinglePanel={isSinglePanel}
                  keywordGroupId={keywordGroupId}
                  links={links}
                  linkKeyword={linkKeyword}
                  showSidebar={showSidebar}
                  toggleSidebar={toggleSidebar}
                  toggleUnlinkDialog={toggleUnlinkDialog}
                />
              );
            }}
          />

          {/* /automation/sequences */}
          <Route
            exact
            path={`${url}/${sequences}`}
            render={() => {
              return (
                <Sequences
                  currentAccount={currentAccount}
                  isSinglePanel={isSinglePanel}
                  returnPath={`${url}/${sequences}`}
                  selectedNavLink={selectedNavLink}
                  setSelectedNavLink={setSelectedNavLink}
                  showSidebar={showSidebar}
                  toggleSidebar={toggleSidebar}
                />
              );
            }}
          />

          {/* /automation/sequences/:id */}
          <Route
            exact
            path={`${url}/${sequences}/:sequenceId`}
            render={({ match }) => {
              const { sequenceId } = match?.params ?? {};
              return (
                <IndividualSequenceOverview
                  currentAccount={currentAccount}
                  isSinglePanel={isSinglePanel}
                  returnPath={`${url}/${sequences}`}
                  sequenceId={sequenceId}
                  showSidebar={showSidebar}
                  toggleSidebar={toggleSidebar}
                  unlinkKeyword={unlinkKeyword}
                />
              );
            }}
          />
        </Switch>
      ) : null}
    </PageWrapper>
  );
}

export default compose(withSidebar)(Automation);

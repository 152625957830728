import { Component, PropsWithChildren } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import {
  ThemeProvider,
  StyledEngineProvider,
  ThemeOptions,
} from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { LicenseInfo } from "@mui/x-license-pro";
import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components";
import { createTheme } from "@tesseract/theme";
import type { AppColors } from "@tesseract/theme";
import { selectAppColors } from "features/EntryPoint//containers/App/selectors";
import saga from "features/EntryPoint/containers/App/saga";
import DragListener from "features/EntryPoint/containers/DragListener";
import LogoGradient from "components/LogoGradient";
import Notifications from "features/EntryPoint/containers/Notifications";
import TooltipContainer from "features/EntryPoint/containers/TooltipContainer";
import getTheme from "utils/theme";
import injectSaga from "utils/injectSaga";

type Props = PropsWithChildren<{
  themeOverrides?: ThemeOptions;
}>;

type ComposedProps = {
  appColors: AppColors;
};

class Root extends Component<Props & ComposedProps> {
  static propTypes = {
    appColors: PropTypes.object.isRequired,
    themeOverrides: PropTypes.object,
    children: PropTypes.node.isRequired,
  };

  render() {
    // for MUI X Pro, OK to expose https://mui.com/x/introduction/licensing/#security
    LicenseInfo.setLicenseKey(
      "71662f8285d3e34df8551d7cacc64bdfTz04MzAyOSxFPTE3Mzc5MTE1NTIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
    );

    const { appColors } = this.props;
    const muiTheme = createTheme(appColors, this.props.themeOverrides);
    const appTheme = getTheme({ appColors, muiTheme });
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <StyledComponentsThemeProvider theme={appTheme}>
            <DragListener>
              <div id="textus-ModalContainer" />
              <LogoGradient />
              <TooltipContainer />
              <SnackbarProvider
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                autoHideDuration={5000}
                // hideIconVariant
                data-testid="Snackbar"
                /* 
                  Below sx prop override is necessary for snackbars to have our primary navy 
                  blue (#283c59) as a background color. This is due to notistack v2.5 and 
                  MUI v5 not integrating cleanly. Notistack is working on a v3.0 to solve the 
                  issue, but it's still in alpha. We should upgrade to v3 when it's a stable 
                  release.
              
                  By targeting variantInfo, which we don't use, it preserves the styling
                  of other variants like error, which we do (specified as an option in 
                  addNotification calls).
                */
                sx={{
                  "& .SnackbarItem-variantSuccess": {
                    backgroundColor: "#2E7D32",
                  },
                  "& .SnackbarContent-root": {
                    minWidth: "auto !important",
                  },
                  "& .SnackbarItem-variantInfo": {
                    backgroundColor: "#283c59",
                  },
                }}
                variant="info"
              >
                <Notifications />
                {this.props.children}
              </SnackbarProvider>
            </DragListener>
          </StyledComponentsThemeProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

const mapStateToProps = (state: unknown) => {
  return {
    appColors: selectAppColors(state),
  };
};

const withConnect = connect(mapStateToProps, {});

const withSaga = injectSaga({ key: "EntryPoint/App", saga });

export const Themed = compose(
  withSaga,
  withConnect,
)(Root) as typeof Component<Props>;

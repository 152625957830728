import assert from "assert";
import { Grid, Button, Typography, Skeleton } from "@mui/material";
import { useCallback, useState } from "react";
import { Form, FormikHelpers, FormikProps } from "formik";
import { Account, identifyAccount } from "@tesseract/core";
import { useSnackbar } from "notistack";
import { patchSettings } from "../api";
import {
  AutomatedSender as AutomatedSenderSetting,
  FormValues,
  SettingsCard,
  SettingsCardActions,
  SettingsCardContent,
  SettingsCardHeader,
  SettingsCardDescription,
  SettingsCardAlert,
} from "features/Settings";
import PageHeader from "components/Page/PageHeader";
import SettingsPageWrapper from "components/SettingsPageComponents/SettingsPageWrapper";
import { createSnackbarContent } from "components/Snackbar";
import { produceAccountUpdate } from "features/Accounts";
import { useAccount } from "features/Accounts/hooks";
import { LockIcon } from "icons";

/**
 * Props for the AutomatedSender component.
 */
type AutomatedSenderProps = {
  toggleSidebar: () => boolean;
  account: Account.Raw;
  isAdmin: boolean;
};

/**
 * Renders the Automated Sender component.
 */
export function AutomatedSender({
  toggleSidebar,
  account: { id, settings: staticSettings },
  isAdmin,
}: AutomatedSenderProps) {
  /**
   * Snackbar component for displaying notifications.
   */
  const snackbar = useSnackbar();

  /**
   * Represents the user retrieved from the API based on the automated sender ID.
   * If the automated sender ID is not set, the system user ID is used instead.
   */
  const { account, state: accountState } = useAccount(id);

  /**
   * Is this setting active?
   */
  const [isActive, setIsActive] = useState(
    Boolean(staticSettings.automatedSenderId.value),
  );

  /**
   * Handles the form submission for updating account settings.
   *
   * @param formValues - The form values containing the updated settings.
   * @param {object} helpers - The form helpers.
   * @param helpers.setErrors - A function to set errors in the form.
   * @returns {Promise<void>} - A Promise that resolves when the submission is complete.
   */
  const handleSubmit = useCallback(
    async (
      formValues: FormValues,
      { setErrors }: FormikHelpers<FormValues>,
    ) => {
      // Update the account settings with the new automated sender ID.
      const response = await patchSettings(id, {
        automatedSenderId: {
          value:
            formValues.automatedSenderId?.disabled === true
              ? null
              : formValues.automatedSenderId?.value,
        },
      });

      // Display a notification based on the response status.
      snackbar.enqueueSnackbar(
        response.ok
          ? "Automated sender saved."
          : "Failed to saved automated sender.",
        {
          content: createSnackbarContent(response.ok ? "success" : "error"),
        },
      );

      // If the response status is 422, set the validation errors in the form.
      if (response.status === 422) {
        const { validationErrors } = await response.json();

        setErrors(validationErrors.settings);
      } else {
        produceAccountUpdate((await response.json()) as Account.Raw);
      }
    },
    [id, snackbar],
  );

  const handleToggleLock = useCallback(
    ({ setFieldValue, submitForm, values }: FormikProps<FormValues>) => {
      return () => {
        assert(account);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        setFieldValue(
          "automatedSenderId.locked",
          !values.automatedSenderId?.locked,
        );
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        setFieldValue(
          "automatedSenderId.lockedAtAccount",
          values.automatedSenderId?.locked
            ? null
            : {
                id: identifyAccount(account),
                name: account.name,
              },
        );

        return submitForm();
      };
    },
    [account],
  );

  /**
   * Handles the change of the active state of the setting.
   */
  const handleActiveChange = useCallback(
    ({ submitForm, setFieldValue }: FormikProps<FormValues>) => {
      return () => {
        setIsActive((currentIsActive) => {
          if (currentIsActive) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            setFieldValue("automatedSenderId.disabled", true);

            submitForm().catch(() => {
              setIsActive(true);
            });
          }

          return !currentIsActive;
        });
      };
    },
    [],
  );

  const locked =
    Boolean(account?.settings.automatedSenderId.locked) || !isAdmin;

  return (
    <SettingsPageWrapper>
      <PageHeader title="Automated Sender" toggleSidebar={toggleSidebar} />
      <Grid
        sx={{
          overflowY: "auto",
        }}
      >
        <Grid
          sx={(theme) => {
            return {
              margin: "auto",
              maxWidth: "100%",
              padding: 2,
              [theme.breakpoints.up("lg")]: {
                padding: 4,
                maxWidth: "1040px",
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                gap: theme.spacing(4),
              },
              [theme.breakpoints.up("xl")]: {
                maxWidth: "1420px",
              },
            };
          }}
        >
          <Typography variant="h4" gutterBottom>
            Keywords & Sequences
          </Typography>
          <AutomatedSenderSetting.Form
            initialValues={{
              automatedSenderId: account?.settings.automatedSenderId,
            }}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {(formProps) => {
              return (
                <SettingsCard component={Form}>
                  <SettingsCardHeader
                    account={account}
                    active={isActive}
                    canEditLock={
                      isAdmin &&
                      (!locked ||
                        account?.settings.automatedSenderId.lockedAtAccount
                          ?.id === id)
                    }
                    locked={locked}
                    onActiveChange={
                      isAdmin ? handleActiveChange(formProps) : undefined
                    }
                    onLockedChange={
                      isAdmin ? handleToggleLock(formProps) : undefined
                    }
                    title="Automated sender"
                  />
                  {(isActive || !isAdmin) && (
                    <>
                      {["loading", "waiting"].includes(accountState) && (
                        <SettingsCardContent>
                          <Skeleton variant="rectangular" height={56} />
                        </SettingsCardContent>
                      )}
                      {isAdmin ? (
                        <>
                          {locked && (
                            <SettingsCardAlert
                              icon={<LockIcon />}
                              severity="info"
                            >
                              Locked by an admin for accounts below{" "}
                              <strong>
                                {
                                  account?.settings.automatedSenderId
                                    .lockedAtAccount?.name
                                }
                              </strong>
                            </SettingsCardAlert>
                          )}
                          {account?.familyCount && account?.familyCount > 1 && (
                            <SettingsCardAlert severity="warning">
                              Modifying this may overwrite changes made on{" "}
                              <b>
                                {account.familyCount} branch and messaging
                                accounts.
                              </b>
                            </SettingsCardAlert>
                          )}
                        </>
                      ) : (
                        <SettingsCardAlert icon={<LockIcon />} severity="info">
                          This setting can only be modified by your
                          administrator.
                        </SettingsCardAlert>
                      )}
                      <SettingsCardDescription>
                        Text messages sent using Keywords or Sequences will be
                        displayed as being sent by the following user (e.g.
                        message logging within your integration). By default
                        this user is set to support@textus.com.
                      </SettingsCardDescription>
                      <SettingsCardContent>
                        {["loading", "waiting"].includes(accountState) && (
                          <AutomatedSenderSetting.Loading />
                        )}
                        {!["loading", "waiting"].includes(accountState) &&
                          account && (
                            <AutomatedSenderSetting.Fields
                              usersAutocompleteProps={{
                                disabled: locked,
                                usersQuery: {
                                  orgAdmin: true,
                                  accountSlug: identifyAccount(account),
                                },
                              }}
                              {...formProps}
                            />
                          )}
                      </SettingsCardContent>
                      <SettingsCardActions
                        sx={{
                          flexDirection: "row-reverse",
                        }}
                      >
                        <Button
                          variant="contained"
                          type="submit"
                          disabled={locked}
                        >
                          Save
                        </Button>
                      </SettingsCardActions>
                    </>
                  )}
                </SettingsCard>
              );
            }}
          </AutomatedSenderSetting.Form>
        </Grid>
      </Grid>
    </SettingsPageWrapper>
  );
}

import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { withSnackbar } from "notistack";

import { actionGenerators, reducer, selectors } from "./state";
import injectReducer from "utils/injectReducer";

class Notifications extends Component {
  static propTypes = {
    enqueueSnackbar: PropTypes.func.isRequired, // withSnackbar
    notifications: PropTypes.array.isRequired, // withConnect
    removeNotification: PropTypes.func.isRequired, // withConnect
  };

  static defaultProps = {
    notifications: [],
  };

  constructor(props) {
    super(props);
    this.displayed = [];
  }

  shouldComponentUpdate({ notifications: newSnacks }) {
    const { notifications: currentSnacks } = this.props;
    const currentSnackKeys = currentSnacks.map(({ key }) => {
      return key;
    });
    const newSnackKeys = newSnacks.map(({ key }) => {
      return key;
    });
    return Boolean(
      newSnackKeys.find((newSnackKey) => {
        return !currentSnackKeys.includes(newSnackKey);
      }),
    );
  }

  componentDidUpdate() {
    const { enqueueSnackbar, notifications, removeNotification } = this.props;
    notifications.forEach((notification) => {
      // Do nothing if snackbar is already displayed
      if (this.displayed.includes(notification.key)) return;
      // Display snackbar using notistack
      enqueueSnackbar(notification.message, notification.options);
      // Keep track of snackbars that we've displayed
      this.storeDisplayed(notification.key);
      // Dispatch action to remove snackbar from redux store
      removeNotification(notification.key);
    });
  }

  storeDisplayed = (key) => {
    this.displayed = [...this.displayed, key];
  };

  render() {
    return null;
  }
}

const mapStateToProps = (state, props) => {
  return {
    notifications: selectors.selectNotifications(state, props),
  };
};

const withConnect = connect(mapStateToProps, actionGenerators);

const withReducer = injectReducer({
  key: "notificationsContainer",
  reducer,
});

export default compose(withSnackbar, withReducer, withConnect)(Notifications);

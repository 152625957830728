import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import useMediaQuery from "@mui/material/useMediaQuery";
import MobileStepper from "@mui/material/MobileStepper";

import { isAfter, isBefore, isSameDay } from "date-fns";
import { handleResponse } from "@tesseract/core";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import CampaignForm from "../../sharedComponents/CampaignForm";
import CampaignProForm from "../../sharedComponents/CampaignProForm";
import { getCutoffError } from "../../utils/getCutoffError";
import CampaignPreview from "./CampaignPreview";
import { StickyFooter } from "components/StickyFooter";
import getUserRole from "utils/getUserRole";
import createUUID from "utils/uuid";
import { createMessageBody } from "utils/createMessageBody";
import { useCurrentAccount, useTimeZones } from "hooks";
import { fetchGroup } from "features/Groups/api";
import { createSnackbarContent } from "components/Snackbar";

import PageHeader from "components/Page/PageHeader";
import { campaignTypes } from "features/Campaigns/constants";

function EditCampaign({
  campaign,
  currentAccount,
  currentUser,
  deliveryStats,
  editCampaignContainer,
  updateCampaignRequest,
  toggleSidebar,
}) {
  const { isCampaignProMember } = useCurrentAccount();
  const { enqueueSnackbar } = useSnackbar();
  const { accountTimeZone } = useTimeZones();

  const [activeStep, setActiveStep] = useState(0);

  const signatureContent = currentUser?.signature?.content || "";

  const campaignTemplates = campaign.campaignTemplates?.members.map(
    ({
      attachments,
      messageBody = "",
      id,
      links = {
        fullLink: "",
        shortLink: currentAccount.shortenedLinkPreviewUrl,
      },
      signatureActive,
    }) => {
      return {
        attachments: attachments?.members || attachments,
        body: messageBody.replace(signatureContent, "").trim(),
        id,
        links,
        signatureActive:
          signatureActive || messageBody.includes(signatureContent),
      };
    },
  );

  const smView = useMediaQuery((theme) => {
    return theme.breakpoints.only("sm");
  });

  const mobileView = useMediaQuery((theme) => {
    return theme.breakpoints.down("sm");
  });

  const getUrl = (campaignId) => {
    const splitId = campaignId.split("/")[2];
    return `/${currentAccount.slug}/campaigns/mine/${splitId}/total`;
  };

  const [campaignValues, setCampaignValues] = useState({
    campaignEndDate: null,
    dayToSend: "",
    messageBody: campaignTemplates[0]?.messageBody ?? campaign.messageBody,
    messageTemplates: campaignTemplates ?? [],
    attachments: campaign.attachments?.members ?? [],
    runsIndefinitely: false,
    sendFrequency: "",
    sendTime: "",
    title: campaign.title,
  });

  const { attachments, messageBody, messageTemplates, title } = campaignValues;

  const [scheduledAt, setScheduledAt] = useState(
    new Date(campaign.scheduledAt),
  );
  const [autoAssign, setAutoAssign] = useState(
    !!getUserRole(currentUser, currentAccount),
  );
  const [isSignatureActive, setIsSignatureActive] = useState(false);
  const [shortenedLink, setShortenedLink] = useState({
    fullLink: "",
    shortLink: currentAccount.shortenedLinkPreviewUrl,
  });
  const [scheduleError, setScheduleError] = useState(false);
  const [groupName, setGroupName] = useState("");

  const messageBodyWithoutSignature = messageBody
    .replace(signatureContent, "")
    .trim();

  const recipientCount = campaign?.deliveryStatistics?.total ?? 0;

  const additionalRecipientsRequired =
    activeStep === 0 &&
    messageTemplates?.length >= recipientCount &&
    recipientCount < 3;

  const hasCutoffError = getCutoffError(currentAccount, scheduledAt);

  useEffect(() => {
    if (messageBody.includes(signatureContent) && signatureContent !== "") {
      setIsSignatureActive(true);
    }
  }, [messageBody, signatureContent]);

  useEffect(() => {
    fetchGroup(campaign.group)
      .then(handleResponse)
      .then((group) => {
        return setGroupName(group.name);
      })
      .catch((error) => {
        return console.error(error);
      });
  });

  useEffect(() => {
    if (isBefore(scheduledAt, new Date())) {
      setScheduleError(true);
    }
  }, [scheduledAt]);

  const steps = ["Update campaign", "Review and send"];

  const getDailyCampaignRecipients = () => {
    return currentAccount.settings?.dailyCampaignRecipients?.value;
  };

  const getLimitError = () => {
    const isToday = isSameDay(new Date(), scheduledAt);
    if (!isToday) return null;
    const dailyCampaignRecipients = getDailyCampaignRecipients();
    const sentAndScheduledCount =
      deliveryStats.campaignMessagesUsed ??
      0 + deliveryStats.campaignMessagesScheduled ??
      0;
    return recipientCount + sentAndScheduledCount > dailyCampaignRecipients
      ? "This campaign will push you over your daily limit. Please select a different date."
      : null;
  };

  const handleDeleteVariant = ({ id }) => {
    const updatedMessageTemplates = messageTemplates?.filter((template) => {
      return template.id !== id;
    });
    setCampaignValues({
      ...campaignValues,
      messageTemplates: updatedMessageTemplates,
    });
  };

  const handleAddVariant = () => {
    const updatedMessageTemplates = messageTemplates?.concat({
      attachments: [],
      messageBody: "",
      signatureActive: isSignatureActive,
      id: createUUID(),
    });
    setCampaignValues({
      ...campaignValues,
      messageTemplates: updatedMessageTemplates,
    });
  };

  const parseErrors = () => {
    const error = editCampaignContainer?.substate?.errorUpdating;
    if (!error) return undefined;
    const validationErrors = error.response?.validationErrors;
    if (!validationErrors)
      return "Something went wrong! Please select another date";
    return validationErrors[Object.keys(validationErrors)[0]] ?? "0";
  };

  const handleSubmit = (values) => {
    // we know that campaigns pro users will have messageTemplates
    // and non pro users will not
    if (values.messageTemplates) {
      const newMessageBody = values.messageTemplates[0].body;
      const updatedMessageTemplates = values.messageTemplates?.map(
        (template) => {
          return {
            ...template,
            messageBody: template.body,
          };
        },
      );

      setCampaignValues({
        ...campaignValues,
        ...values,
        messageBody: newMessageBody,
        messageTemplates: updatedMessageTemplates,
      });
    } else {
      setCampaignValues({
        ...campaignValues,
        ...values,
      });
    }

    setActiveStep(1);
  };

  const handleUpdate = () => {
    const isSending =
      editCampaignContainer?.substate?.isCreating ||
      editCampaignContainer?.substate?.isScheduling;
    if (isSending) return null;
    const updatedMessageBody = createMessageBody(
      messageBody,
      isSignatureActive,
      signatureContent,
      shortenedLink,
    );
    const updatedMessageTemplates = messageTemplates.map((template) => {
      return {
        ...template,
        body: createMessageBody(
          template.body,
          template.signatureActive,
          signatureContent,
          template.links,
        ),
      };
    });
    const body = {
      attachments: isCampaignProMember
        ? messageTemplates[0].attachments.members
        : attachments,
      autoAssign,
      title,
      scheduledAt,

      ...(!isCampaignProMember && {
        messageBody: updatedMessageBody,
        ...(scheduledAt &&
          isAfter(scheduledAt, new Date()) && {
            scheduledAt: scheduledAt.toISOString(),
          }),
      }),

      ...(isCampaignProMember && {
        messageTemplates: updatedMessageTemplates,
      }),
    };

    return updateCampaignRequest(campaign.edit, body, {
      errorCallback: (error) => {
        enqueueSnackbar("error", {
          content: createSnackbarContent(
            "Something went wrong. Please try again.",
          ),
        });
        return console.error(`Error scheduling campaign: ${error.detail}`);
      },
    });
  };

  const getCampaignForm = () => {
    return isCampaignProMember && campaign.type === "single" ? (
      <CampaignProForm
        additionalRecipientsRequired={additionalRecipientsRequired}
        attachments={attachments}
        currentAccount={currentAccount}
        currentUser={currentUser}
        editCampaign
        handleAddVariant={handleAddVariant}
        handleDeleteVariant={handleDeleteVariant}
        handleSubmit={handleSubmit}
        messageTemplates={messageTemplates}
        setShortenedLink={setShortenedLink}
        shortenedLink={shortenedLink}
        title={title}
      />
    ) : (
      <CampaignForm
        attachments={attachments}
        campaignType={campaign.type}
        currentAccount={currentAccount}
        currentUser={currentUser}
        editCampaign
        handleSubmit={handleSubmit}
        isSignatureActive={isSignatureActive}
        messageBody={messageBodyWithoutSignature}
        setIsSignatureActive={setIsSignatureActive}
        setShortenedLink={setShortenedLink}
        shortenedLink={shortenedLink}
        title={title}
      />
    );
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return getCampaignForm();
      case 1:
        return (
          <CampaignPreview
            attachments={attachments}
            autoAssign={autoAssign}
            campaign={campaign}
            currentUser={currentUser}
            currentAccount={currentAccount}
            getDailyCampaignRecipients={getDailyCampaignRecipients}
            getLimitError={getLimitError}
            groupName={groupName}
            isCampaignsPro={
              isCampaignProMember && campaign.type === campaignTypes.oneTime
            }
            isSignatureActive={isSignatureActive}
            messageBody={messageBody}
            messageTemplates={messageTemplates}
            parseErrors={parseErrors}
            scheduledAt={scheduledAt}
            setActiveStep={setActiveStep}
            setAutoAssign={setAutoAssign}
            scheduleError={scheduleError}
            setScheduledAt={setScheduledAt}
            setScheduleError={setScheduleError}
          />
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <Box
      color="text.primary"
      display="flex"
      flexDirection="column"
      fontSize="14px"
      height="100%"
    >
      <PageHeader
        title="Edit one-time campaign"
        toggleSidebar={toggleSidebar}
      />
      <Box
        display="flex"
        flexDirection="column"
        flex="1 1 auto"
        minHeight="0"
        overflow="auto"
        sx={{ overflowX: "hidden" }}
        p="0"
        width="100%"
        height="100%"
      >
        {mobileView ? null : (
          <Stepper
            activeStep={activeStep}
            alternativeLabel={smView}
            sx={{
              padding: "0rem",
              margin: "0 auto",
              maxWidth: "22.5rem",
              width: "100%",
              paddingTop: "2rem",
              paddingBottom: "2rem",
            }}
          >
            {steps.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        )}
        <Box
          display="flex"
          flex="1 1 auto"
          flexDirection="column"
          margin="0 auto"
          maxWidth="800px"
          width="100%"
        >
          {getStepContent(activeStep)}
        </Box>
        {mobileView ? (
          <StickyFooter boxShadow="none">
            <MobileStepper
              variant="text"
              steps={2}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Box id="edit-textus-NewCampaign-NextButton">
                  {activeStep === 1 && (
                    <Button
                      size="small"
                      aria-label="Send Button"
                      color="primary"
                      disabled={
                        !!getLimitError() ||
                        hasCutoffError ||
                        scheduleError ||
                        !scheduledAt
                      }
                      onClick={handleUpdate}
                      variant="text"
                    >
                      Send
                    </Button>
                  )}
                </Box>
              }
              backButton={
                activeStep === 0 ? (
                  <Button
                    size="small"
                    color="primary"
                    component={Link}
                    to={getUrl(campaign.id)}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    size="small"
                    color="primary"
                    disabled={activeStep === 0}
                    onClick={() => {
                      return setActiveStep(0);
                    }}
                  >
                    Back
                  </Button>
                )
              }
              sx={(theme) => {
                return {
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  padding: "0.5rem 1rem",
                  fontSize: theme.typography.body2,
                  background: theme.palette.background.paper,
                  borderTop: `1px solid ${theme.palette.divider}`,
                  height: "43px",
                };
              }}
            />
          </StickyFooter>
        ) : (
          <StickyFooter
            alignItems="center"
            display="flex"
            flex="0 0 auto"
            justifyContent="space-between"
            height="52px"
            padding="0 16px"
            boxShadow="none"
            sx={(theme) => {
              return { borderTop: `1px solid ${theme.palette.divider}` };
            }}
          >
            {activeStep === 0 &&
              isCampaignProMember &&
              campaign.type === campaignTypes.oneTime && (
                <Box alignItems="center" display="flex" flexDirection="row">
                  <Button
                    alignSelf="center"
                    onClick={() => {
                      handleAddVariant({
                        messageTemplates,
                        campaignTitle: campaignValues.title,
                      });
                    }}
                    aria-label="Add Message"
                    disabled={
                      messageTemplates?.length > 2 ||
                      additionalRecipientsRequired
                    }
                    sx={{ minWidth: "7rem" }}
                  >
                    Add Message
                  </Button>

                  {additionalRecipientsRequired && (
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      lineHeight="115%"
                    >
                      Additional recipients are needed to include another
                      message variant.
                    </Typography>
                  )}
                </Box>
              )}
            <Box display="flex" flex="1 1 0" justifyContent="flex-end">
              {activeStep === 0 ? (
                <Button
                  color="primary"
                  component={Link}
                  to={getUrl(campaign.id)}
                  sx={{ marginRight: "0.625rem" }}
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  color="primary"
                  disabled={activeStep === 0}
                  onClick={() => {
                    return setActiveStep(0);
                  }}
                  sx={{ marginRight: "0.625rem" }}
                >
                  Back
                </Button>
              )}
              <Box id="textus-NewCampaign-NextButton">
                {activeStep === 1 && (
                  <Button
                    aria-label="Send Button"
                    color="primary"
                    disabled={
                      !!getLimitError() ||
                      hasCutoffError ||
                      scheduleError ||
                      !scheduledAt
                    }
                    onClick={handleUpdate}
                    variant="contained"
                  >
                    Send campaign
                  </Button>
                )}
              </Box>
            </Box>
          </StickyFooter>
        )}
      </Box>
    </Box>
  );
}

EditCampaign.propTypes = {
  campaign: PropTypes.object.isRequired,
  currentAccount: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  deliveryStats: PropTypes.object.isRequired,
  editCampaignContainer: PropTypes.object.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  updateCampaignRequest: PropTypes.func.isRequired,
};

export default EditCampaign;

import { Component } from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { withSnackbar } from "notistack";

class SnackbarCloseButton extends Component {
  static propTypes = {
    closeSnackbar: PropTypes.func.isRequired,
    snackbarKey: PropTypes.number.isRequired,
  };

  render() {
    const { closeSnackbar, snackbarKey } = this.props;
    return (
      <IconButton
        key="close"
        aria-label="Close"
        onClick={() => {
          return closeSnackbar(snackbarKey);
        }}
        size="large"
        sx={{ color: "unset" }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  }
}

export default withSnackbar(SnackbarCloseButton);

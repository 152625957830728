import { useState } from "react";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import Loader from "components/Loader";
import SnackbarCloseButton from "containers/SnackbarCloseButton";

function OptOutImport(props) {
  const [isLoading, setIsLoading] = useState(false);

  const successCallback = () => {
    const { addNotification } = props;
    setIsLoading(false);
    addNotification({
      message:
        "We're exporting your opt outs. You should receive an email shortly!",
      options: {
        action: (key) => {
          return <SnackbarCloseButton snackbarKey={key} />;
        },
      },
    });
  };

  const errorCallback = () => {
    const { addNotification } = props;
    setIsLoading(false);
    addNotification({
      message: "Uh oh! Something went wrong. Please try again!",
      options: {
        action: (key) => {
          return <SnackbarCloseButton snackbarKey={key} />;
        },
        variant: "error",
      },
    });
  };

  const handleClick = () => {
    const { currentAccount, exportOptOutsRequest } = props;
    setIsLoading(true);
    exportOptOutsRequest(
      currentAccount.exportOptOuts,
      {},
      {
        successCallback,
        errorCallback,
      },
    );
  };

  return (
    <Button
      data-test="ExportOptOutsButton"
      color="primary"
      onClick={handleClick}
    >
      <Loader isLoading={isLoading}>Export Opt Outs</Loader>
    </Button>
  );
}

OptOutImport.propTypes = {
  addNotification: PropTypes.func.isRequired,
  exportOptOutsRequest: PropTypes.func.isRequired,
  currentAccount: PropTypes.object.isRequired,
};

export default OptOutImport;

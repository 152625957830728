import PropTypes from "prop-types";
import { Component } from "react";
import get from "lodash/get";
import queryString from "query-string";
import pluralize from "pluralize";

import { Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import MobileOffIcon from "@mui/icons-material/MobileOff";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import SnackbarCloseButton from "containers/SnackbarCloseButton";

import AddContactsToGroupModal from "features/Contacts/containers/AddContactsToGroupModal";
import { GridActionToolbar } from "components/GridActionToolbar";
import CampaignIcon from "icons/CampaignIcon";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import { SequenceEnroller } from "features/Sequences/components/SequenceEnroller";
import { formatSelectedRecords } from "utils/formatSelectedRecords";

const BATCH_MESSAGE_LIMIT = 10;

export default class ContactBatchActions extends Component {
  static propTypes = {
    addNotification: PropTypes.func.isRequired,
    allSelected: PropTypes.bool.isRequired,
    clearBatchActionState: PropTypes.func.isRequired,
    contactBatchDeleteRequest: PropTypes.func,
    contactBatchPutRequest: PropTypes.func,
    contactCollection: PropTypes.object.isRequired,
    contactFilter: PropTypes.object.isRequired,
    currentAccount: PropTypes.object.isRequired,
    handleSetCompose: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isMobile: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    selectedContacts: PropTypes.array.isRequired,
    selectedRecords: PropTypes.array,
    setAllSelected: PropTypes.func.isRequired,
    setSelectedContacts: PropTypes.func,
    setSelectedRecords: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const { modal = false } = queryString.parse(this.props.location?.search);
    this.state = {
      confirmationModalAction: undefined,
      addContactsToGroupModalVisible: !!modal,
    };
  }

  getBatchActions = () => {
    const { contactFilter, currentAccount, isMobile } = this.props;
    if (contactFilter.slug === "recently-deleted") {
      if (isMobile) {
        return [
          {
            title: "Restore Contacts",
            icon: (
              <RestoreFromTrashIcon
                data-testid="restore-contacts-icon"
                fontSize="small"
                color="inherit"
              />
            ),
            clickHandler: this.createClickHandler("restore"),
            showExpanded: true,
          },
        ];
      } else {
        return [
          {
            button: (
              <Button
                startIcon={<RestoreFromTrashIcon fontSize="small" />}
                onClick={this.createClickHandler("restore")}
                aria-label="Restore Contacts"
                data-testid="restore-contacts-icon-button"
              >
                Restore Contacts
              </Button>
            ),
            showExpanded: true,
          },
        ];
      }
    }

    const messagingEnabled =
      get(currentAccount, ["phoneNumbers"], []).length > 0;
    const messagingOptions = [
      {
        title: "Send message",
        icon: <SendIcon data-testid="send-message" fontSize="small" />,
        clickHandler: this.handleSendMessageClick,
        showExpanded: true,
      },
      {
        title: "Send campaign",
        icon: (
          <CampaignIcon
            data-testid="send-campaign"
            fontSize="small"
            color="inherit"
          />
        ),
        clickHandler: this.handleSendCampaignClick,
        showExpanded: true,
      },
      {
        title: "Add contacts to group",
        icon: <GroupAddIcon fontSize="small" />,
        clickHandler: this.handleAddToGroupClick,
        showExpanded: !isMobile,
      },
    ];

    messagingOptions.splice(2, 0, {
      button: (
        <SequenceEnroller
          key="enroller"
          allSelected={this.props.allSelected}
          buttonType="icon"
          currentAccount={this.props.currentAccount}
          contactCollectionId={this.props.contactCollection.view.first}
          contactFilterSlug={decodeURIComponent(this.props.contactFilter.slug)}
          formattedSelectedRecords={formatSelectedRecords(
            this.props.selectedRecords,
          )}
          selected={this.props.selectedContacts}
          setSelected={() => {
            this.props.setAllSelected(false);
          }}
          setSelectedRecords={this.props.setSelectedRecords}
        />
      ),
      showExpanded: true,
    });

    const otherOptions = [
      {
        title: "Opt out number",
        icon: <MobileOffIcon fontSize="small" data-testid="opt-out-number" />,
        clickHandler: this.createConfirmationClickHandler("unsubscribe"),
      },
      {
        title: "Block number",
        icon: <RemoveCircleIcon fontSize="small" data-testid="block-number" />,
        clickHandler: this.createClickHandler("block"),
      },
      {
        title: "Unblock number",
        icon: <CheckCircleIcon fontSize="small" data-testid="unblock-number" />,
        clickHandler: this.createClickHandler("unblock"),
      },
      ...(get(contactFilter, ["removeContacts"])
        ? [
            {
              title: "Remove contacts",
              icon: <DeleteIcon fontSize="small" />,
              clickHandler: this.createConfirmationClickHandler("delete"),
            },
          ]
        : []),
    ];
    if (messagingEnabled) {
      return [...messagingOptions, ...otherOptions];
    }

    return otherOptions;
  };

  getActionPastParticiple = (action) => {
    if (action.endsWith("e")) return `${action}d`;
    return `${action}ed`;
  };

  getRequestUrl = ({ action, filter, selectAll }) => {
    const { contactCollection } = this.props;
    const actionRequestUrl =
      filter[`${action}Contacts`] || contactCollection.id;
    const [base, query] = actionRequestUrl.split("?");
    const queryParams = queryString.stringify({
      ...queryString.parse(query),
      selectAll: selectAll ? "true" : undefined,
    });
    return `${base}${queryParams ? `?${queryParams}` : ""}`;
  };

  getTotalCount = () => {
    const { allSelected, contactCollection, selectedContacts } = this.props;
    return allSelected ? contactCollection.totalItems : selectedContacts.length;
  };

  createClickHandler = (action, message = null) => {
    const renderSnackbar = (key) => {
      return <SnackbarCloseButton snackbarKey={key} />;
    };
    return () => {
      const {
        addNotification,
        allSelected,
        contactBatchPutRequest,
        contactBatchDeleteRequest,
        contactFilter,
        selectedContacts,
      } = this.props;
      const requestUrl = this.getRequestUrl({
        action,
        filter: contactFilter,
        selectAll: allSelected,
      });
      const totalCount = this.getTotalCount();
      if (action === "block") {
        contactBatchPutRequest(requestUrl, selectedContacts);
      } else if (action === "restore") {
        const [base, _] = requestUrl.split("contacts");
        contactBatchPutRequest(base + "restore_contacts", selectedContacts);
      } else {
        contactBatchDeleteRequest(requestUrl, selectedContacts);
      }
      const baseMessage = `${pluralize(
        "contacts",
        totalCount,
      )} ${this.getActionPastParticiple(action)}`;
      addNotification({
        message: `${totalCount.toLocaleString()} ${message || baseMessage}`,
        options: {
          action: renderSnackbar,
        },
      });
      this.resetBatchActionState(action);
    };
  };

  handleSendMessageClick = () => {
    this.props.handleSetCompose();
  };

  handleSendCampaignClick = () => {
    const { allSelected, contactFilter, history, selectedContacts } =
      this.props;
    const state = allSelected
      ? { contactFilterId: contactFilter.id }
      : { selectedContacts };
    history.push({
      pathname: `${global.document.location.pathname
        .split("/")
        .slice(0, 2)
        .join("/")}/campaigns/new`,
      state,
    });
  };

  handleAddToGroupClick = () => {
    this.setState({ addContactsToGroupModalVisible: true });
  };

  handleModalCancel = () => {
    this.setState({
      confirmationModalAction: undefined,
    });
  };

  handleOptOutModalConfirm = () => {
    const message = "Opt-out notfications sent";
    this.createClickHandler("unsubscribe", message)();
    this.setState({
      confirmationModalAction: undefined,
    });
  };

  handleDeleteModalConfirm = () => {
    const totalCount = this.getTotalCount();
    const message = `${pluralize(
      "contact",
      totalCount,
    )} being removed. The page will update shortly.`;
    const deleteHandler = this.createClickHandler("delete", message);
    deleteHandler();
    this.setState({
      confirmationModalAction: undefined,
    });
  };

  createConfirmationClickHandler = (action) => {
    return () => {
      this.setState({ confirmationModalAction: action });
    };
  };

  resetBatchActionState = (action) => {
    const { contactFilter, clearBatchActionState } = this.props;
    if (
      (contactFilter.id.includes("blocked") && action === "unblock") ||
      action === "delete"
    ) {
      clearBatchActionState();
    }
  };

  render() {
    const {
      allSelected,
      contactCollection,
      currentAccount,
      selectedContacts,
      setAllSelected,
      setSelectedContacts,
      setSelectedRecords,
    } = this.props;

    const { confirmationModalAction, addContactsToGroupModalVisible } =
      this.state;
    const dailyCampaignRecipients = get(currentAccount, [
      "settings",
      "dailyCampaignRecipients",
      "value",
    ]);
    const selectAllCount = contactCollection.totalItems;
    const selectedCount = selectedContacts.length;
    const totalSelected = allSelected ? selectAllCount : selectedCount;
    const actions = this.getBatchActions()
      .filter(({ title }) => {
        return !(
          title === "Send message" && totalSelected > BATCH_MESSAGE_LIMIT
        );
      })
      .filter(({ title }) => {
        return !(
          ["Send campaign", "Create Group"].includes(title) &&
          totalSelected > dailyCampaignRecipients
        );
      });

    const left = "50px";

    return (
      <>
        <AddContactsToGroupModal
          closeModal={() => {
            return this.setState({ addContactsToGroupModalVisible: false });
          }}
          contacts={allSelected ? contactCollection.id : selectedContacts}
          contactTotal={totalSelected}
          visible={addContactsToGroupModalVisible}
        />

        <GridActionToolbar
          actions={actions}
          allSelected={allSelected}
          isMobile={this.props.isMobile}
          position={{ top: "0px", left }}
          selectedCount={selectedCount}
          selectAllCount={selectAllCount}
          setAllSelected={setAllSelected}
          setSelectedContacts={setSelectedContacts}
          setSelectedRecords={setSelectedRecords}
        />

        <ConfirmationModal
          cancellationHandler={this.handleModalCancel}
          confirmationHandler={this.handleOptOutModalConfirm}
          isOpen={confirmationModalAction === "unsubscribe"}
          additionalMessage=" Only the contact can opt themselves back in."
          boldText={`opt-out ${pluralize(
            "contact",
            allSelected
              ? get(contactCollection, ["totalItems"])
              : selectedContacts.length,
            true,
          )}. `}
          message="You are about to "
          title="Opt-Out Contacts"
        />
        <ConfirmationModal
          cancellationHandler={this.handleModalCancel}
          confirmationHandler={this.handleDeleteModalConfirm}
          isOpen={confirmationModalAction === "delete"}
          message="These contacts will be unassigned from any conversations and removed from all groups."
          title={`Remove ${pluralize(
            "contact",
            allSelected
              ? get(contactCollection, ["totalItems"])
              : selectedContacts.length,
            true,
          )}?`}
        />
      </>
    );
  }
}

/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { useEffect, useState } from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useSnackbar } from "notistack";
import { getWindowFeatures } from "../../utils/getWindowFeatures";
import { getConnectedIntegrations } from "../../api/getConnectedIntegrations";
import CONSTANTS from "./constants";
import { AuthorizeJobAdderButtonProps } from "./types";
import { selectAppSettings } from "features/EntryPoint/containers/App/selectors";
import { createSnackbarContent } from "components/Snackbar";
import { IntegrationProvider } from "models/IntegrationProvider";

export function AuthorizeJobAdderButton({
  currentAccount,
  setOauthDialogOpen,
  setSelectedIntegration,
}: AuthorizeJobAdderButtonProps) {
  const [authWindow, setAuthWindow] = useState<Window | null>(null);
  const [intervalId, setIntervalId] = useState<number | null>(null);
  const appSettings = useSelector(selectAppSettings);
  const { enqueueSnackbar } = useSnackbar();

  const handleGetConnectedIntegrations = async () => {
    try {
      const response = await getConnectedIntegrations(currentAccount.slug);
      if (!response.ok) {
        throw new Error("Failed to get connected integrations.");
      }
      const data = await response.json();
      if (data.members && data.members.length > 0) {
        const connectedIntegrationData = data.members.find(
          (integration: IntegrationProvider) => {
            return integration.provider === "job_adder";
          },
        );
        setSelectedIntegration(connectedIntegrationData);
        setOauthDialogOpen(false);
        if (connectedIntegrationData) {
          enqueueSnackbar("Integration successfully connected.", {
            content: createSnackbarContent("info"),
          });
        }
      }
      return null;
    } catch {
      enqueueSnackbar("Failed to get connected integrations.", {
        content: createSnackbarContent("error"),
      });
    }
  };

  const openAuthWindow = () => {
    const width = 800;
    const height = 800;
    const { JOB_ADDER_KEY } = appSettings;
    const accountIntegrationId = currentAccount.integrations;
    localStorage.setItem(CONSTANTS.localStorageKey, accountIntegrationId);
    const query = queryString.stringify({
      client_id: JOB_ADDER_KEY,
      response_type: "code",
      redirect_uri: CONSTANTS.url,
      scope: "offline_access write read",
    });
    const jobAdderUrl = `https://id.jobadder.com/connect/authorize?${query}`;

    const openWindow = window.open(
      jobAdderUrl,
      undefined,
      getWindowFeatures({ width: 800, height: 800 }),
    );
    setAuthWindow(openWindow);

    const id = window.setInterval(() => {
      if (openWindow && openWindow.closed) {
        handleGetConnectedIntegrations();
        window.clearInterval(id);
        setAuthWindow(null);
        setIntervalId(null);
      }
    }, 1000);
    setIntervalId(id);
  };

  useEffect(() => {
    return () => {
      if (intervalId) {
        window.clearInterval(intervalId);
      }
    };
  }, []);

  return (
    <Button
      color="primary"
      endIcon={<OpenInNewIcon />}
      onClick={() => {
        openAuthWindow();
      }}
      variant="contained"
      sx={{
        padding: "6px 16px",
      }}
    >
      Authorize
    </Button>
  );
}

import {
  Box,
  Breadcrumbs,
  Divider,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { SyntheticEvent, useState } from "react";
import { IntegrationDetailsActions } from "../IntegrationDetailsActions/IntegrationDetailsActions";
import { IntegrationDetailsFooter } from "../IntegrationDetailsFooter";
import { integrationDetailsTabs } from "./constants";
import { IntegrationDetailsProps } from "./types";
import { TabPanel } from "components/TabPanel";
import SettingsPageContent from "components/SettingsPageComponents/SettingsPageContent";
import PageHeader from "components/Page/PageHeader";
import { IntegrationProvider } from "models/IntegrationProvider";

export function IntegrationDetails({
  allIntegrations,
  selectedIntegration,
  setSelectedIntegration,
  toggleSidebar,
}: IntegrationDetailsProps) {
  const [currentTab, setCurrentTab] = useState(0);

  // Represents the selected integration's updated config and settings values
  const [updatedIntegrationData, setUpdatedIntegrationData] =
    useState(selectedIntegration);

  const mobileScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  const { general, noteLogging, contacts } = integrationDetailsTabs;

  // Represents the selected integration's initial config and settings values
  const initialIntegrationValues = Object.freeze(selectedIntegration);

  // Represents the selected integration's config and settings fields, strategy, and formatted name.
  const integrationState = allIntegrations.find(
    (integration: IntegrationProvider) => {
      const matchingIntegration =
        integration.provider === selectedIntegration?.provider;
      return Object.freeze(matchingIntegration);
    },
  );

  const handleTabChange = (event: SyntheticEvent, newTabValue: number) => {
    setCurrentTab(newTabValue);
  };

  return (
    <Box flexDirection="column" height="80%">
      <PageHeader
        aria-label="Integration's Detail Page"
        title={
          <Breadcrumbs>
            <Typography
              onClick={() => {
                return setSelectedIntegration(null);
              }}
              sx={{
                color: "text.secondary",
                fontSize: "1rem",
                lineHeight: "1.5rem",
                textDecoration: "none",
                ":hover": { textDecoration: "underline" },
              }}
            >
              Integrations
            </Typography>
            <Typography
              fontSize="1rem"
              lineHeight="1.5rem"
              color="text.hyperlink"
              fontWeight={800}
            >
              {integrationState?.providerName ?? ""}
            </Typography>
          </Breadcrumbs>
        }
        toggleSidebar={toggleSidebar}
      />
      <Box
        display="flex"
        flexDirection={mobileScreen ? "column" : "row"}
        alignContent="center"
        justifyContent="space-between"
        paddingX="16px"
        paddingTop="16px"
        paddingBottom="8px"
      >
        <Typography
          sx={{
            paddingBottom: mobileScreen ? "8px" : "0",
          }}
          variant="h3"
        >
          {integrationState?.providerName ?? ""}
        </Typography>
        <IntegrationDetailsActions
          setSelectedIntegration={setSelectedIntegration}
          setUpdatedIntegrationData={setUpdatedIntegrationData}
          updatedIntegrationData={updatedIntegrationData}
        />
      </Box>
      <Tabs
        aria-label="General, note logging, and contacts tabs"
        data-testid="IntegrationDetailsTabs"
        indicatorColor="primary"
        onChange={handleTabChange}
        textColor="primary"
        scrollButtons={mobileScreen}
        variant="scrollable"
        allowScrollButtonsMobile
        value={currentTab}
      >
        <Tab aria-label="General-tab" label="General" value={general} />
        <Tab
          aria-label="Messag-logging-tab"
          label="Message Logging"
          value={noteLogging}
        />
        <Tab aria-label="Contacts-tab" label="Contacts" value={contacts} />
      </Tabs>
      <Divider />
      <SettingsPageContent overflow={false} aria-label="Integration Details">
        <TabPanel value={currentTab} index={0}>
          Placeholder for General Tab
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          Placeholder for Note Logging
        </TabPanel>
        <TabPanel value={currentTab} index={2}>
          Placeholder for Contacts
        </TabPanel>
      </SettingsPageContent>
      <IntegrationDetailsFooter
        initialIntegrationValues={initialIntegrationValues}
        updatedIntegrationData={updatedIntegrationData}
      />
    </Box>
  );
}

import { useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import ScheduledReports from "./components/ScheduledReports";
import { actionGenerators as notificationsActionGenerators } from "features/EntryPoint/containers/Notifications/state";
import { selectAccountNavbarElements } from "features/EntryPoint/containers/App/selectors";

import Loader from "components/Loader";
import Logo from "components/Logo";
import PageHeader from "components/Page/PageHeader";
import SnackbarCloseButton from "containers/SnackbarCloseButton";
import withRecord from "higherOrderComponents/withRecord";

import SettingsPageWrapper from "components/SettingsPageComponents/SettingsPageWrapper";
import SettingsPageContent from "components/SettingsPageComponents/SettingsPageContent";

function ScheduledAnalytics({
  addNotification,
  currentUser,
  currentAccount: { slug },
  fetchAnalyticsReportsListRequest,
  isMediumLarge,
  toggleSidebar,
  accountNavbarElements,
}) {
  const [loading, setLoading] = useState(true);
  const [reportIds, setReportIds] = useState([]);

  const analyticsReportsListId = `/${slug}/analytics`;
  const accountList = accountNavbarElements.map(({ account, title }) => {
    return {
      name: title,
      slug: account,
    };
  });
  const successCallback = (response) => {
    const analyticsReports = response?.members ?? [];
    setReportIds(analyticsReports);
    setLoading(false);
  };

  const errorCallback = (error) => {
    addNotification({
      message: `Something went wrong trying to get analytic reports. ${error.name}: ${error.message}`,
      options: {
        action: (key) => {
          return <SnackbarCloseButton snackbarKey={key} />;
        },
        variant: "error",
      },
    });
  };

  useEffect(() => {
    fetchAnalyticsReportsListRequest(analyticsReportsListId, null, {
      successCallback,
      errorCallback,
    });
  }, [analyticsReportsListId]);
  return (
    <SettingsPageWrapper>
      <PageHeader title="Scheduled Analytics" toggleSidebar={toggleSidebar} />
      <SettingsPageContent isMediumLarge={isMediumLarge}>
        <Box display="flex" flexDirection="column" height="100%">
          {loading ? (
            <Loader isLoading={loading} aria-label="Page is Loading">
              <Logo animate dotColor="contrast" />
            </Loader>
          ) : (
            <ScheduledReports
              accountList={accountList}
              addNotification={addNotification}
              currentUser={currentUser}
              reports={reportIds}
            />
          )}
        </Box>
      </SettingsPageContent>
    </SettingsPageWrapper>
  );
}

ScheduledAnalytics.propTypes = {
  accountNavbarElements: PropTypes.array.isRequired,
  addNotification: PropTypes.func.isRequired,
  currentAccount: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  fetchAnalyticsReportsListRequest: PropTypes.func.isRequired,
  isMediumLarge: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    accountNavbarElements: selectAccountNavbarElements(state, props),
  };
};

const withConnect = connect(
  () => {
    return mapStateToProps;
  },
  {
    ...notificationsActionGenerators,
  },
);

export default compose(
  withRecord({
    actions: ["fetch"],
    container: "features/ScheduledAnalytics/analyticsReportsList",
    noFetch: true,
    shape: {},
    showLoader: () => {
      return false;
    },
    type: "analyticsReportsList",
  }),
  withConnect,
)(ScheduledAnalytics);

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, ListItem, IconButton, Tooltip, Typography } from "@mui/material";
import { DeleteIcon } from "icons";
import SnackbarCloseButton from "containers/SnackbarCloseButton";
import { fetchSavedReplyCollection } from "features/SavedReplies/api";
import { useCurrentAccount, useUserData } from "hooks";
import UserAvatar from "components/Avatar";

export default function Root({
  addNotification,
  clickHandler,
  currentUser,
  deleteSavedReplyRequest,
  savedReplyCollectionId,
  isActive,
  savedReply,
  setActiveSavedReply,
  setSavedReplies,
}) {
  const [hovering, setHovering] = useState(false);

  const currentAccount = useCurrentAccount();
  const { featureFlags } = currentAccount;
  const templatesMaintenance = featureFlags?.templatesMaintenance;
  const templateOwner = useUserData(savedReply.user);

  const handleDelete = (event) => {
    event.preventDefault();
    event.stopPropagation();
    deleteSavedReplyRequest(savedReply.id, null, {
      successCallback: async () => {
        setActiveSavedReply(undefined);

        const response = await fetchSavedReplyCollection(
          savedReplyCollectionId,
        );
        const newSavedReplyCollection = await response.json();
        setSavedReplies(newSavedReplyCollection);

        addNotification({
          message: "You successfully deleted a template.",
          options: {
            // eslint-disable-next-line react/no-unstable-nested-components
            action: (key) => {
              return <SnackbarCloseButton snackbarKey={key} />;
            },
          },
        });
      },
    });
  };

  const { content, title } = savedReply;

  return !templatesMaintenance || templateOwner ? (
    <ListItem
      data-testid="created-template"
      display="flex"
      padding="20px"
      className={`${isActive ? "isActive" : ""}`}
      onClick={() => {
        return clickHandler && clickHandler(savedReply);
      }}
      onMouseEnter={() => {
        return setHovering(true);
      }}
      onMouseLeave={() => {
        return setHovering(false);
      }}
      sx={{
        "&:hover": {
          background: (theme) => {
            return theme.palette.action.hover;
          },
          cursor: "pointer",
          p: {
            color: (theme) => {
              return theme.palette.text.primary;
            },
          },
        },
      }}
    >
      <Box maxWidth="320px" flex="1 1 auto" sx={{ overflowWrap: "break-word" }}>
        <Box display="flex">
          {templatesMaintenance ? (
            <UserAvatar
              subject={templateOwner}
              size={32}
              tooltip={templateOwner.name}
            />
          ) : null}
          <Typography
            data-testid="template-title"
            variant="body2"
            fontWeight={700}
            margin="0 0 0.5rem 0"
            color="text.primary"
            paddingLeft={templatesMaintenance ? 1 : 0}
            paddingTop={templatesMaintenance ? 0.5 : 0}
          >
            {title}
          </Typography>
        </Box>
        <Typography
          data-testid="template-content"
          component="p"
          variant="body2"
          color="text.primary"
          overflow="hidden"
          margin={0}
          padding={0}
          paddingTop={0}
          textOverflow="ellipsis"
          sx={{
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            display: "-webkit-box",
          }}
        >
          {content}
        </Typography>
      </Box>
      {savedReply.user === currentUser.id && (
        <Box
          display={hovering || isActive ? "flex" : "none"}
          flex="0 0 33px"
          alignItems="center"
          justifyContent="center"
          fontSize="14px"
        >
          <Tooltip title="Delete template">
            <IconButton data-testid="delete-template" onClick={handleDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </ListItem>
  ) : null;
}

Root.propTypes = {
  addNotification: PropTypes.func.isRequired, // withConnect
  clickHandler: PropTypes.func, // passed
  currentUser: PropTypes.object.isRequired, // withConnect
  deleteSavedReplyRequest: PropTypes.func.isRequired, // withRecord
  savedReplyCollectionId: PropTypes.string.isRequired, // passed
  isActive: PropTypes.bool, // passed
  savedReply: PropTypes.object.isRequired, // passed
  setActiveSavedReply: PropTypes.func.isRequired, // passed
  setSavedReplies: PropTypes.func.isRequired, // passed
};

/* eslint-disable @typescript-eslint/no-floating-promises */
import { Box, Button, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import _ from 'lodash';
import { updateIntegration } from "../../api/updateIntegration";
import { IntegrationDetailsFooterProps } from "./types";
import { createSnackbarContent } from "components/Snackbar";

export function IntegrationDetailsFooter({
  initialIntegrationValues,
  updatedIntegrationData,
}: IntegrationDetailsFooterProps) {
  const { enqueueSnackbar } = useSnackbar();

  const isSaveDisabled = _.isEqual(initialIntegrationValues, updatedIntegrationData);

  const handleSave = async () => {
    try {
      const body = {};
      if (updatedIntegrationData?.config) {
        body.config = updatedIntegrationData.config;
      }
      if (updatedIntegrationData?.settings) {
        body.settings = updatedIntegrationData.settings;
      }
      const response = await updateIntegration(
        updatedIntegrationData?.id ?? "",
        body,
      );
      if (!response.ok) {
        throw new Error("Cannot update integration");
      }
    } catch {
      enqueueSnackbar("Integration update failed.", {
        content: createSnackbarContent("error"),
      });
    }
  };

  return (
    <Box
      alignItems="center"
      bgcolor="background.paper"
      bottom="0"
      boxShadow="0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20)"
      display="flex"
      flexDirection="row-reverse"
      padding="16px"
      position="absolute"
      width="100%"
      zIndex={10}
    >
      <Button
        color="primary"
        disabled={isSaveDisabled}
        onClick={() => {
          handleSave();
        }}
        variant="contained"
      >
        Save
      </Button>
      <Typography
        sx={{ paddingRight: "8px" }}
        variant="caption"
        color="text.secondary"
      >
        Last updated at:
      </Typography>
    </Box>
  );
}

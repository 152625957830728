import { useState } from "react";
import { useDispatch } from "react-redux";

import { actionGenerators } from "../state/contactState";
import { convertToCsv, parseCsv } from "features/AccountSettings/utils";
import { useCurrentAccount, useNotification } from "hooks";
import SnackbarCloseButton from "containers/SnackbarCloseButton";

interface useContactUploadReturn {
  error: any;
  isUploading: boolean;
  signedUrl: string | null;
  parsedResults: any;
  processContacts: (file: File) => void;
  uploadContacts: (headers: string[], url: string) => void;
}

export const useContactUpload = (): useContactUploadReturn => {
  const [error, setError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [parsedResults, setParsedResults] = useState(null);
  const [signedUrl, setSignedUrl] = useState<string | null>(null);
  const account = useCurrentAccount();
  const { addNotification } = useNotification();
  const dispatch = useDispatch();

  const {
    // @ts-ignore
    uploadContactImportRequest,
    // @ts-ignore
    importContactImportRequest,
  } = actionGenerators;

  const parseFile = (csv: File) => {
    return parseCsv({
      csv,
      successCallback: (_parsedResults: any) => {
        setParsedResults(_parsedResults);
        setError(null);
      },
      errorCallback: (_error: any) => {
        setError(_error);
      },
    });
  };

  /*
   * At this point, the dropzone has accepted the file
   * and we need to parse the file before sending to S3
   */
  const processContacts = (file: File) => {
    const fileName = file.name.substr(0, file.name.lastIndexOf("."));
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event?.target?.result);
      const csvFile = convertToCsv({ data, fileName });
      parseFile(csvFile);
      uploadFile(csvFile);
    };
    reader.readAsArrayBuffer(file);
  };

  /*
   * The file has been parsed and is being uploaded to S3
   */
  const uploadFile = (file: File) => {
    setIsUploading(true);
    dispatch(
      uploadContactImportRequest(
        account.presignContactImport,
        {
          file,
        },
        {
          successCallback: (_signedUrl: string) => {
            setSignedUrl(_signedUrl);
          },
          errorCallback: (errorUploading: any) => {
            setError(errorUploading);
          },
          cleanup: () => {
            return setIsUploading(false);
          },
        },
      ),
    );
  };

  /*
   * The contacts are now finally being imported and/or created in our system
   */
  const uploadContacts = (headers: string[], url: string) => {
    dispatch(
      addNotification({
        message: `We're working on importing your contacts now. 
          We'll send you an email and notification when step 2 is ready.`,
        options: {
          action: (key: string) => {
            return <SnackbarCloseButton snackbarKey={key} />;
          },
        },
      }),
    );
    dispatch(
      importContactImportRequest(
        account.contactImport,
        {
          headers,
          url,
        },
        {
          successCallback: () => {
            setSignedUrl(null);
            setParsedResults(null);
          },
          errorCallback: (errorImporting: any) => {
            setSignedUrl(null);
            setError(errorImporting);
            setIsUploading(false);
            dispatch(
              addNotification({
                message: "Something went wrong! Please try again.",
                options: {
                  action: (key: string) => {
                    return <SnackbarCloseButton snackbarKey={key} />;
                  },
                  variant: "error",
                },
              }),
            );
          },
        },
      ),
    );
  };

  return {
    error,
    isUploading,
    processContacts,
    signedUrl,
    parsedResults,
    uploadContacts,
  };
};

import { useSnackbar } from "notistack";
import { DialogContent, Typography } from "@mui/material";
import { deleteIntegration } from "../../api/deleteIntegration";
import { RemoveIntegrationModalProps } from "./types";
import { DialogFooter } from "components/DialogFooter";
import { SimpleDialog } from "components/SimpleDialog";
import { createSnackbarContent } from "components/Snackbar";

export function RemoveIntegrationModal({
  open,
  updatedIntegrationData,
  setOpenRemoveIntegrationDialog,
  setSelectedIntegration,
}: RemoveIntegrationModalProps) {
  const { enqueueSnackbar } = useSnackbar();

  const handleRemoveIntegration = async () => {
    try {
      const response = await deleteIntegration(
        updatedIntegrationData?.id ?? "",
      );
      if (!response.ok) {
        throw new Error("Integration removal failed.");
      }
      // Navigate user back to integrations landing page
      setSelectedIntegration(null);
      enqueueSnackbar("Integration removed.", {
        content: createSnackbarContent("info"),
      });
    } catch {
      enqueueSnackbar("Integration removal failed.", {
        content: createSnackbarContent("error"),
      });
    }
  };

  return (
    <SimpleDialog
      handleClose={() => {
        setOpenRemoveIntegrationDialog(false);
      }}
      open={open}
      onClose={() => {
        setOpenRemoveIntegrationDialog(false);
      }}
      title="Remove integration?"
    >
      <DialogContent>
        <Typography variant="body1">
          This integration will be completely disconnected from TextUs and will
          require re-authentication.
        </Typography>
      </DialogContent>
      <DialogFooter
        confirmColor="error"
        confirmText="Remove"
        confirmVariant="text"
        onConfirm={handleRemoveIntegration}
        onCancel={() => {
          return setOpenRemoveIntegrationDialog(false);
        }}
      />
    </SimpleDialog>
  );
}

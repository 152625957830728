import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import get from "lodash/get";

import Table from "components/Table";
import TableWrapper from "features/Campaigns/components/TableWrapper";

const ContactName = styled.div`
  color: ${(props) => {
    return props.theme.colors.primary.main;
  }};
  font-weight: 700;
`;

const ContactNumber = styled.div`
  color: ${(props) => {
    return props.theme.colors.text.primary;
  }};
  font-size: 0.75rem;
`;

export default class Recipients extends Component {
  static propTypes = {
    recipients: PropTypes.array.isRequired,
  };

  getColumns = () => {
    return [
      {
        title: "Name",
        primary: true,
        getTableCellContent: (record) => {
          return record["@type"] === "CampaignRecipient"
            ? get(record, ["contactPhone", "contact", "name"])
            : record.name;
        },
      },
      {
        title: "Primary Number",
        getTableCellContent: (record) => {
          return record["@type"] === "CampaignRecipient"
            ? get(record, ["contactPhone", "formattedPhoneNumber"])
            : get(record, ["phones", "members", 0, "formattedPhoneNumber"]);
        },
        nowrap: true,
      },
      {
        title: "Business Name",
        getTableCellContent: (record) => {
          return record["@type"] === "CampaignRecipient"
            ? get(record, ["contactPhone", "contact", "data", "business"], "")
            : get(record, ["data", "business"], "");
        },
      },
    ];
  };

  getCollapsedColumns = () => {
    return [
      {
        title: "Contact",
        getTableCellContent: (record) => {
          const contactName =
            record["@type"] === "CampaignRecipient"
              ? get(record, ["contactPhone", "contact", "name"])
              : record.name;
          const contactNumber =
            record["@type"] === "CampaignRecipient"
              ? get(record, ["contactPhone", "formattedPhoneNumber"])
              : get(record, ["phones", "members", 0, "formattedPhoneNumber"]);
          return (
            <div>
              <ContactName>{contactName}</ContactName>
              {contactNumber && <ContactNumber>{contactNumber}</ContactNumber>}
            </div>
          );
        },
      },
    ];
  };

  render() {
    const { recipients } = this.props;
    const collection = { members: recipients, totalItems: recipients.length };
    return (
      <TableWrapper>
        <Table
          collapsedColumns={this.getCollapsedColumns()}
          columns={this.getColumns()}
          collection={collection}
          withoutPagination
        />
      </TableWrapper>
    );
  }
}

import { createRef, Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Formik, Field, Form } from "formik";
import debounce from "lodash/debounce";
import queryString from "query-string";
import styled from "styled-components";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MuiButton from "@mui/material/Button";
import Icon from "@mui/material/Icon";

import getAllowedLoginMethods from "../utils/getAllowedLoginMethods";
import getIsClockInaccurate from "../utils/getIsClockInaccurate";

import AzureButton from "./AzureButton";
import GoogleButton from "./GoogleButton";
import MobileNativeAuthForm from "./MobileNativeAuthForm";
import OktaForm from "./OktaForm";
import PingOneForm from "./PingOneForm";
import PasswordlessButton from "./PasswordlessButton";
import PasswordlessOverlay from "./PasswordlessOverlay";
import ResetPasswordOverlay from "./ResetPasswordOverlay";
import BullhornLogo from "utils/images/bullhorn-logo-notext.png";
import LoadingOverlay from "features/Authentication/components/LoadingOverlay";
import Button from "features/Authentication/components/PrimaryButton";
import Page from "features/Authentication/components/Page";
import P from "components/P";
import BasicButton from "components/BasicButton";
import localStorageHelper from "utils/localStorageHelper";
import SnackbarCloseButton from "containers/SnackbarCloseButton";
import { PasswordLoginSchema } from "formHelpers/validationSchemas";

const labeling = get(window, ["branding", "labeling"], {});

const separatorHeight = `45px`;

const Description = styled.p`
  color: ${(props) => {
    return props.theme.colors.primary.main;
  }};
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 30px;
`;

const Root = styled(Page)``;

const Separator = styled.div`
  height: ${separatorHeight};
  line-height: ${separatorHeight};
  padding: 0 15px;
  position: relative;
  text-align: center;
  width: 100%;
  span {
    background: ${(props) => {
      return props.theme.colors.background.paper;
    }};
    border-radius: 50%;
    font-size: 12px;
    font-weight: 700;
    padding: 0 10px;
    position: relative;
    z-index: 2;
  }
  &:after {
    content: " ";
    display: block;
    left: 0;
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: 1;
    border-bottom: ${(props) => {
      return props.theme.mixins.border({ color: props.theme.colors.divider });
    }};
  }
`;

const GoogleForm = styled.form``;
const AzureForm = styled.form`
  ${P} {
    margin: 15px 0;
  }
  [role="img"] {
    font-size: 18px !important;
  }
`;

const ResetPasswordButton = styled(BasicButton)`
  font-size: 12px;
  color: ${labeling?.primary_color};
  height: 30px;
  margin: 12px 0 12px auto;
  &:hover {
    color: ${labeling?.secondary_color};
  }
`;

const MainContent = styled.div`
  transition-delay: ${(props) => {
    return props.isOverlayActive ? 0 : 0.4;
  }}s;
  transition: all ease
    ${(props) => {
      return props.isOverlayActive ? 0 : 0.4;
    }}s;
  width: 100%;
  ${(props) => {
    return props.isOverlayActive
      ? props.theme.mixins.fadeHide
      : props.theme.mixins.fadeShow;
  }};
`;

const BullhornButton = styled(MuiButton)(({ theme }) => {
  return {
    display: "flex",
    marginTop: 10,
    height: 39,
    fontSize: 11,
    fontWeight: 700,
    padding: 0,
    elevation: 0,
    backgroundColor: "#212A45",
    "&:hover": {
      backgroundColor: "#009ADE",
    },
    p: 0,
    "& .MuiButton-startIcon": {
      position: "absolute",
      left: "17px",
    },
  };
});

function snackBarCloseButton(key) {
  return <SnackbarCloseButton snackbarKey={key} />;
}

function isProviderAllowed(methods, provider) {
  return methods.some((method) => {
    return method.provider === provider;
  });
}

export default class Main extends Component {
  static propTypes = {
    addNotification: PropTypes.func.isRequired, // withConnect
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    performLoginRequest: PropTypes.func.isRequired,
    requestResetPassword: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.AzureForm = createRef();

    this.state = {
      showNuAuthPasswordlessOverlay: false,
      showResetPasswordOverlay: false,
      allowedLoginMethods: [],
      isClockInaccurate: getIsClockInaccurate(),
      email: "",
    };

    this.debouncedSetLoginMethods = debounce(this.setLoginMethods, 300);
  }

  componentDidMount = () => {
    const { addNotification } = this.props;
    const { isClockInaccurate } = this.state;
    if (isClockInaccurate) {
      addNotification({
        message:
          "Your computer's clock is inaccurate and may prevent successful login. Please adjust your clock and refresh to try again.",
        options: {
          persist: true,
          variant: "error",
        },
      });
    }
  };

  handleAzureFormSubmit = () => {
    this.AzureForm.current.submit();
  };

  handleNuAuthSubmit = ({ email, password }, actions) => {
    const loginParams = {
      email,
      password,
      errorCallback: (error) => {
        const errorResponse = get(error, "response", {});
        this.handleNuAuthError({ actions, errorResponse });
      },
    };

    this.props.performLoginRequest(loginParams);
  };

  handleNuAuthError = ({ actions, errorResponse }) => {
    const failedLoginCount =
      localStorageHelper.getItem("failedLoginCount", {
        fallback: 0,
      }) + 1;
    const baseMessage =
      errorResponse.detail || "There was a problem with your credentials";
    const loginAttempts = get(
      errorResponse,
      ["validationErrors", "loginAttempts", "attempts"],
      failedLoginCount,
    );
    localStorageHelper.setItem({
      item: "failedLoginCount",
      value: loginAttempts,
    });
    const { location, history } = this.props;
    const queryParams = queryString.parse(location.search);
    const updatedQueryParams = queryString.stringify({
      ...queryParams,
      failed_logins: loginAttempts,
    });
    const updatedLocation = {
      pathname: "/login",
      search: updatedQueryParams,
      state: location.state,
    };
    history.push(updatedLocation);
    this.props.addNotification({
      message: `${baseMessage}. Please try again`,
      options: {
        action: (key) => {
          return snackBarCloseButton(key);
        },
        variant: "error",
      },
    });
    actions.setSubmitting(false);
    actions.resetForm();
  };

  handlePasswordlessSubmit = ({ email }, actions) => {
    this.handleNuAuthSubmit({ email }, actions);
  };

  handleResetPasswordSubmit = ({ email }, actions) => {
    this.props.requestResetPassword({ email }, actions);
  };

  renderNuAuthForm = () => {
    const { allowedLoginMethods, isClockInaccurate } = this.state;
    const azureLogin = isProviderAllowed(allowedLoginMethods, "azure_oauth2");
    const oktaLogin = isProviderAllowed(allowedLoginMethods, "okta");
    const pingOneLogin = isProviderAllowed(allowedLoginMethods, "ping_one");
    return (
      <>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={PasswordLoginSchema}
          onSubmit={this.handleNuAuthSubmit}
        >
          {({ errors, touched, isSubmitting }) => {
            return (
              <Form>
                {isSubmitting && <LoadingOverlay />}
                <Field name="email">
                  {({ field, form }) => {
                    return (
                      <TextField
                        {...field}
                        id="email"
                        type="email"
                        error={touched.email && Boolean(errors.email)}
                        fullWidth
                        label="Email"
                        autoComplete="email"
                        onChange={({ target: { value } }) => {
                          form.setFieldValue(field.name, value);
                          this.updateAllowedLoginMethods(value);
                        }}
                        placeholder="Please enter your email"
                        helperText={touched.email ? errors.email || "" : ""}
                        variant="standard"
                      />
                    );
                  }}
                </Field>
                {!azureLogin && !oktaLogin && !pingOneLogin && (
                  <>
                    <Field name="password">
                      {({ field }) => {
                        return (
                          <TextField
                            {...field}
                            id="password"
                            type="password"
                            error={touched.password && Boolean(errors.password)}
                            fullWidth
                            label="Password"
                            autoComplete="current-password"
                            helperText={
                              touched.password ? errors.password || "" : ""
                            }
                            variant="standard"
                          />
                        );
                      }}
                    </Field>
                    {this.renderResetPasswordButton()}

                    <Button
                      // This aria-label cannot be changed
                      // Correlates to src/scripts/tab/login/getPagesTextus.ts in tesseract-sidepanel
                      aria-label="Log In"
                      data-testid="sign-in-button"
                      type="submit"
                      disabled={isSubmitting || isClockInaccurate}
                    >
                      Sign in
                    </Button>
                  </>
                )}
              </Form>
            );
          }}
        </Formik>
        {this.renderFormFooter()}
      </>
    );
  };

  updateAllowedLoginMethods = (value) => {
    const email = value.trim();
    return this.setState({ email }, () => {
      this.debouncedSetLoginMethods(email);
    });
  };

  setLoginMethods = async (email) => {
    const methods = await getAllowedLoginMethods(email);
    this.setState({ allowedLoginMethods: methods });
  };

  renderResetPasswordButton = () => {
    return (
      <ResetPasswordButton
        aria-label="Reset Password Button"
        data-testid="reset-password-button"
        type="button"
        onClick={() => {
          return this.setState({ showResetPasswordOverlay: true });
        }}
        disabled={this.state.isClockInaccurate}
      >
        Forgot your password?
      </ResetPasswordButton>
    );
  };

  renderFormFooter = () => {
    const { allowedLoginMethods, isClockInaccurate, email } = this.state;
    const azureLogin = isProviderAllowed(allowedLoginMethods, "azure_oauth2");
    const oktaLogin = isProviderAllowed(allowedLoginMethods, "okta");
    const pingOneLogin = isProviderAllowed(allowedLoginMethods, "ping_one");
    const emailDomain = email.split("@")[1];
    const authToken = document.head
      .querySelector("[name=csrf-token]")
      .getAttribute("content");
    if (azureLogin) {
      return this.renderAzureForm();
    }
    if (oktaLogin) {
      const { domain } =
        allowedLoginMethods.find((method) => {
          return method.provider === "okta";
        }) || {};
      return (
        <OktaForm
          emailDomain={emailDomain}
          domain={domain}
          isClockInaccurate={isClockInaccurate}
          authToken={authToken}
        />
      );
    }
    if (pingOneLogin) {
      const { domain } =
        allowedLoginMethods.find((method) => {
          return method.provider === "ping_one";
        }) || {};
      return (
        <PingOneForm
          emailDomain={emailDomain}
          domain={domain}
          isClockInaccurate={isClockInaccurate}
          authToken={authToken}
        />
      );
    }
    return (
      <>
        <Separator>
          <span>or</span>
        </Separator>
        <PasswordlessButton
          onClick={() => {
            return this.setState({ showNuAuthPasswordlessOverlay: true });
          }}
          disabled={isClockInaccurate}
        />
        {this.renderGoogleForm()}
        {this.renderBullhornForm()}
      </>
    );
  };

  renderAzureForm = () => {
    const inExtension = window.self !== window.top;
    const webAppLoginButton = (
      <MuiButton
        color="primary"
        href={document.location.href}
        size="large"
        target="_blank"
        variant="contained"
      >
        Go to Web App
      </MuiButton>
    );

    const authenticityToken = document.head
      .querySelector("[name=csrf-token]")
      .getAttribute("content");
    return inExtension ? (
      <AzureForm>
        <Box mt={1} mb={2}>
          To continue with Azure please sign in through the web app, then close
          and reopen the extension.
        </Box>
        {webAppLoginButton}
      </AzureForm>
    ) : (
      <AzureForm
        ref={this.AzureForm}
        name="azure_auth"
        method="post"
        action="/auth/azure_activedirectory_v2"
      >
        <P>Your administrator requires you to use Azure to login</P>
        <AzureButton
          type="button"
          onClick={this.handleAzureFormSubmit}
          disabled={inExtension}
        />
        <input
          type="hidden"
          name="authenticity_token"
          value={authenticityToken}
        />
      </AzureForm>
    );
  };

  inNativeMobileAuth = () => {
    const { location } = this.props;
    const queryParams = queryString.parse(location.search);

    return !!queryParams.mobileNativeAuth;
  };

  renderGoogleForm = () => {
    const { isClockInaccurate } = this.state;
    const isSidepanel = window.top !== window.self;
    const authenticityToken = document.head
      .querySelector("[name=csrf-token]")
      .getAttribute("content");
    return (
      <GoogleForm
        target={isSidepanel ? "_blank" : "_self"}
        name="google_auth"
        method={isSidepanel ? "get" : "post"}
        action={isSidepanel ? "/" : "/auth/google_oauth2"}
        aria-label="Google Sign in Form"
      >
        <GoogleButton
          aria-label="Log In with Google"
          data-testid="google-sign-in-button"
          type="submit"
          disabled={isClockInaccurate}
        />
        <input
          type="hidden"
          name="authenticity_token"
          value={authenticityToken}
        />
      </GoogleForm>
    );
  };

  // eslint-disable-next-line class-methods-use-this
  renderBullhornForm = () => {
    const isSidepanel = window.top !== window.self;
    const authenticityToken = document.head
      .querySelector("[name=csrf-token]")
      .getAttribute("content");

    return (
      (window.location.origin === "https://next.textus.engineering" ||
        window.location.origin === "https://texting.bullhorn.com") && (
        <form
          target={isSidepanel ? "_blank" : "_self"}
          name="bullhorn auth"
          method={isSidepanel ? "get" : "post"}
          action={isSidepanel ? "/" : "/auth/bullhorn"}
          aria-label="Bullhorn Sign in Form"
        >
          <input
            type="hidden"
            name="authenticity_token"
            value={authenticityToken}
          />
          <BullhornButton
            aria-label="Bullhorn Sign in Button"
            data-testid="BullhornLoginStarted"
            type="submit"
            variant="contained"
            startIcon={
              <Icon>
                <img
                  alt="Bullhorn Logo"
                  src={BullhornLogo}
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    height: "100%",
                  }}
                />
              </Icon>
            }
          >
            Sign in With Bullhorn
          </BullhornButton>
        </form>
      )
    );
  };

  renderNativeMobileAuth = () => {
    return (
      <>
        <MobileNativeAuthForm
          onEmailChange={this.updateAllowedLoginMethods}
          hidePassword
          renderResetPassword={this.renderResetPasswordButton}
          addNotification={this.props.addNotification}
        />
        {this.renderFormFooter()}
      </>
    );
  };

  renderAuthForm = () => {
    if (this.inNativeMobileAuth()) return this.renderNativeMobileAuth();

    return this.renderNuAuthForm();
  };

  render() {
    const { showNuAuthPasswordlessOverlay, showResetPasswordOverlay } =
      this.state;

    return (
      <Root>
        <PasswordlessOverlay
          isActive={showNuAuthPasswordlessOverlay}
          onSubmit={this.handlePasswordlessSubmit}
          close={() => {
            return this.setState({ showNuAuthPasswordlessOverlay: false });
          }}
        />
        <ResetPasswordOverlay
          isActive={showResetPasswordOverlay}
          onSubmit={this.handleResetPasswordSubmit}
          close={() => {
            return this.setState({ showResetPasswordOverlay: false });
          }}
        />

        <MainContent
          isOverlayActive={
            showNuAuthPasswordlessOverlay || showResetPasswordOverlay
          }
        >
          {this.renderAuthForm()}
        </MainContent>
      </Root>
    );
  }
}

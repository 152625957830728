import { actionGenerators } from "features/EntryPoint/containers/Notifications/state";

// This will/should eventually do more.
// Not really sure why the notifications need to go through the store right now?
// The idea for this hook is to allow any component to trigger a notification without going through the store.
// Can also return a list of current notifications if need be
// As of the first implementation, it simply acts as a means to access the notification generators
export const useNotification = () => {
  const { addNotification, removeNotification } = actionGenerators;

  return { addNotification, removeNotification };
};

import { stringify } from "query-string";
import { merge } from "lodash";
import { Interceptor } from "./types";

/**
 * A service for making requests to a remote resource.
 */
export class Service {
  /**
   * The base URL for the service.
   */
  public url?: string;

  /**
   * The default options for the service.
   */
  public options?: RequestInit;

  /**
   * Request interceptors.
   */
  protected interceptors: Interceptor[] = [];

  /**
   * Create a new service.
   * @param url The base URL for the service.
   * @param options The default options for the service.
   */
  constructor({ url, options }: { url?: string; options?: RequestInit }) {
    this.url = url;
    this.options = options;
  }

  /**
   * Fetch a resource from the service.
   * @param path The path to the resource.
   * @param options The options for the request.
   * @returns The response.
   */
  public async fetch(path: string, options?: RequestInit): Promise<Response> {
    let current: readonly [url: string, options?: RequestInit] = [
      (this.url ?? "") + path,
      merge({}, this.options, options),
    ];

    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < this.interceptors.length; index++) {
      // eslint-disable-next-line no-await-in-loop
      current = await this.interceptors[index](...current);
    }

    return fetch(...current);
  }

  /**
   * Perform a GET request.
   * @param url The URL to request.
   * @param query The query parameters for the request.
   * @returns The response.
   */
  public async get(path: string, query?: object, options?: RequestInit) {
    return this.fetch(
      `${path}${query ? `?${stringify(query)}` : ""}`,
      merge(
        {
          method: "GET",
        },
        options,
      ),
    );
  }

  /**
   * Perform a PATCH request.
   * @param url The URL to request.
   * @param body The body for the request.
   * @returns The response.
   */
  public async patch(url: string, body?: object, options?: RequestInit) {
    return this.fetch(
      url,
      merge(
        {
          method: "PATCH",
          body: JSON.stringify(body),
        },
        options,
      ),
    );
  }

  /**
   * Perform a POST request.
   * @param url The URL to request.
   * @param body The body for the request.
   * @returns The response.
   */
  public async post(url: string, body?: object, options?: RequestInit) {
    return this.fetch(
      url,
      merge(
        {
          method: "POST",
          body: JSON.stringify(body),
        },
        options,
      ),
    );
  }

  /**
   * Perform a PUT request.
   * @param url The URL to request.
   * @param body The body for the request.
   * @returns The response.
   */
  public async put(url: string, body?: object, options?: RequestInit) {
    return this.fetch(
      url,
      merge(
        {
          method: "PUT",
          body: JSON.stringify(body),
        },
        options,
      ),
    );
  }

  /**
   * Perform a DELETE request.
   * @param url The URL to request.
   * @returns The response.
   */
  public async destroy(url: string, options?: RequestInit) {
    return this.fetch(
      url,
      merge(
        {
          method: "DELETE",
        },
        options,
      ),
    );
  }

  /**
   * Add an interceptor to the service.
   * @param interceptor The interceptor to add.
   * @returns The service.
   */
  public intercept(interceptor: Interceptor) {
    this.interceptors.push(interceptor);

    return this;
  }
}

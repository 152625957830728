import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { SizeMe } from "react-sizeme";

import { reducer, selectors, actionGenerators, saga } from "./state";
import Root from "./components/Root";
import { selectCurrentAccount } from "features/EntryPoint/containers/App/selectors";
import injectReducer from "utils/injectReducer";
import injectSaga from "utils/injectSaga";
import { actionGenerators as notificationsActionGenerators } from "features/EntryPoint/containers/Notifications/state";

class QuickComposer extends Component {
  static propTypes = {
    recipientIds: PropTypes.array,
    recipients: PropTypes.array.isRequired,
    setRecipients: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { recipientIds, setRecipients } = this.props;
    if (recipientIds) setRecipients(recipientIds);
  }

  render() {
    const { recipientIds, recipients } = this.props;
    if (recipientIds && !recipients) return null;
    return (
      <SizeMe monitorHeight>
        {({ size: { height } }) => {
          return <Root {...this.props} isShort={height < 400} />;
        }}
      </SizeMe>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentAccount: selectCurrentAccount(state, props),
    recipients: selectors.selectRecipients(state, props),
    searchResults: selectors.selectSearchResults(state, props),
    quickComposerContainer: selectors.selectQuickComposer(state, props),
    conversationPreviewContainer: selectors.selectConversationPreview(
      state,
      props,
    ),
  };
};

const withReducer = injectReducer({ key: "quickComposerContainer", reducer });
const withSaga = injectSaga({ key: "quickComposerContainer", saga });

const withConnect = connect(mapStateToProps, {
  ...notificationsActionGenerators,
  ...actionGenerators,
});

export default compose(withReducer, withSaga, withConnect)(QuickComposer);

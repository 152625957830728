import { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import SnackbarCloseButton from "containers/SnackbarCloseButton";

function withProfileSubmit(WrappedComponent) {
  class WithProfileSubmit extends Component {
    static propTypes = {
      currentUser: PropTypes.object.isRequired,
      addNotification: PropTypes.func,
      updateUserRequest: PropTypes.func.isRequired,
    };

    errorCallback = (actions) => {
      return (errors) => {
        const { addNotification } = this.props;
        const validationErrors = get(
          errors,
          ["validationErrors"],
          "Something went wrong!",
        );
        actions.setSubmitting(false);
        actions.setErrors(validationErrors);
        if (addNotification) {
          addNotification({
            message: "There was an error saving your profile",
            options: {
              action: (key) => {
                return <SnackbarCloseButton snackbarKey={key} />;
              },
              variant: "error",
            },
          });
        }
      };
    };

    successCallback = (actions) => {
      return () => {
        const { addNotification } = this.props;
        actions.setSubmitting(false);
        if (addNotification) {
          addNotification({
            message: "Profile saved successfully!",
            options: {
              action: (key) => {
                return <SnackbarCloseButton snackbarKey={key} />;
              },
            },
          });
        }
      };
    };

    handleSubmit = (values, actions) => {
      const { currentUser, updateUserRequest } = this.props;
      updateUserRequest(currentUser.id, values, {
        successCallback: this.successCallback(actions),
        errorCallback: this.errorCallback(actions),
      });
    };

    render() {
      return (
        <WrappedComponent {...this.props} handleSubmit={this.handleSubmit} />
      );
    }
  }

  return WithProfileSubmit;
}

export default withProfileSubmit;

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import { Component } from "react";
import { NewMessage } from "./NewMessage";
import { PassedProps, State } from "./types";
import { actionGenerators as typingIndicatorsActionGenerators } from "features/EntryPoint/containers/TypingIndicators/state";
import {
  selectCurrentAccount,
  selectCurrentUser,
  selectUserCanHover,
} from "features/EntryPoint/containers/App/selectors";
import { selectors as dragListenerSelectors } from "features/EntryPoint/containers/DragListener/state";
import { actionGenerators as notificationsActionGenerators } from "features/EntryPoint/containers/Notifications/state";
import withRecord from "higherOrderComponents/withRecord";

const mapStateToProps = (state: unknown) => {
  return {
    currentAccount: selectCurrentAccount(state),
    currentUser: selectCurrentUser(state),
    isDragging: dragListenerSelectors.selectIsDragging(state),
    userCanHover: selectUserCanHover(state),
  };
};

const withConnect = connect(
  () => {
    return mapStateToProps;
  },
  {
    ...typingIndicatorsActionGenerators,
    ...notificationsActionGenerators,
  },
);

export default compose(
  withRecord({
    actions: ["create"],
    container: "NewMessage",
    type: "message",
  }),
  withConnect,
  withRouter,
)(NewMessage) as typeof Component<PassedProps, State>;

import { useMemo, useState } from "react";

import { useSnackbar } from "notistack";
import { isEqual } from "lodash";
import getAccountPlanValues from "../utils/getAccountPlanValues";

import { AccountPlan, useAccountPlansProps } from "../models/AccountPlanModels";

const useAccountPlans = ({
  accountPlans,
  accountListMembers = [],
  addNotification = () => {
    // comment needed to satisfy eslint no-empty-function
  },
  updateAccountPlanRequest = () => {
    // comment needed to satisfy eslint no-empty-function
  },
}: useAccountPlansProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [openAssignmentModal, setOpenAssignmentModal] = useState(false);
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);

  const toggleAssignmentModal = (): void => {
    return setOpenAssignmentModal(!openAssignmentModal);
  };

  const campaignsPro = useMemo(() => {
    return (
      accountPlans.find(({ type }) => {
        return type === "campaigns";
      }) ?? ({} as AccountPlan)
    );
  }, [accountPlans]);

  const keywords = useMemo(() => {
    return (
      accountPlans.find(({ type }) => {
        return type === "keywords";
      }) ?? ({} as AccountPlan)
    );
  }, [accountPlans]);

  const sequences = useMemo(() => {
    return (
      accountPlans.find(({ type }) => {
        return type === "sequences";
      }) ?? ({} as AccountPlan)
    );
  }, [accountPlans]);

  const formattedAccountPlans = accountPlans
    .filter(({ type }) => {
      const isCampaignsPro = type === "campaigns";
      const isKeywords = type === "keywords";
      const isSequences = type === "sequences";
      return isCampaignsPro || isKeywords || isSequences;
    })
    .map((accountPlan) => {
      return getAccountPlanValues(accountPlan);
    });

  const successCallback = () => {
    enqueueSnackbar("Successfully confirmed license(s).", {
      variant: "success",
    });
  };

  const errorCallback = () => {
    enqueueSnackbar(
      "You have selected more accounts than you have licenses available. Try again.",
      {
        variant: "error",
      },
    );
  };

  const assignLicenses = async ({
    assignKeywords,
    assignCampaignsPro,
    assignSequences,
  }: {
    assignKeywords: boolean;
    assignCampaignsPro: boolean;
    assignSequences: boolean;
  }) => {
    const { id: campaignsProId, assignments: campaignsProAssignments = [] } =
      campaignsPro ?? {};
    const { id: keywordsId, assignments: keywordsAssignments = [] } =
      keywords ?? {};
    const { id: sequencesId, assignments: sequencesAssignments = [] } =
      sequences ?? {};

    let newKeywordsAssignments = [...keywordsAssignments];
    let newCampaignsProAssignments = [...campaignsProAssignments];
    let newSequenceAssignments = [...sequencesAssignments];

    // Remove keyword licenses if false.
    if (!assignKeywords) {
      selectedAccounts.forEach((account) => {
        newKeywordsAssignments = newKeywordsAssignments.filter((c) => {
          return c.account !== account;
        });
      });
    }
    // Add keyword licenses if true.
    else {
      // Get all accounts as strings in order to remove duplicates.
      const currentAccounts = keywordsAssignments.map((k) => {
        return k.account;
      });

      const allAccounts = Array.from(
        new Set([...selectedAccounts, ...currentAccounts]),
      );

      newKeywordsAssignments = allAccounts.map((account) => {
        return {
          account: account as string,
          quantity: 1,
        };
      });
    }

    // Remove campaignsPro licenses if false.
    if (assignCampaignsPro === false) {
      selectedAccounts.forEach((account) => {
        newCampaignsProAssignments = newCampaignsProAssignments.filter((c) => {
          return c.account !== account;
        });
      });
    }

    // Add campaignsPro licenses if true.
    else {
      // Get all accounts as strings in order to remove duplicates.
      const currentAccounts = campaignsProAssignments.map((c) => {
        return c.account;
      });

      const allAccounts = Array.from(
        new Set([...selectedAccounts, ...currentAccounts]),
      );

      newCampaignsProAssignments = allAccounts.map((account) => {
        return {
          account: account as string,
          quantity: 1,
        };
      });
    }

    // Remove sequences licenses if false.
    if (assignSequences === false) {
      selectedAccounts.forEach((account) => {
        newSequenceAssignments = newSequenceAssignments.filter((c) => {
          return c.account !== account;
        });
      });
    }

    // Add sequences licenses if true.
    else {
      // Get all accounts as strings in order to remove duplicates.
      const currentAccounts = sequencesAssignments.map((c) => {
        return c.account;
      });

      const allAccounts = Array.from(
        new Set([...selectedAccounts, ...currentAccounts]),
      );

      newSequenceAssignments = allAccounts.map((account) => {
        return {
          account: account as string,
          quantity: 1,
        };
      });
    }

    // Do a client-side check to ensure we're not trying to assign
    // more licenses than we have available.
    if (
      newKeywordsAssignments.length > keywords.quantity ||
      newCampaignsProAssignments.length > campaignsPro.quantity ||
      newSequenceAssignments.length > sequences.quantity
    ) {
      errorCallback();
      setOpenAssignmentModal(false);
      return;
    }

    // Create an array of configuration objects
    const updates = [
      {
        id: keywordsId,
        assignments: newKeywordsAssignments,
        prevAssignments: keywordsAssignments,
      },
      {
        id: campaignsProId,
        assignments: newCampaignsProAssignments,
        prevAssignments: campaignsProAssignments,
      },
      {
        id: sequencesId,
        assignments: newSequenceAssignments,
        prevAssignments: sequencesAssignments,
      },
    ];

    // Filter out any configurations without an id and map them to their update promises
    const updatePromises = updates
      .filter((update) => {
        return (
          // Only consider items with an id and with changed assignments values
          update.id && !isEqual(update.assignments, update.prevAssignments)
        );
      })
      .map((update) => {
        return new Promise((resolve, reject) => {
          updateAccountPlanRequest(
            update.id,
            { assignments: [...update.assignments] },
            {
              successCallback: resolve, // On success, resolve the promise
              errorCallback: reject, // On error, reject the promise
            },
          );
        });
      });

    // Wait for all updates to complete
    Promise.all(updatePromises)
      .then(() => {
        return successCallback(); // All updates succeeded
      })
      .catch(() => {
        return errorCallback(); // At least one update failed
      });

    setSelectedAccounts([]);
    setOpenAssignmentModal(false);
  };

  const getAccount = (accountId: string) => {
    return accountListMembers.find((member) => {
      return member.slug === accountId;
    });
  };

  return {
    assignLicenses,
    campaignsPro,
    formattedAccountPlans,
    keywords,
    openAssignmentModal,
    selectedAccounts,
    sequences,
    getAccount,
    setSelectedAccounts,
    setOpenAssignmentModal,
    toggleAssignmentModal,
  };
};

export { useAccountPlans };

import { Box, Button, Divider } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useSnackbar } from "notistack";
import { format } from "date-fns";
import { useCumulativeBillingAnalytics } from "../../hooks/useCumulativeBillingAnalytics";
import { useAccountUsageTable } from "../../hooks/useAccountUsageTable";
import { AccountUsageTable } from "../../components/AccountUsageTable/AccountUsageTable";
import { CumulativeBillingAnalyticsProps } from "./types";
import PageContent from "components/Page/PageContent";
import PageHeader from "components/Page/PageHeader";
import { CumulativeBillingOverview } from "features/AccountAnalytics/components/CumulativeBillingOverview";
import CumulativeBillingSkeleton from "features/AccountAnalytics/components/CumulativeBillingSkeleton";
import { exportBillingAnalytics } from "features/AccountAnalytics/api/exportBillingAnalytics";
import { createSnackbarContent } from "components/Snackbar";

function CumulativeBillingAnalytics({
  currentAccount,
  currentUser,
  isSinglePanel,
  scheduledReports,
  setScheduledReports,
  showSidebar,
  toggleSidebar,
}: CumulativeBillingAnalyticsProps) {
  const {
    analyticsData,
    asOfDate,
    contractTerms,
    getBillingAnalytics,
    handleSelectContractTerm,
    hasOverage,
    selectedContractTerm,
    setAsOfDate,
    isLoadingAnalytics,
  } = useCumulativeBillingAnalytics(currentAccount);

  const {
    accountUsageData,
    handleSelectTimePeriod,
    isLoadingAccountUsage,
    paginationData,
    pageUrl,
    selectedTimePeriod,
    setAccountUsageData,
    setPaginationData,
    timePeriodOptions,
    sendReport,
    paginationModel,
    setPaginationModel,
  } = useAccountUsageTable(currentAccount, selectedContractTerm);

  const { enqueueSnackbar } = useSnackbar();

  /*
  Handles the data export request for both the overview and account usage data
  */
  const handleDataExport = async () => {
    try {
      const bodyParams = {
        asOfDate: format(new Date(asOfDate), "y-MM-dd"),
        accountUsageDate:
          selectedTimePeriod === "Term to date"
            ? format(new Date(), "y-MM")
            : format(new Date(selectedTimePeriod), "y-MM"),
        contractTermStartDate: selectedContractTerm.startDate,
        reports: ["CUMULATIVE_ACCOUNT_USAGE", "CUMULATIVE_OVERVIEW_STATISTICS"],
      };
      const response = await exportBillingAnalytics(
        currentAccount.slug,
        bodyParams,
      );
      if (response.ok) {
        enqueueSnackbar(
          `Data exported successfully! Your report will be emailed to you shortly.`,
          {
            content: createSnackbarContent("info"),
          },
        );
      }
    } catch (error) {
      enqueueSnackbar(`Failed to export overarching data: Error:${error}`, {
        content: createSnackbarContent("error"),
      });
    }
  };

  return (
    <PageContent isSinglePanel={isSinglePanel} showSidebar={showSidebar}>
      {isLoadingAnalytics ? (
        <CumulativeBillingSkeleton
          hasOverage={hasOverage()}
          toggleSidebar={toggleSidebar}
        />
      ) : (
        <Box display="flex" flexDirection="column" height="100%">
          <PageHeader
            aria-label="Billing Analytics"
            title="Billing"
            toggleSidebar={toggleSidebar}
          >
            <Box flex="0 0 auto" mr={2}>
              <Button
                aria-label="Export overview and account usage data"
                color="primary"
                onClick={handleDataExport}
                variant="contained"
              >
                Export Data
                <Box paddingLeft="5px">
                  <CloudDownloadIcon />
                </Box>
              </Button>
            </Box>
          </PageHeader>
          <Box
            overflow="scroll"
            display="flex"
            flexDirection="column"
            height="100%"
            width="100%"
            sx={{
              pr: {
                xl: "264px",
                lg: "79px",
                md: "32px",
                sm: "16px",
                xs: "16px",
              },
              pl: {
                xl: "264px",
                lg: "79px",
                md: "32px",
                sm: "16px",
                xs: "16px",
              },
            }}
          >
            <CumulativeBillingOverview
              asOfDate={asOfDate}
              analyticsData={analyticsData}
              contractTerms={contractTerms}
              getBillingAnalytics={getBillingAnalytics}
              handleSelectContractTerm={handleSelectContractTerm}
              hasOverage={hasOverage}
              selectedContractTerm={selectedContractTerm}
              setAsOfDate={setAsOfDate}
            />
            <Divider
              sx={{
                marginTop: "16px",
              }}
            />
            <Box aria-label="Account Analytics" height="70%">
              <AccountUsageTable
                currentAccount={currentAccount}
                currentUser={currentUser}
                data={accountUsageData}
                filterOptions={timePeriodOptions}
                filterSelectHandler={handleSelectTimePeriod}
                isLoadingAccountUsage={isLoadingAccountUsage}
                paginationData={paginationData}
                paginationModel={paginationModel}
                pageUrl={pageUrl}
                scheduledReports={scheduledReports}
                selectedFilterOption={selectedTimePeriod}
                setAccountUsageData={setAccountUsageData}
                setPaginationData={setPaginationData}
                setScheduledReports={setScheduledReports}
                sendReport={sendReport}
                setPaginationModel={setPaginationModel}
              />
            </Box>
          </Box>
        </Box>
      )}
    </PageContent>
  );
}

export { CumulativeBillingAnalytics };

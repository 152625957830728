import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";

import Tooltip from "@mui/material/Tooltip";
import InfoIconOutlined from "@mui/icons-material/InfoOutlined";
import { CampaignHeader } from "./CampaignHeader";

const styles = { margin: ".25rem .5rem" };

function CampaignType({
  campaignType,
  setCampaignType,
  recurringDisabled = false,
}) {
  return (
    <Box
      display="flex"
      flex="1 1 auto"
      alignItems="center"
      flexDirection="column"
      minHeight="0"
      p="0rem"
      width="100%"
    >
      <CampaignHeader title="Choose campaign type" />

      <FormControl
        component="fieldset"
        style={{
          fontSize: "1.5rem",
          margin: "0rem",
          padding: "0rem",
          display: "flex",
          alignItems: "flex-start",
        }}
        variant="standard"
      >
        <RadioGroup
          aria-label="Campaign Type"
          name="campaignType"
          value={campaignType}
          onChange={({ target: { value } }) => {
            return setCampaignType(value);
          }}
        >
          <FormControlLabel
            aria-label="Single Campaign"
            value="single"
            control={
              <Radio
                color="secondary"
                data-testid="single-campaign-radio-button"
              />
            }
            label={
              <>
                One-time campaign
                <Tooltip
                  placement="right"
                  title="Send a campaign once to your chosen recipients."
                >
                  <InfoIconOutlined
                    sx={(theme) => {
                      return {
                        color: theme.palette.text.secondary,
                        margin: ".25rem .5rem",
                      };
                    }}
                  />
                </Tooltip>
              </>
            }
          />
          <FormControlLabel
            aria-label="Recurring Campaign"
            value="recurring"
            control={
              <Radio
                color="secondary"
                disabled={recurringDisabled}
                data-testid="recurring-campaign-radio-button"
              />
            }
            label={
              <>
                Recurring campaign
                <Tooltip
                  placement="right"
                  title="Create a campaign to send to a group on a set cadence."
                >
                  <InfoIconOutlined
                    sx={(theme) => {
                      return {
                        color: theme.palette.text.secondary,
                        margin: ".25rem .5rem",
                      };
                    }}
                  />
                </Tooltip>
              </>
            }
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
}

CampaignType.propTypes = {
  campaignType: PropTypes.string.isRequired,
  recurringDisabled: PropTypes.bool,
  setCampaignType: PropTypes.func.isRequired,
};
export default CampaignType;

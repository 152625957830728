import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { actionGenerators as notificationsActionGenerators } from "features/EntryPoint/containers/Notifications/state";
import { selectCurrentAccount } from "features/EntryPoint/containers/App/selectors";
import withRecord from "higherOrderComponents/withRecord";

import Root from "features/AccountSettings/containers/OptOutExport/components/Root";

class OptOutExport extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentAccount: selectCurrentAccount(state, props),
  };
};

const withConnect = connect(mapStateToProps, notificationsActionGenerators);

export default compose(
  withRecord({
    actions: ["export"],
    container: "containers/OptOutExport",
    type: "optOuts",
  }),
  withConnect,
)(OptOutExport);

export const getFromInbox = (contactCollectionId?: string) => {
  return contactCollectionId?.includes("/conversations");
};

export function extractKeywordGroupSlug(slug?: string) {
  const keywordFilter = slug?.match(/keyword:\w+/);
  return keywordFilter ? keywordFilter[0] : undefined;
}

export function extractCampaignContactFilterSlug(id: string) {
  const regex = /\/campaigns\/(.*?)\//;
  const match = id.match(regex);
  return match ? `campaign:${match[1]}` : null;
}

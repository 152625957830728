import styled from "styled-components";

export default styled.div`
  height: 100%;
  flex: 1 1 auto;
  min-height: 0;
  position: relative;

  img {
    @media (max-height: 500px) {
      display: none;
    }
  }
`;

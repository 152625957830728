import { useEffect, useMemo, useState } from "react";
import { Box, Theme, useMediaQuery } from "@mui/material";
import AccountSettingsNavBar from "./components/AccountSettingsNavBar";
import AccountSettingsRouter from "./components/AccountSettingsRouter";
import { getSettingsOptions } from "./config/settingsConfig";
import PageSection from "components/Page/PageSection";
import PageSidebar from "components/Page/PageSidebar";
import PageWrapper from "components/Page/PageWrapper";
import { useCurrentAccount, useCurrentUser } from "hooks";
import withRecord from "higherOrderComponents/withRecord";

import hasAdminMembership from "utils/hasAdminMembership";
import getUserRole from "utils/getUserRole";
import { SettingsSection } from "models/SettingsSection";

function AccountSettings() {
  const smScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("md");
  });
  const account = useCurrentAccount();
  const me = useCurrentUser();

  const isAdmin = useMemo(() => {
    return hasAdminMembership(me);
  }, [me]);

  const role = getUserRole(me, account);

  const options = useMemo((): SettingsSection[] => {
    return getSettingsOptions({
      account,
      isAdmin,
      role,
      currentUser: me,
    });
  }, [account, isAdmin, me, role]);

  const [isOpen, setIsOpen] = useState(!smScreen);

  useEffect(() => {
    setIsOpen(!smScreen);
  }, [smScreen]);

  return (
    <PageWrapper>
      <PageSidebar>
        <AccountSettingsNavBar options={options} />
      </PageSidebar>
      <Box
        display="flex"
        sx={(theme) => {
          return {
            position: "relative",
            backgroundColor: theme.palette.background.paper,
            flexFlow: "column",
            height: "100%",
            marginLeft: isOpen ? "220px" : 0,
            zIndex: 3,
            width: isOpen ? "calc(100% - 220px)" : "100%",
            transition: theme.transitions.create(["margin", "width"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.short,
            }),
          };
        }}
      >
        <PageSection>
          <AccountSettingsRouter
            account={account}
            isAdmin={isAdmin}
            currentUser={me}
            isNavbarOpen={isOpen}
            options={options}
            toggleNavbar={() => {
              setIsOpen(!isOpen);
              return isOpen;
            }}
          />
        </PageSection>
      </Box>
    </PageWrapper>
  );
}

export default withRecord({
  actions: ["update"],
  container: "features/AccountSettings/containers/Account",
  type: "account",
})(AccountSettings);

/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useCallback, useMemo, useState } from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useSnackbar } from "notistack";
import { getWindowFeatures } from "../../utils/getWindowFeatures";
import { authorizeIntegration } from "../../api/authorizeIntegration";
import { AuthorizeHubspotButtonProps } from "./types";
import { selectAppSettings } from "features/EntryPoint/containers/App/selectors";
import { createSnackbarContent } from "components/Snackbar";
import { usePopup } from "utils/popup";

interface OAuthConfig {
  consentUrl: string;
  clientId: string;
  redirectUri: string;
  scopes: string[];
}

const getAuthUrl = ({
  consentUrl,
  clientId,
  scopes,
  redirectUri,
}: OAuthConfig) => {
  return `${consentUrl}?client_id=${clientId}&scope=${scopes.join(
    "%20",
  )}&redirect_uri=${redirectUri}` as const;
};

export function AuthorizeHubspotButton({
  currentAccount,
  setOauthDialogOpen,
  setSelectedIntegration,
}: AuthorizeHubspotButtonProps) {
  const [code, setCode] = useState<string | undefined>();
  const appSettings = useSelector(selectAppSettings);
  const { enqueueSnackbar } = useSnackbar();

  const handleAuthorizeIntegration = async ({config, settings}: any) => {
    try {
      const response = await authorizeIntegration(currentAccount.slug, {
        provider: "hubspot",
        active: true,
        settings,
        config,
      });
      if (!response.ok) {
        throw new Error("Failed to connect integration.");
      }
      const data = await response.json();
      setSelectedIntegration(data);
      setOauthDialogOpen(false);
      enqueueSnackbar("Integration successfully connected.", {
        content: createSnackbarContent("info"),
      });
    } catch {
      enqueueSnackbar("Failed to connect integration.", {
        content: createSnackbarContent("error"),
      });
    }
  }

  const popupParameters = useMemo(() => {
    return [
      getAuthUrl({
        consentUrl: appSettings.HUBSPOT_AUTHORIZATION_URI,
        clientId: appSettings.HUBSPOT_CLIENT_ID,
        redirectUri: `${window.location.origin}/auth/hubspot_callback`,
        scopes: [
          "crm.objects.contacts.read",
          "crm.objects.contacts.write",
          "crm.objects.owners.read",
        ],
      }),
      "MS Dynamics Window",
      getWindowFeatures({ width: 500, height: 750 }),
    ] as const;
  }, [appSettings.HUBSPOT_AUTHORIZATION_URI, appSettings.HUBSPOT_CLIENT_ID]);

  const { open: openAuthorizer } = usePopup<string>(
    "HubspotChannel",
    popupParameters,
  );

  const handleSubmit = useCallback(
    ({ settings, config }: any) => {
      return handleAuthorizeIntegration(
        {
          settings,
          config,
        },
      );
    },
    [handleAuthorizeIntegration],
  );

  const openAuthWindow = useCallback(async () => {
    openAuthorizer({
      submit: (_code: any) => {
        setCode(_code);
        handleSubmit(
          {
            config: {
              code: _code,
            },
            settings: {
              noteType: "sms",
            },
          },
          
        );
      },
    });
  }, [handleSubmit, openAuthorizer]);

  return (
    <Button
      color="primary"
      endIcon={<OpenInNewIcon />}
      onClick={() => {
        openAuthWindow();
      }}
      variant="contained"
      sx={{
        padding: "6px 16px",
      }}
    >
      Authorize
    </Button>
  );
}

import { FormikHelpers } from "formik";
import { useCallback, useEffect } from "react";
import { useSnackbar } from "notistack";
import { Box } from "@mui/material";
import { Contact } from "@tesseract/core";
import styled from "styled-components";
import { ContactUnlinkCandidateDialog } from "./components/ContactUnlinkCandidateDialog";
import { dialogIds } from "./constants";
import { Props, Methods } from "./types";
import ContactConfirmDeleteDialog from "./components/ContactConfirmDeleteDialog";
import { ContactForm } from "features/ContactForm";
import { createContact, updateContact, useContact } from "features/Contact";
import { DialogHeader } from "components/DialogHeader";
import { ValidationErrorResponse } from "features/Contact/api/updateContact";
import ContactFormWrapper from "features/ContactModal/containers/ContactFormWrapper";
import type { FormValues } from "features/ContactForm";
import { contactUpdated, contactCreated } from "features/Contact/events";
import { ContactFormRedesign } from "features/ContactForm/ContactFormRedesign";
import ContactShow from "features/ContactModal/components/ContactShow";
import ModalContent from "components/ModalContent";
import { createSnackbarContent } from "components/Snackbar";

const ContactModalContent = styled(ModalContent)`
  height: calc(100vh - 60px);
  padding: 0;
  position: relative;
  max-width: 100%;
`;

/*
  This variable allows us to dynamically redner/perform contact unlinking in integrations.
  integrationName is passed to the ContactUnlinkDialog component.
  integrationName is set within the ContactForm component via `onContactUnlink` method.
*/
let integrationName: string = "bullhorn" || "greenhouse";

export const contactDialogs = {
  [dialogIds.show]: ({
    fullScreen,
    currentAccount,
    contactModalContainer: {
      substate: { activeConversation, contact, expanded },
    },
    isSinglePanel,
    setView,
    setCompose,
    handleClose,
    setContactModal,
  }: Props & Methods) => {
    return (
      <>
        <DialogHeader
          onClose={handleClose}
          title="Contact details"
          fullScreen={fullScreen}
        />
        <ContactModalContent>
          <ContactShow
            activeConversation={activeConversation}
            closeModal={handleClose}
            contact={contact}
            currentAccount={currentAccount}
            expanded={expanded}
            isSinglePanel={isSinglePanel}
            setActiveView={setView}
            setContactModal={setContactModal}
            setCompose={setCompose}
          />
        </ContactModalContent>
      </>
    );
  },

  [dialogIds.edit]: ({
    fullScreen,
    currentAccount,
    currentUser,
    contactModalContainer: {
      substate: {
        contact: { id },
        conversationPhoneNumber,
      },
    },
    handleClose,
    cancelEdit,
    setView,
    setContactModal,
  }: Props & Methods) => {
    /**
     *  Use contact hook
     */
    const { load, contact, state, error } = useContact();

    /**
     * Fetch the contact from the server if the id is defined
     */
    useEffect(() => {
      return load(id);
    }, [id, load]);

    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = useCallback(
      (values: FormValues, actions: FormikHelpers<FormValues>) => {
        return updateContact(id, values)
          .then(async (response) => {
            if (response.status === 422) {
              const { validationErrors } =
                (await response.json()) as ValidationErrorResponse;

              actions.setErrors(validationErrors);
            }
            return response.json() as Promise<Contact.Raw>;
          })
          .then((updatedContact) => {
            contactUpdated(updatedContact);
            setView(undefined);
            setContactModal({
              form: false,
              contact: updatedContact,
            });

            enqueueSnackbar(`Successfully saved ${updatedContact.name}`, {
              content: createSnackbarContent("info"),
            });

            return true;
          })
          .catch(() => {
            enqueueSnackbar(`Error updating contact`, {
              content: createSnackbarContent("error"),
            });

            return false;
          });
      },
      [enqueueSnackbar, id, setContactModal, setView],
    );

    return (
      <>
        <DialogHeader
          onClose={handleClose}
          title="Edit contact"
          fullScreen={fullScreen}
        />
        {error ? (
          <Box height="50vh">Error loading contact</Box>
        ) : (
          <ContactFormRedesign
            loading={state !== "done"}
            currentAccount={currentAccount}
            currentUser={currentUser}
            contact={contact}
            conversationPhoneNumber={conversationPhoneNumber}
            onContactUnlink={(integration: string) => {
              setView(dialogIds.confirmUnlink);
              integrationName = integration;
            }}
            onCancel={cancelEdit}
            onDelete={() => {
              setView(dialogIds.confirmDelete);
            }}
            onSubmit={handleSubmit}
          />
        )}
      </>
    );
  },

  [dialogIds.create]: ({
    fullScreen,
    currentAccount,
    currentUser,
    contactModalContainer: {
      substate: { conversationPhoneNumber },
    },
    handleClose,
    setView,
    setContactModal,
  }: Props & Methods) => {
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = useCallback(
      (values: FormValues, actions: FormikHelpers<FormValues>) => {
        return createContact(currentAccount.id, values)
          .then(async (updateContactResponse) => {
            if (updateContactResponse.status === 422) {
              const { validationErrors } =
                (await updateContactResponse.json()) as ValidationErrorResponse;

              actions.setErrors(validationErrors);
            }

            if (!updateContactResponse.ok) {
              throw new Error("Error updating contact");
            }

            const contact = (await updateContactResponse.json()) as Contact.Raw;

            contactCreated(contact);

            setContactModal({
              form: false,
              contact,
            });

            setView(undefined);

            enqueueSnackbar(`Successfully saved ${contact.name}`, {
              content: createSnackbarContent("info"),
            });

            return true;
          })
          .catch(() => {
            enqueueSnackbar(`Error updating contact`, {
              content: createSnackbarContent("error"),
            });

            return false;
          });
      },
      [currentAccount.id, enqueueSnackbar, setContactModal, setView],
    );

    return (
      <>
        <DialogHeader
          onClose={handleClose}
          title="New contact"
          fullScreen={fullScreen}
        />
        <ContactFormRedesign
          currentAccount={currentAccount}
          currentUser={currentUser}
          conversationPhoneNumber={conversationPhoneNumber}
          onCancel={handleClose}
          onSubmit={handleSubmit}
        />
      </>
    );
  },

  [dialogIds.confirmDelete]: ({
    currentAccount,
    contactModalContainer: {
      substate: { contact },
    },
    handleClose,
    setView,
  }: Props & Methods) => {
    return (
      <ContactConfirmDeleteDialog
        {...{
          currentAccount,
          handleClose,
          contact,
          cancel: () => {
            return setView(undefined);
          },
        }}
      />
    );
  },

  [dialogIds.confirmUnlink]: ({
    currentAccount,
    contactModalContainer: {
      substate: { contact },
    },
    handleClose,
    setView,
    setContactModal,
  }: Props & Methods) => {
    return (
      <ContactUnlinkCandidateDialog
        {...{
          currentAccount,
          handleClose,
          contact,
          integrationName,
          onContactUnlink: () => {
            const { data, ...rest } = contact;
            const { [integrationName]: _, ...dataRest } = data;

            const updatedContact = {
              ...rest,
              data: {
                ...dataRest,
              },
            };

            contactUpdated(updatedContact);

            setContactModal({
              form: true,
              contact: updatedContact,
            });

            setView(dialogIds.edit);
          },
          cancel: () => {
            return setView(dialogIds.edit);
          },
        }}
      />
    );
  },

  [dialogIds.existing]: ({
    fullScreen,
    currentAccount,
    currentUser,
    contactModalContainer: {
      substate: { contact, conversationPhoneNumber, partialMatches },
    },
    handleClose,
    cancelEdit,
    setView,
  }: Props & Methods) => {
    return (
      <>
        <DialogHeader
          fullScreen={fullScreen}
          onClose={handleClose}
          title="Existing Contact(s) Found"
        />
        <ContactFormWrapper
          onContactUnlink={() => {
            setView(dialogIds.confirmUnlink);
          }}
          cancelEdit={contact ? cancelEdit : undefined}
          closeModal={handleClose}
          conversationPhoneNumber={conversationPhoneNumber}
          contact={contact}
          currentAccount={currentAccount}
          partialMatches={partialMatches}
          currentUser={currentUser}
          onDelete={() => {
            setView(dialogIds.confirmDelete);
          }}
        />
      </>
    );
  },
} as const;
